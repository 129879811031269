import { useCallback, useEffect, useRef, useState } from 'react'
import { useEditorContext } from '@/scenes/engine'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'baseui'
import { lightTheme } from '@/customTheme'
import AutoScroll from '@/components/AutoScroll'
import Search from '../../Toolbox/ToolboxItems/components/Search'
import debounce from 'lodash/debounce'
import { useStyletron } from 'baseui'
import useAppContext from '@/hooks/useAppContext'
import Loading from '@/components/Loading'
import { getImagesUnplash, reportDownloadUnplash, searchPhotosUnplash } from '@/services/connect_unsplash'
import { nanoid } from 'nanoid'
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@/scenes/engine/objects/media-repository/media_image_type'
import { ObjectType } from '@/scenes/engine/common/constants'
import { PanelType } from '@/constants/app-options'
import { MediaImageRepositoryProcessing } from '@/scenes/engine/objects/media-repository/media_image_repository_processing'
import { selectTemplateDetails } from '@/store/slices/templates/selectors'
import CanvasImageRenderer from '@/scenes/engine/utils/canvasImageRenderer'
import { selectImageElements } from '@/store/slices/imageElement/selectors'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'
import HeadingInspector from '@/components/HeadingInspector'

function Stock({ isOpen, setIsOpen }) {
  const [css] = useStyletron()
  const [searchValue, setSearchValue] = useState(null)

  useEffect(() => {
    if (searchValue && searchValue.length > 1) {
      customAmplitude('Photography Search', { Query: searchValue })
    }
  }, [searchValue])

  const { editor, activeObject } = useEditorContext()
  const {
    setActivePanel,
    isReplacingImage,
    setRemoveBgSuccess,
    setDisableEditor,
    setRemoveBgAbortController,
    setIsReplacingImage,
  } = useAppContext()
  const [isBoxShadow, setIsBoxShadow] = useState(false)
  const [defaultPage, setDefaultPage] = useState(1)
  const [defaultPageForSearch, setDefaultPageForSearch] = useState(1)
  const listSectionRef = useRef(null)
  const masonryGridRef = useRef(null) 
  const [resultSearch, setResultSearch] = useState(null)
  const [isSearching, setIsSearching] = useState(false)
  const [isLoadedJson, setIsLoadedJson] = useState(true)
  const [listImages, setListImages] = useState([])
  let imageProcessing = new MediaImageRepositoryProcessing()
  const templateDetails = useSelector(selectTemplateDetails)
  const filterPacks = useSelector(selectImageElements)

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal
    async function getImages() {
      if (!signal.aborted) {
        let data = await getImagesUnplash()
        if (!signal.aborted) {
          setListImages(data)
        }
      }
    }
    getImages()
    return () => {
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    if (masonryGridRef.current.offsetHeight < listSectionRef.current.offsetHeight) {
      getMoreData()
    }
  }, [isOpen])


  const handleSearch = async value => {
    if (value) {
      setIsSearching(true)
      listSectionRef.current?.scrollTo(0, 0)
      const data = await searchPhotosUnplash(value).then(data => {
        return data
      })
      setResultSearch(data)
      setIsSearching(false)
    } else {
      setResultSearch(null)
      setIsSearching(false)
    }
  }
  const debounceHandleSearch = useCallback(debounce(value => {
    handleSearch(value)
  }, 400), [])

  const handleReset = () => {
    listSectionRef.current?.scrollTo(0, 0)
    setDefaultPageForSearch(1)
    setDefaultPage(1)
    handleSearch('')
    setSearchValue('')
  }

  const addImageToCanvas = async url => {
    let guid = nanoid()
    let assetStateId = nanoid()

    let maskInfo = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractMask(url)

    await MediaImageRepository.getInstance().storeImageBlobString(guid, assetStateId, MediaImageType.latest, url)
    await MediaImageRepository.getInstance().storeImageBlobString(
      guid,
      assetStateId,
      MediaImageType.original,
      url
    )
    await MediaImageRepository.getInstance().storeImageBlobString(
      guid,
      assetStateId,
      MediaImageType.mask,
      maskInfo.blob
    )

    let frame = editor.handlers.frameHandler.get()
    let layerSize = maskInfo.size
    let canvasAspectRatio = frame.width / frame.height
    let layerAspectRatio = layerSize.width / layerSize.height
    let width = 0.6

    if (layerAspectRatio < canvasAspectRatio) {
      width = (width * layerAspectRatio) / canvasAspectRatio
    }

    const object = {
      type: ObjectType.BAZAART_IMAGE,
      centerPoint: {
        x: 0.5,
        y: 0.5,
      },
      sizeOnCanvas: {
        width: width,
      },
      transformation: {
        horizontalFlip: false,
        verticalFlip: false,
      },
      boundingBox: { y: 0, width: 1, height: 1, x: 0 },
      absoluteRotation: 0,
      bazaartGuid: guid,
      layerAssetStateId: assetStateId,
      hasTransparency: maskInfo.hasTransparency,
      isStockImage: true
    }
    editor.handlers.objectsHandler.add(object)

    customAmplitude('bazaart.add.photo.canvas')
    setActivePanel(PanelType.BACKGROUND)
    setIsOpen()
  }

  const removeBg = async resizedImage => {
    setRemoveBgSuccess(false)
    setDisableEditor(true)
    let abortController = new AbortController()
    setRemoveBgAbortController(abortController)

    setTimeout(async () => {
      await editor.handlers.objectsHandler.removeBg(null, abortController.signal, resizedImage)
      setTimeout(() => {
        setRemoveBgSuccess(true)
      }, 100)
    }, 100)
  }

  async function itemSelected(itemObject) {
    let imageUrl= itemObject?.urls?.regular
    let urlToReport= itemObject?.links?.download_location
    let resizedImage = await imageProcessing.resizeBlobToMaxEdgeSize(imageUrl, 1280)
    if (isReplacingImage) {
      setIsReplacingImage(false)
      const replaceLayer = activeObject ? activeObject : editor.handlers.objectsHandler.replaceRefLayer
      const isMagicTemplate = templateDetails?.is_magic
      let isMagicLyaer = isMagicTemplate && replaceLayer.isTemplateLayer
      editor.handlers.transactionHandler.save()
      await editor.handlers.objectsHandler.replaceImage(resizedImage, isMagicLyaer, false)

      await CanvasImageRenderer.getInstance().render(
        editor.handlers.canvasHandler.canvas.getActiveObject(),
        editor.handlers.frameHandler.getSize(),
        filterPacks
      )
      // @ts-ignore
      editor.handlers.canvasHandler.canvas.getActiveObject().set({isStockImage: true})
      
      if (isMagicLyaer) {
        removeBg(resizedImage)
      }
      const eventProperties = {
        Tool: 'bazaart.replace',
        Type: ObjectType.BAZAART_IMAGE,
      }
      customAmplitude('Selected tool', eventProperties)
    } else {
      addImageToCanvas(resizedImage)
      // @ts-ignore
      window.dataLayer.push({ event: 'stock_added'});
    }
    reportDownloadUnplash(urlToReport)
    customAmplitude('Selected tool', {
      Tool: 'bazaart.add.stock',
    })
    setIsOpen()
  }

  const getMoreData = async () => {
    let data
    if (resultSearch) {
      if(resultSearch.length === 0) return
      setIsSearching(true)
      data = await searchPhotosUnplash(searchValue, defaultPageForSearch + 1)
      if (data?.length > 0) {
        setResultSearch(pre => pre ? [...pre, ...data] : data)
        setDefaultPageForSearch(pre => pre + 1)
      }
      setIsSearching(false)
    } else {
      data = await getImagesUnplash(defaultPage + 1)
      if (data) {
        // filter out duplicates - was bug on Unsplash: https://app.asana.com/0/0/1207163228427282/f
        setListImages(pre => [...pre, ...data.filter(item => !pre.find(i => i.id === item.id))])
        setDefaultPage(pre => pre + 1)
      }
    }
  }

  const debounceGetMoreData = debounce(() => {
    getMoreData()
  }, 400)

  
  useEffect(() => {
    if (masonryGridRef.current.offsetHeight < listSectionRef.current.offsetHeight) {
      getMoreData()
    }
  }, [listImages, resultSearch])

  const { t } = useTranslation()

  return (
    <ThemeProvider theme={lightTheme}>
      <div
        id="popover-stock"
        style={{
          overflow: 'hidden',
          pointerEvents: !isLoadedJson ? 'none' : 'auto',
          boxSizing: 'border-box',
          position: 'fixed',
          top: 'calc(-50vh + 48px + 180px)',
          left: '80px',
          background: '#ffffff',
          width: '340px',
          height: 'calc(100vh - 96px)',
          flex: 'none',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
          borderRadius: '16px',
          display: isOpen ? 'flex' : 'none',
          flexDirection:'column'
        }}
      >
        <div style={{ boxShadow: isBoxShadow ? '0px 0px 6px rgba(0, 0, 0, 0.1)' : 'none' }}>
          <HeadingInspector
            hasNavigation={false}
            title={t("Stock")}
            hasClose={true}
            handleClose={() => {
              setIsOpen()
            }}
          ></HeadingInspector>
          <div
            style={{
              padding: '0 24px 12px 24px',
              paddingTop: 0,
              width: '100%',
              boxSizing: 'border-box',
              background: '#fff',
              zIndex: 1000,
            }}
          >
            <Search
              handleValueChange={value => debounceHandleSearch(value)}
              size="standard"
              valueExternal={searchValue}
              setValueExternal={setSearchValue}
              handleReset={() => handleReset()}
            />
          </div>
        </div>
        <AutoScroll
          style={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            paddingBottom: '20px',
            position: 'relative',
          }}
          ref={listSectionRef}
          handleScroll={async e => {
            if (e.target.scrollTop > 0) {
              setIsBoxShadow(true)
            } else {
              setIsBoxShadow(false)
            }
            if (
              Math.round(listSectionRef.current.scrollTop) + listSectionRef.current.clientHeight >=
                listSectionRef.current.scrollHeight - 1 &&
              Math.round(listSectionRef.current.scrollTop) + listSectionRef.current.clientHeight <=
                listSectionRef.current.scrollHeight + 1
            ) {
              debounceGetMoreData()
            }
          }}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: '8px',
            }}
            className='masonry-grid'
            ref={masonryGridRef}
          >
            {!resultSearch && !isSearching && listImages && listImages.length
              ? listImages.map((image) => (
                  <div
                    style={{
                      display: 'flex',
                      position: 'relative',
                      borderRadius: '8px',
                      overflow: 'hidden',
                      border: '1px solid rgba(229, 229, 229, 1)',
                      cursor: 'pointer',
                      // height: `${(image.height / image.width) * 92}px`,
                      gridRowEnd: `span ${Math.ceil((image.height * 10 / image.width))}`,
                    }}
                    key={image.id}
                    className={css({
                      position: 'relative',
                      ':hover div': {
                        display: 'flex !important',
                        flexDirection: 'column-reverse',
                      },
                      ':active div': {
                        display: 'flex !important',
                        flexDirection: 'column-reverse',
                        background: 'rgba(0,0,0,.4) !important',
                      },
                    })}
                  >
                    <WrapImage
                      item={image}
                      handleOnClick={() => itemSelected(image)}
                      close={setIsOpen}
                    />
                  </div>
                ))
              : null}

            {resultSearch && resultSearch.length
              ? resultSearch.map((image) => (
                  <div
                    className={css({
                      // width: '92px',
                      cursor: 'pointer',
                      borderRadius: '8px',
                      position: 'relative',
                      ':hover div': {
                        display: 'flex !important',
                        flexDirection: 'column-reverse',
                      },
                      ':active div': {
                        display: 'flex !important',
                        flexDirection: 'column-reverse',
                        background: 'rgba(0,0,0,.4) !important',
                      },
                      // height: `${(image.height / image.width) * 92}px`,
                      gridRowEnd: `span ${Math.ceil((image.height * 10 / image.width ))}`,
                    })}
                    key={image.id}
                  >
                    <WrapImage
                      item={image}
                      handleOnClick={() => itemSelected(image)}
                      close={setIsOpen}
                    />
                  </div>
                ))
              : null}
          </div>

          {resultSearch && resultSearch.length === 0 ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
                flexDirection: 'column',
              }}
            >
              <p style={{ ...lightTheme.typography.LabelLarge, color: lightTheme.colors.blackGray, margin: 0 }}>
                {t('No results')}
              </p>
              <p
                style={{
                  ...lightTheme.typography.Small12regular,
                  color: 'rgba(153, 153, 153, 1)',
                  margin: 0,
                }}
              >
                {t('for \"%@\"', { arg:searchValue})}
              </p>
            </div>
          ) : null}

          {isSearching ? (
            <div style={{
              position: 'absolute',
              inset: 0,
              background: '#fff'
            }}>
              <div
                style={{
                  position: 'absolute',
                  bottom: '50%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                  zIndex: 1000,
                  paddingLeft: '12px',
                }}
              >
                <Loading />
              </div>    
            </div>
          ) : null}
        </AutoScroll>
        {/* {!isLoadedJson ? (
          <div
            style={{
              position: 'absolute',
              left: '-97px',
              top: '-81px',
              width: '100vw',
              height: '100vh',
              background: 'rgba(255, 255, 255, 0.7)',
              zIndex: -1,
            }}
          >
            <Loading
              style={{
                position: 'absolute',
                left: 'calc(50% + 218px)',
                top: 'calc(50% + 32px)',
                transform: 'translate(-150%,-150%)',
              }}
            />
          </div>
        ) : null} */}
      </div>
    </ThemeProvider>
  )
}

export default Stock

function WrapImage({ item, handleOnClick, close }) {
  const [isLoaded, setIsLoaded] = useState(false)
  const { objDragging, setObjDragging } = useAppContext()
  const {t} = useTranslation()
  return (
    <>
      <img
        onClick={() => handleOnClick()}
        style={{
          width: '100%',
          borderRadius: '8px',
          // height: `${(item.height / item.width) * 92}px`,
          height: `100%`,
          // transition: 'all 3s ease-in-out',
          objectFit: 'fill',
        }}
        src={item?.urls?.regular}
        alt={item.alt_description}
        onLoad={e => {
          setIsLoaded(true)
        }}
        onDrag={() => {
          if (!objDragging.item || objDragging.item?.urls?.regular !== item.urls.regular) {
            setObjDragging({
              item: item,
              type: ObjectType.BAZAART_IMAGE,
            })
            close()
          }
        }}
      />
      <div
        style={{
          position: 'absolute',
          inset: 0,
          background: !isLoaded ? lightTheme.colors.grayScale50 : 'rgba(0, 0, 0, .25)',
          display: isLoaded ? 'none' : 'block',
          zIndex: 10,
          borderRadius: '8px',
          pointerEvents: 'none',
          padding: '4px',
        }}
      >
        <span style={{ color: '#fff', fontSize: '10px', lineHeight: '12px' }}>
          {item.user.name} / Unsplash
        </span>
      </div>
    </>
  )
}
