import { setUserIsPremium } from '@/store/slices/user/actions'
import store from '@/store/store'
import { setUser } from '@/store/slices/user/actions'
import * as amplitude from '@amplitude/analytics-browser'
import ApiService from '@services/api'
import Cookies from 'universal-cookie'
import * as _ from 'lodash'
import * as Sentry from '@sentry/react'
import { customAmplitude } from '@/utils/customAmplitude'
import { UserDevice } from '@/firebase/services/sync/UserDevice'
import { FirebaseWrapper } from '@/firebase/FirebaseBaseWrapper'
import { DateProvider } from '@/firebase/services/DateProvider'
import api from '@services/api'
import { initializeProfitWell } from '@/libs/profitwell'

type Callback = (...args: any[]) => any

export enum PremiumOnPlatformType {
  android,
  ios,
  web,
}

export class SignInManager {
  platformType: string | null
  userId: number | null
  username: string | null
  isStaff: boolean | null
  email: string | null
  userIsPremium: boolean | null
  userHaveActivePaddleSubscription: boolean | null
  userPremiumOnPlatform: PremiumOnPlatformType | null
  manageCancelUrl: string | null
  manageUpdateUrl: string | null
  userHadAFreeTrial: boolean | null
  accessToken: string | null
  dataForAnalytics: object | null
  checkTransactionDone = false
  firebase_token : string | null

  cookies = new Cookies()
  didSetup = false
  firebaseTokenUpdated = false
  didSetupPaddle = false

  private callbacks: Record<string, Callback>
  private paddle: any | null
  private appleId: any | null
  private google: any | null
  private fbq: any | null
  private FB: any | null

  private purchasePlanId: string | null
  private googleButtonWrapper: any | null
  private googleCloseCheckInterval: any | null

  private static instance: SignInManager

  private constructor() {
    const params = new URLSearchParams(window.location.search)
    let gclid = params.get('gclid')
    if (gclid) {
      localStorage.setItem('gclid', gclid)
    } else {
      gclid = localStorage.getItem('gclid')
    }
  }

  public static getInstance(): SignInManager {
    if (!SignInManager.instance) {
      SignInManager.instance = new SignInManager()
    }

    let cookies = new Cookies()
    let userInfo = cookies.get('userInfo')
    if (userInfo) {
       SignInManager.instance.setUserProperties(userInfo)
       SignInManager.instance.refreshFirebaseToken()
    }

    return SignInManager.instance
  }

  public setupUI(container: HTMLDivElement) {
    const dependencies = [
      this.setupFacebook(),
      this.setupGoogle(),
      this.setupApple()
    ];
    
    Promise.allSettled(dependencies).then(() => {
      if (!this.didSetup) {
        this.didSetup = true;
        this.setupLogic();
      }
      if (this.googleButtonWrapper && this.googleButtonWrapper.parentNode) {
        this.googleButtonWrapper.parentNode.removeChild(this.googleButtonWrapper)
      }
      this.googleButtonWrapper = this.createFakeGoogleWrapper(container)
    });
  }

  // @ts-ignore
  private waitForDependency(dependencyName: keyof typeof window.paymentDependency.allDependencies): Promise<void> {
    return new Promise((resolve) => {
      // @ts-ignore
      if (window.paymentDependency.loadedDependencies[window.paymentDependency.allDependencies[dependencyName]]) {
        resolve();
      } else {
        // @ts-ignore
        window.paymentDependency.addListener(() => resolve());
      }
    });
  }

  private setupFacebook(): Promise<void> {
    return this.waitForDependency('facebook').then(() => {
      let that = this
      // @ts-ignore
      this.FB = window.FB
      if (this.FB) {
        this.FB.init({
          appId: process.env.REACT_APP_FB_APP_ID,
          cookie: true, // Enable cookies to allow the server to access the session.
          xfbml: true, // Parse social plugins on this webpage.
          version: 'v16.0', // Use this Graph API version for this call.
        })
  
        // @ts-ignore
        this.fbq = window.fbq
      }
  
      // @ts-ignore
      window.fbAsyncInit = function () {
        // @ts-ignore
        that.FB = window.FB
        that.FB.init({
          appId: process.env.REACT_APP_FB_APP_ID,
          cookie: true, // Enable cookies to allow the serREACT_APP_FB_APP_IDver to access the session.
          xfbml: true, // Parse social plugins on this webpage.
          version: 'v16.0', // Use this Graph API version for this call.
        })
      }
    });
  }

  private setupGoogle(): Promise<void> {
    return this.waitForDependency('google').then(() => {
      let that = this
      // @ts-ignore
      this.google = window.google
      this.google?.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        state_cookie_domain: process.env.REACT_APP_GOOGLE_STATE_COOKIE_DOMAIN,
        ux_mode: 'popup',
        callback: async tokenResponse => {
          await that.handleCredentialResponse(tokenResponse)
        },
      })
    });
  }
  
  private setupApple(): Promise<void> {
    return this.waitForDependency('apple').then(() => {
      // @ts-ignore
      this.appleId = window.AppleID
      // let redirectURI = process.env.REACT_APP_APPLE_REDIRECT_URI
      let redirectURI = window.location.origin
      this.appleId?.auth.init({
        clientId: 'login.co.bazaart.app',
        scope: 'name email',
        redirectURI: redirectURI,
        state: 'origin:web',
        usePopup: true,
      })
    });
  }

  private setupLogic() {
    this.callbacks = {}
    this.userIsPremium = false
    this.isStaff = false
    this.manageCancelUrl = null
    this.manageUpdateUrl = null
    this.userPremiumOnPlatform = null
    // @ts-ignore
    window.originalOpen = window.open
  }

  public setupPaddle(){
    if(!this.didSetupPaddle) {
      
      // @ts-ignore
      this.paddle = window.Paddle
      if(this.paddle == undefined) {return}
      
      this.didSetupPaddle = true
      
      let that = this
      if (process.env.REACT_APP_IS_SANDBOX == 'true') {
        this.paddle?.Environment.set('sandbox')
      }
  
      this.paddle?.Setup({
        vendor: parseInt(process.env.REACT_APP_PADDLE_ID, 10),
        eventCallback: function (data) {
          let params = {
            isSandbox: process.env.REACT_APP_IS_SANDBOX,
            userId: that.userId,
            source: that.platformType,
            email: that.email,
          }
  
          let interestingEvents = [
            'Checkout.Loaded',
            'Checkout.Failed',
            'Checkout.Complete',
            'Checkout.PaymentComplete',
          ]
  
          if (data.event === 'Checkout.Customer.Details') {
            document.getElementById('paddle-checkout').style.setProperty('display', 'block', 'important')
            document.getElementById('paddle-checkout-loader').style.setProperty('display', 'none', 'important')
          }
          if (data.event === 'Checkout.PaymentMethodSelected') {
            params['all_params'] = JSON.stringify(data.eventData)
            customAmplitude(`Checkout.PaymentMethodSelected`, {
              'Payment Method': data.eventData.payment_method,
            })
            // @ts-ignore
            window.dataLayer.push({ event: 'add_payment_info', customer_id: params.userId, user_email: params.email});
          } else if (data.event === 'Checkout.Error') {
            params['errorReason'] = JSON.stringify(data.eventData.error)
            params['all_params'] = JSON.stringify(data.eventData)
            customAmplitude(`Checkout.Error`, params)
            customAmplitude('Premium Subscribed Error', {
              ...that.dataForAnalytics,
              id: that.purchasePlanId,
              'Checkout Id': data.eventData.checkout.id,
              'Error Reason': params['errorReason'],
              isSandbox: process.env.REACT_APP_IS_SANDBOX,
            })
            // @ts-ignore
            window.dataLayer.push({ event: 'add_paiment_info', customer_id: params.userId, user_email: params.email});
          } else if (data.event === 'Checkout.PaymentComplete') {
            document.getElementById('paddle-checkout').style.setProperty('display', 'none', 'important')
            document.getElementById('paddle-checkout-loader').style.setProperty('display', 'block', 'important')
            customAmplitude('Premium Subscribed', {
              ...that.dataForAnalytics,
              id: that.purchasePlanId,
              'Checkout Id': data.eventData.checkout.id,
              'Payment Method': data.eventData.payment_method,
              isSandbox: process.env.REACT_APP_IS_SANDBOX,
            })

            that.sha256(params.email).then(hash_email => {
              if(data?.eventData?.checkout?.prices?.vendor?.items[0]?.recurring?.trial_days > 0){
                // @ts-ignore
                window.dataLayer.push({ event: 'start_trial', customer_id: params.userId, user_email: hash_email});
              } else {
                  // @ts-ignore
                  window.dataLayer.push({
                    event: 'purchase',
                    customer_id: params.userId,
                    user_email: hash_email,
                    ecommerce : {
                      transaction_id: data?.eventData?.checkout?.id,
                      tax: data?.eventData?.checkout?.prices?.vendor?.total_tax,
                      currency: data?.eventData?.checkout?.prices?.vendor?.currency,
                      value:  data?.eventData?.checkout?.prices?.vendor?.total,
                      items: [{
                        item_id: data?.eventData?.checkout?.prices?.vendor?.items[0]?.product_id,
                        quantity: data?.eventData?.checkout?.prices?.vendor?.items[0]?.quantity,
                        item_name: data?.eventData?.checkout?.prices?.vendor?.items[0]?.name,
                        price: data?.eventData?.checkout?.prices?.vendor?.total,
                        currency: data?.eventData?.checkout?.prices?.vendor?.currency
                      }]
                    }
                    
                  }); 
              }
          });

          } else if (data.event === 'Checkout.Complete') {
            if (that.fbq) {
              that.fbq('track', 'Purchase', {
                value: 0.0,
                currency: 'USD',
                userId: that.userId,
                email: that.email,
              })
            }
            setTimeout(async () => {
              await that.checkTransactionStatus(15)
              store.dispatch(setUser(that.getUserProperties()))
              store.dispatch(setUserIsPremium(that.userIsPremium))
              that.checkTransactionDone = true
  
              that.cookies.set(
                'userInfo',
                {
                  userId: that.userId,
                  username: that.username,
                  platformType: that.platformType,
                  email: that.email,
                  isStaff: that.isStaff,
                  userIsPremium: that.userIsPremium,
                  userHaveActivePaddleSubscription: that.userHaveActivePaddleSubscription,
                  accessToken: that.accessToken,
                  userHadAFreeTrial: that.userHadAFreeTrial,
                  manageCancelUrl: that.manageCancelUrl,
                  manageUpdateUrl: that.manageUpdateUrl,
                  userPremiumOnPlatform: that.userPremiumOnPlatform,
                  firebase_token: that.firebase_token
                },
                { path: '/' }
              )
  
              const identifyEvent = new amplitude.Identify()
              identifyEvent.set('isPremium', that.userIsPremium)
              amplitude.identify(identifyEvent)
            })
          } else if (interestingEvents.indexOf(data.event) > -1) {
            customAmplitude(`${data.event}`, params)
          }
        },
      })
    }
  }

  logout(){
    
    FirebaseWrapper.FirebaseBaseWrapper.shared.signOutUser();

    let cookies = new Cookies()
    cookies.remove('userInfo', { path: '/' })
    SignInManager.getInstance().setUserProperties({
      userId: null,
      username: null,
      platformType: null,
      email: null,
      userIsPremium: null,
      userHaveActivePaddleSubscription: null,
      manageCancelUrl: null,
      manageUpdateUrl: null,
      userPremiumOnPlatform: null,
      userHadAFreeTrial: null,
      accessToken: null,
      firebase_token: null
    })
  }

  private async sha256(str:string) : Promise<string> {
    const buffer = new TextEncoder().encode(str);
    const hash = await crypto.subtle.digest("SHA-256", buffer)
    return Array.prototype.map.call(new Uint8Array(hash), x => ('00' + x.toString(16)).slice(-2)).join('')
  }

  // https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
  async loginWithApple(): Promise<any> {
    let that = this
    return new Promise(async function (resolve, reject) {
      const event = await that.appleId.auth.signIn()
      const token = event.authorization.code
      const userId = event.authorization.id_token
      // consecutive runs don't return the user's email
      const maybeEmail = event.user ? event.user.email : null

      let bazaartUser = await that.logIn(token, 'apple', that.userId)
      that.email = bazaartUser.email ? bazaartUser.email : maybeEmail

      let user = that.getUserProperties()
      resolve(user)
    })
  }

  async loginWithFacebook(): Promise<any> {
    let that = this
    return new Promise(function (resolve, reject) {
      that.FB.login(
        function (response) {
          if (!response.authResponse) {
            resolve(null)
            return
          }
          if (response.status !== 'connected') {
            return
          }
          const accessToken = response.authResponse.accessToken
          const userId = response.authResponse.userID
          // @ts-ignore
          that.FB.api('/me', 'get', { fields: 'id,email' }, function (response) {
            setTimeout(async function () {
              let maybeEmail = response.email
              let bazaartUser = await that.logIn(accessToken, 'facebook', userId)
              that.email = bazaartUser.email ? bazaartUser.email : maybeEmail
              let user = that.getUserProperties()
              resolve(user)
            }, 0)
          })
        },
        { scope: 'email,public_profile' }
      )
    })
  }

  // https://stackoverflow.com/a/71394671/126015
  async loginWithGoogle(): Promise<any> {
    // let that = this
    return new Promise((resolve, reject) => {
      if(!this.callbacks){
        customAmplitude('Sign up error', {
          error: 'callbacks in loginWithGoogle is undefined'
        })
        Sentry.captureException(new Error('callbacks in loginWithGoogle is undefined'), { level: 'info' })
      }
      this.callbacks['google'] = (success: boolean) => {
        if (this.googleCloseCheckInterval) {
          this.clearGoogleInterval()
        }
        if (success) {
          let user = this.getUserProperties()
          resolve(user)
        } else {
          resolve(null)
        }
        delete this.callbacks['google']
      }
      this.googleButtonWrapper?.click()
    })
  }

  // https://developers.google.com/identity/gsi/web/guides/handle-credential-responses-js-functions
  private async handleCredentialResponse(response) {
    if (this.googleCloseCheckInterval) {
      this.clearGoogleInterval()
    }

    // decodeJwtResponse() is a custom function defined by you
    // to decode the credential response.
    let payload = this.decodeJwtResponse(response.credential)
    const accessToken = response.credential
    const userId = response.sub
    const maybeEmail = payload.email

    let bazaartUser = await this.logIn(accessToken, 'google', userId)
    this.email = bazaartUser.email ? bazaartUser.email : maybeEmail
    this.callbacks['google'](true)
  }

  private getUserProperties() {
    return {
      platformType: this.platformType,
      userId: this.userId,
      username: this.username,
      email: this.email,
      userIsPremium: this.userIsPremium,
      isStaff: this.isStaff,
      userHaveActivePaddleSubscription: this.userHaveActivePaddleSubscription,
      userPremiumOnPlatform: this.userPremiumOnPlatform,
      manageCancelUrl: this.manageCancelUrl,
      manageUpdateUrl: this.manageUpdateUrl,
      userHadAFreeTrial: this.userHadAFreeTrial,
      firebase_token: this.firebase_token,
    }
  }
  async setUserProperties(userInfo) {
    this.userId = userInfo.userId
    this.username = userInfo.username
    this.platformType = userInfo.platformType
    this.email = userInfo.email
    this.isStaff = userInfo.isStaff
    this.userIsPremium = userInfo.userIsPremium
    this.userHaveActivePaddleSubscription = userInfo.userHaveActivePaddleSubscription
    this.manageCancelUrl = userInfo.manageCancelUrl
    this.manageUpdateUrl = userInfo.manageUpdateUrl
    this.userPremiumOnPlatform = userInfo.userPremiumOnPlatform
    this.userHadAFreeTrial = userInfo.userHadAFreeTrial
    this.accessToken = userInfo.accessToken
    this.firebase_token = userInfo.firebase_token
  }

  async getPlanDetails(plan_id): Promise<any> {
    let that = this
    return new Promise(function (resolve, reject) {
      that.paddle?.Product.Prices(plan_id, result => {
        resolve(result)
      })
    })
  }

  async getMonthlyPlan() {
    let planDetails = await this.getPlanDetails(process.env.REACT_APP_PADDLE_PLAN_MONTHLY)
    planDetails.plan_id = process.env.REACT_APP_PADDLE_PLAN_MONTHLY
    return planDetails
  }

  async getYearlyPlan() {
    let plan_id = this.userHadAFreeTrial
      ? process.env.REACT_APP_PADDLE_PLAN_WITHOUT_TRIAL
      : process.env.REACT_APP_PADDLE_PLAN_WITH_TRIAL
    let planDetails = await this.getPlanDetails(plan_id)
    planDetails.plan_id = plan_id
    return planDetails
  }

  pay(platformType, email, userId, didClose, purchasePlanId, analytics?) {
    if (!userId) {
      return
    }
    const gaCookie = this.cookies.get('_ga')
    let gaClientId = null;
    if (gaCookie) {
      gaClientId = gaCookie.split('.').slice(-2).join('.')
    }
    let gclid = localStorage.getItem('gclid')

    // document.getElementById('login-block').style.setProperty('display', 'none', 'important')
    let internalPadding = 10
    const frameStyle = `width: calc(364px +  ${
      internalPadding * 2
    }px); margin: 0 -${internalPadding}px; background-color: transparent; border: none`
    this.purchasePlanId = purchasePlanId

    let paddleOptions = {
      product: parseInt(this.purchasePlanId, 10),
      email: email,
      disableLogout: true,
      marketingConsent: true,
      passthrough: userId,
      method: 'inline',
      frameTarget: 'paddle-checkout',
      frameInitialHeight: 450,
      frameStyle: frameStyle,
      closeCallback: didClose,
      customData: { source: 'webapp', ga_client_id: gaClientId, user_id: userId, gclid: gclid},
    }
    if (analytics) {
      this.dataForAnalytics = analytics
    }

    this.paddle.Checkout.open(paddleOptions)
  }

  private async logIn(sessionToken, type, userId) {
    let result = await ApiService.login(sessionToken, type, userId)

    //@ts-ignore
    this?.fbq('init', '634163634538447', {
      em: result.email, // Email
      external_id: result.id, // User ID
    })

    this.userId = result.id
    this.username = result.username;
    this.platformType = type
    this.email = result.email ? result.email : ''
    this.firebase_token = result.firebase_token

    // TODO: second userId should be changed to name and en should be changed to dynamic langague
    let userDevice = new UserDevice(this.userId.toString(), this.username, type, "web", "web", (new Date()).getSeconds(), "en", undefined)
    userDevice.save();

    const adminUsers = ['yoad@bazaart.me', 'moti@bazaart.me', 'dror@bazaart.me','l.yadid@gmail.com']
    this.isStaff = result.is_staff ? true : adminUsers.indexOf(result.email) !== -1;

    let token = `${result.username}:${result.session_token}`
    this.accessToken = token

    FirebaseWrapper.FirebaseBaseWrapper.shared.signInUser(result.firebase_token);

    await this.refreshTransactionStatus()

    customAmplitude('Sign up Success', {
      isSandbox: process.env.REACT_APP_IS_SANDBOX,
      userId: result.id,
      email: result.email ? result.email : '',
      Destination: type.charAt(0).toUpperCase() + type.slice(1),//capitalize type
    })

    const identifyEvent = new amplitude.Identify()
    identifyEvent.set('isSignup', true)
    amplitude.identify(identifyEvent)
    amplitude.setUserId(result.id)
    
    Sentry.setUser({ id: result.id })
    initializeProfitWell(result.id)

    // @ts-ignore
    window.dataLayer.push({ event: 'login', event_context: type, customer_id: result.id});

    if(result.new_user){
      // @ts-ignore
      window.dataLayer.push({ event: 'sign_up', event_context: type, customer_id: result.id});
    }
    else{
      // @ts-ignore
      window.dataLayer.push({ event: 'sign_in', event_context: type, customer_id: result.id});
    }

    return result
  }

  private async refreshFirebaseToken() {
    if (this.firebaseTokenUpdated){
      return;
    }
    this.firebaseTokenUpdated = true;

    this.firebase_token = await api.refreshFirebaseToken(this.userId);
    await FirebaseWrapper.FirebaseBaseWrapper.shared.signInUser(this.firebase_token);
  }

  async refreshTransactionStatus() {
    await this.checkTransactionStatus()
    store.dispatch(setUser(this.getUserProperties()))
    store.dispatch(setUserIsPremium(this.userIsPremium))
    this.checkTransactionDone = true

    this.cookies.set(
      'userInfo',
      {
        userId: this.userId,
        username: this.username,
        platformType: this.platformType,
        email: this.email,
        isStaff: this.isStaff,
        userIsPremium: this.userIsPremium,
        userHaveActivePaddleSubscription: this.userHaveActivePaddleSubscription,
        manageCancelUrl: this.manageCancelUrl,
        manageUpdateUrl: this.manageUpdateUrl,
        userHadAFreeTrial: this.userHadAFreeTrial,
        accessToken: this.accessToken,
        userPremiumOnPlatform: this.userPremiumOnPlatform,
        firebase_token: this.firebase_token
      },
      { path: '/' }
    )

    const identifyEvent = new amplitude.Identify()
    identifyEvent.set('isPremium', this.userIsPremium)
    amplitude.identify(identifyEvent)
  }

  private async checkTransactionStatus(maxAttempts: number = 1) {
    function isUserPaying(purchase) {
      let expiration = purchase['expiration']
      if (expiration) {
        return expiration > DateProvider.getUTCDate() || new Date(expiration) > DateProvider.getUTCDate()
      }
      return true
    }

    let transactions = []
    let userIsAlreadyPaying = false

    let attempts = 0
    const delayBetweenRequests = 500

    while (attempts < maxAttempts && !userIsAlreadyPaying) {
      try {
        const result = await ApiService.listTransactions()
        if (result && result.objects) {
          transactions = result.objects
          if (result.objects.some(isUserPaying)) {
            userIsAlreadyPaying = true
          }
        }
      } catch (error) {
        console.error(`listTransactions call attempt ${attempts + 1} failed:`, error)
      }

      await new Promise(resolve => setTimeout(resolve, delayBetweenRequests))
      attempts++
    }

    let onlyPaddleValidTransactions = transactions
      .filter(purchase => purchase['paddle_update_url'])
      .filter(isUserPaying)

    let userHadAFreeTrial = transactions.length > 0

    this.userIsPremium = userIsAlreadyPaying
    this.userHadAFreeTrial = userHadAFreeTrial

    if (userIsAlreadyPaying && onlyPaddleValidTransactions.length > 0) {
      let lastPayingTransaction = _.last(onlyPaddleValidTransactions)
      this.manageCancelUrl = lastPayingTransaction.paddle_cancel_url
      this.manageUpdateUrl = lastPayingTransaction.paddle_update_url
      this.userPremiumOnPlatform = PremiumOnPlatformType.web
      this.userHaveActivePaddleSubscription = lastPayingTransaction.expiration == null
    } else if (userIsAlreadyPaying) {
      this.manageCancelUrl = null
      this.manageUpdateUrl = null
      this.userPremiumOnPlatform = PremiumOnPlatformType.ios
    } else {
      this.manageCancelUrl = null
      this.manageUpdateUrl = null
      this.userPremiumOnPlatform = null
    }
  }

  // thanks - https://stackoverflow.com/a/75856811/126015
  private decodeJwtResponse(token) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  }

  private clearGoogleInterval() {
    window.clearInterval(this.googleCloseCheckInterval)
    this.googleCloseCheckInterval = null
    // @ts-ignore
    if (window.originalOpen) {
      // @ts-ignore
      window.open = window.originalOpen
    }
  }

  private createFakeGoogleWrapper = (container: HTMLDivElement) => {
    const googleLoginWrapper = document.createElement('div')
    // Or you can simple hide it in CSS rule for custom-google-button
    googleLoginWrapper.style.display = 'none'
    googleLoginWrapper.classList.add('custom-google-button')

    // Add the wrapper to body
    container.appendChild(googleLoginWrapper)

    // Use GSI javascript api to render the button inside our wrapper
    // You can ignore the properties because this button will not appear
    this.google?.accounts.id.renderButton(googleLoginWrapper, {
      type: 'icon',
      width: 200,
    })

    const googleLoginWrapperButton = googleLoginWrapper.querySelector('div[role=button]')

    return {
      click: () => {
        let that = this
        // @ts-ignore
        window.open = (url, target, windowFeatures) => {
          // @ts-ignore
          let openedWindow = window.originalOpen(url, target, windowFeatures)
          if (that.googleCloseCheckInterval) {
            that.clearGoogleInterval()
          }

          that.googleCloseCheckInterval = setInterval(() => {
            if (!openedWindow || !that.callbacks['google']) {
              that.clearGoogleInterval()
              return
            }

            if (openedWindow.closed) {
              // @ts-ignore
              window.open = window.originalOpen
              that.callbacks['google'](false)
            }
          }, 100)
        }
        // @ts-ignore
        googleLoginWrapperButton.click()
      },
    }
  }
}
