import { createReducer } from '@reduxjs/toolkit'
import {
  setCallbackFromCreationPage,
  setHasClipboardImage,
  setIsOpenErrorModalRemoveBg,
  setZoomRatio,
} from './action'

export type ClipboardType = '' | 'internal' | 'external'
export enum ActionType {
  ADD_IMAGE,
  CUSTOM_SIZE,
  REMOVE_BG,
  REMOVE_OBJ,
  ADD_TEMPLATE,
  QUICK_TUTORIAL,
  MAGIC_BACKGROUND,
  ENHANCE
}
export interface EditorState {
  zoomRatio: number
  isOpenErrorModalRemoveBg: boolean
  hasClipboardImage: ClipboardType
  callbackFromCreationPage: {
    action: ActionType
    value?: any,
    analytic?: any
  }
}
const initialState: EditorState = {
  zoomRatio: 1,
  isOpenErrorModalRemoveBg: false,
  hasClipboardImage: '',
  callbackFromCreationPage: null,
}

export const editorReducer = createReducer(initialState, builder => {
  builder.addCase(setZoomRatio, (state, { payload }) => {
    state.zoomRatio = payload
  })
  builder.addCase(setIsOpenErrorModalRemoveBg, (state, { payload }) => {
    state.isOpenErrorModalRemoveBg = payload
  })
  builder.addCase(setHasClipboardImage, (state, { payload }) => {
    state.hasClipboardImage = payload
  })
  builder.addCase(setCallbackFromCreationPage, (state, { payload }) => {
    state.callbackFromCreationPage = payload
  })
})