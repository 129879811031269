import BaseHandler from '../BaseHandler'
// import objectToFabric from './objectToFabric'
import { fabric } from 'fabric'
import { Template } from '../../common/interfaces'
import objectToFabric from '../../utils/objectToFabric'
import { ObjectType } from '../../common/constants'
import store from '@/store/store'
import CanvasImageRenderer from '../../utils/canvasImageRenderer'
import * as _ from 'lodash'

class DesignHandler extends BaseHandler {
  public async toDataURL(canvas?, preventWatermark: boolean = false, format = 'png') {
    const staticCanvas = new fabric.Canvas(null)
    const frame = this.root.frameHandler.get()
    const json = this.root.canvasHandler.exportToCanvasJSON()
    // @ts-ignore
    const promise = json.objects.filter(o => !o.id?.includes('replace') ).map(async (object: any) => {
      if(object.type === ObjectType.BAZAART_IMAGE ) {
        object._originalScaleX = object.scaleX * object._filterScalingX
        object._originalScaleY = object.scaleY * object._filterScalingY
        if(object.isTemplateLayer) {
          const imageElements = store.getState().editor.imageElements.imageElements
          object.effects = object.effects_from_template
          object.filters = await CanvasImageRenderer.getInstance().getFilters(object, imageElements)
        }
      }
      return this.replaceType(object)
    })
    json.objects = await Promise.all(promise)

    let hasTransparentBg = false

    // @ts-ignore
    const bgIndex = json.objects.findIndex(o => o.type === ObjectType.BAZAART_BG)
    if(bgIndex < 0) {
    // @ts-ignore
     json.objects = json.objects.filter(o => {
      // @ts-ignore
      if(o.type === ObjectType.FRAME) {
        return false
      } else {
       // @ts-ignore
        if(o.backgrondIdentifier === 'transparent') {
          hasTransparentBg = true
          return false
        }
        return true
      }
     })
    }
    json.objects.map(o => {
       // @ts-ignore
      if(o.clipPath) {
       // @ts-ignore
        const clipPath = o.clipPath
        clipPath.rx = 0
        clipPath.ry = 0
      }
    })
    await this.loadCanvasFromJSONAsync(staticCanvas, json)
    // @ts-ignore
    if(bgIndex < 0) {
      staticCanvas.backgroundColor = hasTransparentBg ? 'transparent' : frame && (typeof frame.fill === 'string') ? frame.fill : 'transparent'
    }
    if(!store.getState().editor.user.userIsPremium && !preventWatermark) {
      await this.root.objectsHandler.addWatermark(staticCanvas)
    }
    const data = staticCanvas.toDataURL({
      multiplier: 1,
      top: frame.top,
      left: frame.left,
      height: frame.height,
      width: frame.width,
      format: format,
    })
    return data
  }

  async loadCanvasFromJSONAsync(canvas, json): Promise<void> {
    return new Promise ((resolve, reject) =>{
      canvas.loadFromJSON(json, function() {
        resolve();
      });
    })
  }

   replaceType = function(obj){
    obj.previousType = obj.type
    switch (obj.type) {
      case ObjectType.BAZAART_BG:
        obj.type = ObjectType.BACKGROUND_IMAGE
        break
      case ObjectType.BAZAART_TEXT:
        obj.type = ObjectType.STATIC_TEXT
        break
      case ObjectType.BAZAART_STICKER:
      case ObjectType.BAZAART_IMAGE:
      case ObjectType.BAZAART_SHAP:
      case ObjectType.BAZAART_DRAWING:
      case ObjectType.BAZAART_OVERLAY:
        obj.type = ObjectType.STATIC_IMAGE
        break
      case ObjectType.GROUP:
        obj.objects = obj.objects.map((obj: any) => {
          return this.replaceType(obj)
        })
        break
    }
    return obj
  }
  private async loadTemplate(staticCanvas: fabric.StaticCanvas, template: Template) {
    const { frame, background } = template
    this.setBackground(staticCanvas, background)
    for (const object of template.objects) {
      if(object.type === ObjectType.BACKGROUND_IMAGE 
        || object.type === ObjectType.BAZAART_BG 
        || object.type === ObjectType.BAZAART_IMAGE
        || object.type === ObjectType.BAZAART_STICKER
        || object.type === ObjectType.BAZAART_SHAP
        || object.type === ObjectType.BAZAART_DRAWING
        || object.type === ObjectType.GROUP) {
        object.loadThumbnail = false
      }
      if(object.type === ObjectType.BAZAART_IMAGE && object.isTemplateLayer) {
        const imageElements = store.getState().editor.imageElements.imageElements
        object.effects = object.effects_from_template
        object.filters = await CanvasImageRenderer.getInstance().getFilters(object, imageElements)
      }
      const element = await objectToFabric.run(object, frame)
      element ? staticCanvas.add(element) : console.log('UNABLE TO LOAD OBJECT: ', object)
    }
  }

  private setDimensions(
    staticCanvas: fabric.StaticCanvas,
    { width, height }: { width: number; height: number }
  ) {
    staticCanvas.setWidth(width).setHeight(height)
  }

  private setBackground(staticCanvas: fabric.StaticCanvas, background: { type: string; value: string }) {
    if (!background) {
      return
    }
    staticCanvas.setBackgroundColor(background.type === 'color' ? background.value : '#ffffff', () => {
      staticCanvas.renderAll()
    })
  }
}

export default DesignHandler
