import useAppContext from '@/hooks/useAppContext'
import ModalCustom from '@/scenes/Editor/components/Modal/Modal'
import { InspectorPanelType, useInspector } from '@/scenes/Editor/components/Toolbox/InspectorDataProvider'
import { RemoveEditorContext } from '@/scenes/engine'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

function ModalEnhanceError() {
  const { showModalType, setShowModalType, setToolType, cancelPixelManipulation } = useAppContext()
  const { t } = useTranslation()
  const { hideInspector } = useInspector()
  const removeEditor = useContext(RemoveEditorContext).editor
  const { canvas } = useContext(RemoveEditorContext)

  const handleCloseModal = () => {
    setShowModalType(null)
    setToolType(null)
    // exit enhance tool
    hideInspector(InspectorPanelType.Enhance, true)
    removeEditor.handlers.pixelManipulationObjectHandler.FinishToolState().then(() => {
      removeEditor.handlers.pixelManipulationObjectHandler.reset()
      canvas.clear()
      cancelPixelManipulation(true)
    })
  }

  return (
    <ModalCustom
      isOpen={showModalType === ShowModalType.ENHANCE_ERROR}
      onClose={handleCloseModal}
      content={{
        header: t('Oops!') + ' 😬',
        desc: t('Something went wrong.'),
      }}
      action={{
        primary: {
          name: t('Ok'),
          handle: handleCloseModal
        },
      }}
    ></ModalCustom>
  )
}

export default ModalEnhanceError