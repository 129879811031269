import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <circle cx={8} cy={8} r={8} fill={props.disabled ? lightTheme.colors.grayScale200 : "#FCD34D"} />
    <path
      fill={props.disabled ? lightTheme.colors.grayScale50 : lightTheme.colors.blackGray}
      d="M8.125 4.5a.716.716 0 0 0-.731.695.693.693 0 0 0 .391.614c.009.06.012.12.01.18 0 .997-.741 1.803-1.658 1.803-.866 0-1.576-.725-1.66-1.641a.544.544 0 0 0 .218-.433.581.581 0 0 0-.598-.564.581.581 0 0 0-.597.564.573.573 0 0 0 .402.533c.325 1.408.595 2.837.923 4.247.137.592.67 1.002 1.277 1.002h4.018c.624 0 1.165-.433 1.296-1.043.298-1.381.595-2.825.933-4.196a.564.564 0 0 0 .411-.533.594.594 0 0 0-.597-.565c-.32 0-.584.249-.598.565 0 .16.072.31.196.413-.072.925-.783 1.66-1.659 1.66-.917 0-1.659-.805-1.659-1.801a1.07 1.07 0 0 1 .01-.181.693.693 0 0 0 .371-.79.704.704 0 0 0-.699-.529Z"
    />
  </svg>
)
export default SvgComponent
