import { ExportTemplate } from '@/scenes/engine/common/interfaces'
import { ObjectType, ObjectTypeFromAPI, propertyMapping } from '../common/constants'
import exportObject from '../utils/fabricToObject'
import objectToFabric from '../utils/objectToFabric'
import BaseHandler from './BaseHandler'
import { StaticText } from 'fabric/fabric-impl'
import {fabric} from 'fabric'
import { MediaImageRepository } from '../objects/media-repository/media_image_repository'
import CanvasImageRenderer from '../utils/canvasImageRenderer'
import store from '@/store/store'

class TemplateHandler extends BaseHandler {
  async exportTemplate(canvas?) {
    const canvasJSON: any = canvas ? canvas.toJSON(this.root.propertiesToInclude) : this.canvas.toJSON(this.root.propertiesToInclude)
    const frameOptions = this.root.frameHandler.getOptions()

    const template = {
      name: 'Untitled design',
      objects: [],
      background: {
        type: 'color',
        value: frameOptions.fill ? frameOptions.fill : '#fff',
      },
      frame: {
        width: frameOptions.width,
        height: frameOptions.height,
        top: frameOptions.top,
        left: frameOptions.left,
      },
    }
    
    const objects = canvasJSON.objects.filter(
      object => (object.type !== ObjectType.FRAME || (object.type === ObjectType.FRAME && typeof object.fill === 'object')) && !object.id?.includes('replace')
    )

    objects.forEach(async object => {
      if (object.type === ObjectType.FRAME) {
        template.objects = template.objects.concat(object)
      } else {
        const exportedObject = await exportObject.run(object, frameOptions)
        if(exportedObject){
          if(exportedObject._filterScalingX){
            exportedObject.scaleX = exportedObject.scaleX * exportedObject._filterScalingX
            exportedObject.scaleY = exportedObject.scaleY * exportedObject._filterScalingY
          }
          template.objects = template.objects.concat(exportedObject)
        } else {
          console.log('UNABLE TO EXPORT OBJECT: ', object)
        }
      }
    })

    // return template
    return template
  }

  async asyncMap<T, U>(
    array: T[],
    callback: (item: T, index: number, array: T[]) => Promise<U>
  ): Promise<U[]> {
    const promises = array.map(callback);
    return Promise.all(promises);
  }

  async exportToJSON(): Promise<ExportTemplate[]> {
    const propertiesToExport = this.root.propertiesToExport
    // TODO: temporary fix for Group
    this.canvas.getObjects().forEach(async (object) => {
      if(object.type === ObjectType.GROUP) {
        this.root.objectsHandler.ungroup(object)
      }
      // @ts-ignore
      if(object.isTemplateLayer && (object.bazaartGuid && !object.bazaartGuid.includes('replace'))) {
        // @ts-ignore
        object.set('adjustments', object.adjustments_from_template)
        await CanvasImageRenderer.getInstance().render(object, this.root.frameHandler.getSize(), store.getState().editor.imageElements.imageElements)
      }
    })
    const canvasJSON: any = this.canvas.toJSON(propertiesToExport)
    const frameOptions = this.root.frameHandler.getOptions()
    const filteredObjects = canvasJSON.objects.filter(obj => Object.values(ObjectType).includes(obj.type) && obj.type !== ObjectType.FRAME && (obj.bazaartGuid && !obj.bazaartGuid.includes('replace')));

    const filledObjects: ExportTemplate[] = await this.asyncMap(filteredObjects, async ( obj, index) => {
        //@ts-ignore
        obj.center = obj.centerPoint
        //@ts-ignore
        delete obj.centerPoint
        //@ts-ignore
        switch (obj.type) {
          case ObjectType.BAZAART_BG:
            return {
              [ObjectTypeFromAPI.BACKGROUND_LAYER]: await exportObject.run(obj,frameOptions,true),
            }
          case ObjectType.BAZAART_IMAGE:
            return {
              [ObjectTypeFromAPI.IMAGE_LAYER]: await exportObject.run(obj,frameOptions,true),
            }
          case ObjectType.BAZAART_TEXT:
            return {
              [ObjectTypeFromAPI.TEXT_LAYER]: await exportObject.run(obj,frameOptions,true),
            }
          case ObjectType.BAZAART_STICKER:
            return {
              [ObjectTypeFromAPI.STICKER_LAYER]: await exportObject.run(obj,frameOptions,true),
            }
          case ObjectType.BAZAART_SHAP:
            return { [ObjectTypeFromAPI.SHAPE_LAYER]: await exportObject.run(obj,frameOptions,true) }
          case ObjectType.STATIC_VECTOR:
            return { [ObjectTypeFromAPI.VECTOR_LAYER]: await exportObject.run(obj,frameOptions,true) }
          case ObjectType.STATIC_PATH:
          case ObjectType.DYNAMIC_PATH:
            return { [ObjectTypeFromAPI.PATH_LAYER]: await exportObject.run(obj,frameOptions,true) }
          case ObjectType.BAZAART_DRAWING:
            return { [ObjectTypeFromAPI.DRAWING_LAYER]: await exportObject.run(obj,frameOptions,true) }
          case ObjectType.BAZAART_OVERLAY:
            return { [ObjectTypeFromAPI.OVERLAY_LAYER]: await exportObject.run(obj,frameOptions,true) }
          default:
            return null
        }
      })

      console.log(filledObjects)
      return filledObjects
  }

  async importTemplate(template) {
    this.root.transactionHandler.save()
    this.root.objectsHandler.clear(false)
    const frame = template.frame
    this.root.frameHandler.update(frame)

    const frameOptions = this.root.frameHandler.getOptions()
    for (const object of template.objects) {
      const element = await objectToFabric.run(object, frameOptions)
      if (element) {
        this.canvas.add(element)
      } else {
        console.log('UNABLE TO LOAD OBJECT: ', object)
      }
    }
    this.root.frameHandler.setBackgroundColor(
      template.background && template.background.type === 'color' ? template.background.value : '#ffffff'
    )
    this.root.transactionHandler.clear()
    this.root.zoomHandler.zoomToFit()
  }
}
export default TemplateHandler
