export enum GridSize {
    Grid3
}

interface InspectorGridProps {
    gridSize: GridSize; // Replace `GridSize` with your actual type definition
    children?: React.ReactNode; // Allow optional children
}

function InspectorGrid({ gridSize, children }: InspectorGridProps) {
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', width: '292px' }}>
            {children}
        </div>
    );
}

export default InspectorGrid