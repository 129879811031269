const SvgComponent = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_15226_5934)">
      <path d="M4.9 4H9V2H4.9C3.3 2 2 3.3 2 4.9V9H4V4.9C4 4.41 4.4 4 4.9 4Z" fill="#666666"/>
      <path d="M2 19.1C2 20.7 3.3 22 4.9 22H9V20H4.9C4.41 20 4 19.6 4 19.1V15H2V19.1Z" fill="#666666"/>
      <path d="M22 4.9C22 3.3 20.7 2 19.1 2H15V4H19.1C19.59 4 20 4.4 20 4.9V9H22V4.9Z" fill="#666666"/>
      <path d="M20 19.1C20 19.59 19.6 20 19.1 20H15V22H19.1C20.73 21.96 22.03 20.67 22 19.1V15H20V19.1Z" fill="#666666"/>
    </g>
    <defs>
      <clipPath id="clip0_15226_5934">
        <rect width="20" height="20" fill="white" transform="translate(2 2)"/>
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
