import { useContext, useEffect, useState } from 'react'
import { EditorContext, RemoveEditorContext } from '@/scenes/engine'
import useAppContext from '@/hooks/useAppContext'
import { lightTheme } from '@/customTheme'
import EnhanceObjectHandler from '@/scenes/engine/handlers/remove-handler/EnhanceObjectHandler'
import { StaticImage } from 'fabric/fabric-impl'
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@/scenes/engine/objects/media-repository/media_image_type'
import api from '@/services/api'
import { MediaImageRepositoryProcessing, ResizeMode, ResizeOptions } from '@/scenes/engine/objects/media-repository/media_image_repository_processing'
import { useTranslation } from 'react-i18next'
import { AutoAdjustManager } from '@/scenes/engine/utils/autoAdjustManager'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { customAmplitude } from '@/utils/customAmplitude'
import ModalEnhanceError from '@/components/ShowModal/ModalEnhanceError'

function EnhanceTool() {
  const removeEditor = useContext(RemoveEditorContext).editor
  const { setIsLoadedJsonSuccess } = useAppContext()
  const { setisOpenPixelManipulationObject, isOpenPixelManipulationObject, toolType, setShowModalType, cancelPixelManipulation } = useAppContext()
  const [toolActive, setToolActive] = useState('before')
  const [isLoadedResult, setIsLoadedResult] = useState(false)
  const { canvas } = useContext(RemoveEditorContext)
  const { activeObject } = useContext(EditorContext)
  const Base64Prefix = 'data:image/png;base64,'
  const imageProcessing = new MediaImageRepositoryProcessing()
  

  const { t } = useTranslation()


  useEffect(() => {
    removeEditor.handlers.setPixelToolType('Enhance')
    setisOpenPixelManipulationObject(true)

    if(activeObject) {
      getResultEnhance(activeObject)
    }
  }, [])


  useEffect(() => {
    if (!isOpenPixelManipulationObject || !removeEditor || toolType !== 'Enhance') {
      return
    }
    let canvasRemoveTool = document.getElementById('wrap-canvas-remove-tool')

    ;(async () => {
      removeEditor.handlers.pixelManipulationObjectHandler.activeObject = activeObject
      await removeEditor.handlers.pixelManipulationObjectHandler.setupImage()
       await removeEditor.handlers.pixelManipulationObjectHandler.addImageToCanvas()
       // duplicate image so it can be animated to fade afterwards
       await removeEditor.handlers.pixelManipulationObjectHandler.addImageToCanvas()

      canvasRemoveTool.style.zIndex = '1'
      let enhanceObjectHandler = removeEditor.handlers.pixelManipulationObjectHandler as EnhanceObjectHandler
      enhanceObjectHandler.handleAnimate()
    })()
  }, [isOpenPixelManipulationObject, toolType])

  const getResultEnhance = async (activeObject) => {
    let refObject = activeObject as unknown as StaticImage

    // @ts-ignore
    let id = refObject.id as string
    let assetStateId = refObject.layerAssetStateId as string
    // @ts-ignore
    let originalImage = await MediaImageRepository.getInstance().getImage(
      id,
      assetStateId,
      MediaImageType.original
    )
    refObject.isLatest = false
    
    const base64 = await imageProcessing.blobUrlToBase64(originalImage)
    
    try {
      let data = await api.enhance(base64)
      await handleEnhanceImage(data.result)
      setIsLoadedResult(true)
      customAmplitude('AI enhance api call')
    } catch(err) {
      console.log('err', err)
      setIsLoadedResult(true)
      setShowModalType(ShowModalType.ENHANCE_ERROR)
      customAmplitude('AI enhance api error')
    }
  }


  const handleEnhanceImage = async (data) => {
    let refObject = activeObject as unknown as StaticImage
    //@ts-ignore
    let id = refObject.id as string
    let assetStateId = refObject.layerAssetStateId as string
    let mask = await MediaImageRepository.getInstance().getImage(
      id,
      assetStateId,
      MediaImageType.mask
    )
    
    let resizeOptions: ResizeOptions = {
            allowUpsampling: false,
            exportType: 'image/png',
            pad: false,
            resizeMode: ResizeMode.aspectFit
          }
          
    let resizedBlobUrlImage = await imageProcessing.resizeBlobToMaxEdgeSize(
      Base64Prefix+data, 
      2048,
      null,
      resizeOptions
    );

    let resizedBase64Image = Base64Prefix+(await imageProcessing.blobUrlToBase64(resizedBlobUrlImage));
    let result = await imageProcessing.generateLatestImageInfo(resizedBase64Image, mask, null)
    let flatImage = result.latestImage.toDataURL()

    let autoAdjustManager = new AutoAdjustManager();
    let base64_cal_image = await autoAdjustManager.adjust(flatImage);
    let enhanceObjectHandler = removeEditor.handlers.pixelManipulationObjectHandler as EnhanceObjectHandler
    enhanceObjectHandler.imageResult = base64_cal_image

    let objEnhance = canvas.getObjects()[1] as unknown as StaticImage
    if(!objEnhance) {
      return
    }
    // @ts-ignore
    if(!objEnhance._originalScaleX && !objEnhance._originalScaleY) {
      // @ts-ignore
      objEnhance._originalScaleX = objEnhance.scaleX
      // @ts-ignore
      objEnhance._originalScaleY = objEnhance.scaleY
    }
    objEnhance.replaceImage(base64_cal_image, true).then(() => {
      objEnhance.useNewTextureNextTime()
      canvas.renderAll();
      removeEditor.handlers.transactionRemoveHandler.save()
    })
    setIsLoadedJsonSuccess(true)
  }

  useEffect(() => {
    globalThis.directionAnimation = 1
    const containerElement = document.querySelector(
      '#wrap-canvas-remove-tool .remove-container-class'
    ) as HTMLElement
    const overlay = document.createElement('div')
    overlay.style.position = 'absolute'
    overlay.style.inset = '0'
    overlay.style.background = 'rgba(0, 0, 0, 0.5)'
    containerElement.appendChild(overlay)

    const wiper = document.createElement('div')
    wiper.id = 'wiper'
    wiper.style.position = 'absolute'
    wiper.style.top = '0'
    wiper.style.left = '0'
    wiper.style.width = '3px'
    wiper.style.height = '100%'
    wiper.style.background = '#FFF'
    wiper.style.boxShadow = '0px 0px 20.1px 7px rgba(255, 255, 255, 0.80)'
    wiper.style.zIndex = '2'
    containerElement.appendChild(wiper)

    let start = null
    const duration = 2000

    function animateWiper(timestamp) {
      if (!start) start = timestamp
      const progress = timestamp - start
      const percentage = Math.min(progress / duration, 1)
      wiper.style.left = globalThis.directionAnimation === 1 ? `${percentage * 100}%` : `${100 - percentage * 100}%`
      if (globalThis.endEnterAnimation) {
        globalThis.endEnterAnimation = false
        return
      }
      if (progress < duration) {
        requestAnimationFrame(animateWiper)
      } else {
        start = null
        globalThis.directionAnimation *= -1
        requestAnimationFrame(animateWiper)
      }
    }

    requestAnimationFrame(animateWiper)

    return () => {
      containerElement.removeChild(overlay)
      containerElement.removeChild(wiper)
      globalThis.endEnterAnimation = true
    }
  }, [])

  useEffect(() => {
    if (isLoadedResult) {
      setToolActive('after')
      exitAnimation()
    }
  }, [isLoadedResult])

  const exitAnimation = () => {
    globalThis.endEnterAnimation = true
    const containerElement = document.querySelector(
      '#wrap-canvas-remove-tool .remove-container-class'
    ) as HTMLElement
    const overlay = containerElement.querySelector('div')
    const wiper = document.getElementById('wiper') as HTMLElement

    let start = null
    const duration = 2000

    function animateWiper(timestamp) {
      if (!start) start = timestamp
      const progress = timestamp - start
      
      const percentage = Math.min(progress / duration, 1)
      wiper.style.left = globalThis.directionAnimation === 1 ? `${percentage * 100}%` : `${100 - percentage * 100}%`
      if(globalThis.directionAnimation === 1) {
        overlay.style.left = `${percentage * 100}%`
      } else {
        overlay.style.right = `${percentage * 100}%`
      }
      if (progress < duration) {
        requestAnimationFrame(animateWiper)
      } else {
        containerElement.removeChild(overlay)
        containerElement.removeChild(wiper)
        return
      }
    }

    requestAnimationFrame(animateWiper)
  }

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
        <div
          style={{
            display: 'flex',
            gap: '4px',
            padding: '6px',
            background: lightTheme.colors.grayScale50,
            borderRadius: '26px',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '6px',
              padding: '8px 0',
              background: toolActive === 'before' ? '#fff' : 'transparent',
              borderRadius: '20px',
              cursor: 'pointer',
              width: '100%',
              justifyContent: 'center',
              pointerEvents: isLoadedResult ? 'auto' : 'none',
            }}
            onClick={() => {
              setToolActive('before')
              const objEnhance = canvas.getObjects()[0]
              objEnhance.moveTo(1)
              removeEditor.emit('remove-history:changed', { hasUndo: false })
            }}
          >
            <span
              style={{
                ...lightTheme.typography.Small14Semibold,
                color: !isLoadedResult ? lightTheme.colors.grayScale200 : toolActive === 'before' ? lightTheme.colors.blackGray : 'rgba(106, 106, 106, 1)',
              }}
            >
              {t('Original')}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '6px',
              padding: '8px 0',
              background: toolActive === 'after' ? '#fff' : 'transparent',
              borderRadius: '20px',
              cursor: 'pointer',
              width: '100%',
              justifyContent: 'center',
              pointerEvents: isLoadedResult ? 'auto' : 'none',
            }}
            onClick={async () => {
              setToolActive('after')
              const objEnhance = canvas.getObjects()[1]
              objEnhance.moveTo(0)
              removeEditor.emit('remove-history:changed', { hasUndo: true })
            }}
          >
            <span
              style={{
                ...lightTheme.typography.Small14Semibold,
                color: !isLoadedResult ? lightTheme.colors.grayScale200 : toolActive === 'after' ? lightTheme.colors.blackGray : 'rgba(106, 106, 106, 1)',
              }}
            >
              {t('Enhanced')}
            </span>
          </div>
        </div>
      </div>
      <ModalEnhanceError />
    </div>
  )
}

export default EnhanceTool
