import { useEffect, useState } from 'react'
import { PLACEMENT, StatefulPopover } from 'baseui/popover'
import { HexColorPicker, HexColorInput } from 'react-colorful'
import Color from '@assets/images/Color.png'
import ColorPickerHover from '@assets/images/ColorPickerHover.png'
import { styled } from 'baseui'
import { LabelLarge } from 'baseui/typography'
import { useEditorContext } from '@/scenes/engine'
import { ObjectType } from '@/scenes/engine/common/constants'
import { lightTheme } from '@/customTheme'
import { useTranslation } from 'react-i18next'

const WrapColorButton = styled('div', (props: {$active: boolean, $color: string}) => ({
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  borderWidth: props.$active ? '4px !important' : '2px',
  background: props.$active ? 'white !important' : '',
  borderStyle: 'solid',
  transition: 'all 0.25s ease-in-out',
  ':hover': {
    cursor: 'pointer',
    transform: props.$active ? '' : 'scale(1.1)',
  },
  borderColor: props.$color,
}))
const HeartColorButton = styled('div', (props: {$color: string, $active: boolean}) => ({
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  borderWidth: props.$color === lightTheme.colors.grayScale100 && !props.$active ? 0 : '1px',
  borderStyle: 'solid',
  borderColor: props.$color,
}))
const WrapColorPicker = styled('div', props => ({
  width: '100%',
  padding: '0 16px',
}))

function ColorButton(props: {
  color: string
  handleOnClick: Function
  isActive: boolean
  isColorPicker?: boolean
  colorSelected?: string
}) {
  const { activeObject } = useEditorContext()
  const [color, setColor] = useState('#000000')
  const [isOpenPopover, setIsOpenPopover] = useState(false)

  const { t } = useTranslation()
  useEffect(() => {
    if(!activeObject || activeObject.type !== ObjectType.BAZAART_TEXT) return
    if(activeObject.fill !== color) {
      setColor(activeObject.fill as string)
    }
  }, [activeObject])

  if (props.isColorPicker) {
    // return color picker
    return (
      <div style={{ position: 'relative' }}>
        <StatefulPopover
          placement={PLACEMENT.bottomRight}
          popoverMargin={4}
          content={
            <div
              style={{
                background: '#ffffff',
                width: '292px',
                padding: '0px 0px 24px',
                border: '1px solid #E5E5E5',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
                borderRadius: '16px',
              }}
            >
              <LabelLarge
                $style={{
                  height: '50px',
                  paddingLeft: '24px',
                  display: 'flex',
                  alignItems: 'center',
                  letterSpacing: '-0.011em',
                  color: '#7a7a7e',
                }}
              >
                {t('Color')}
              </LabelLarge>
              <WrapColorPicker className="wrap-color-picker">
                <HexColorPicker
                  color={props.colorSelected ?? color}
                  onChange={e => {
                    props.handleOnClick(e)
                    setColor(e)
                  }}
                />
                <div
                  className="wrap-input"
                  style={{
                    position: 'relative',
                    marginTop: '16px',
                  }}
                >
                  <HexColorInput
                    color={props.colorSelected ?? color}
                    onClick={e => {
                      e.currentTarget.select()
                    }}
                    onChange={e => {
                      props.handleOnClick(e)
                      setColor(e)
                    }}
                  />
                  <div
                    className="display-color"
                    style={{
                      background: `${props.colorSelected ?? color}`,
                      position: 'absolute',
                      width: '32px',
                      height: '32px',
                      right: '4px',
                      top: '4px',
                      borderRadius: '8px',
                    }}
                  ></div>
                </div>
              </WrapColorPicker>
            </div>
          }
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                zIndex: '100',
              }),
            },
          }}
          stateReducer={(a, b, c) => {
            setIsOpenPopover(b.isOpen)
            return b
          }}
        >
          <WrapColorButton
            $style={{
              backgroundImage: isOpenPopover ? `url(${ColorPickerHover})` : `url(${Color})`,
              borderWidth: '0',
            }}
          >
            <div style={{ background: '#fff' }}></div>
          </WrapColorButton>
        </StatefulPopover>
      </div>
    )
  }
  // return color button
  return (
    <WrapColorButton
      className={props.isActive}
      key={props.color}
      $active={props.isActive}
      $color={props.color === '#ffffff' ? lightTheme.colors.grayScale100 : props.color}
      onClick={() => props.handleOnClick(props.color)}
      style={{
        background: props.color,
      }}
    >
      <HeartColorButton
        $active={props.isActive}
        $color={props.color === '#ffffff' ? lightTheme.colors.grayScale100 : props.color}
        style={{
          background: props.color,
        }}
      ></HeartColorButton>
    </WrapColorButton>
  )
}

export default ColorButton
