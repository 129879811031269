import { Image as ImageJs } from 'image-js';
import { GREY, RGB } from 'image-js/src/image/core/kindNames';
import { create, all, MathJsInstance } from 'mathjs'
import CanvasImageRenderer from './canvasImageRenderer';
import { AdjustmentPresetType } from './PixijsFilters/AdjustmentFilterFactory';
import { loadImageFromURL } from './image-loader';
import { fabric } from 'fabric'
import { Size } from '../objects/media-repository/size';
import { Inset } from '../objects/media-repository/inset';
import { BasePixiFilter } from './PixijsFilters/BasePixiFilter';

export class AutoAdjustManager {
    
    math: MathJsInstance
    /**
     *
     */
    constructor() {
        this.math = create(all)        
    }
    

    async adjust(base64: string): Promise<string> {
        let imgJs = await ImageJs.load(base64);
        let filters = this.acquireFilters(imgJs);

        const imageElement: any = await loadImageFromURL(base64)
        const staticImage = new fabric.StaticImage(imageElement, { })
        staticImage.set('dirty', true);
        let adjustedStaticImage = staticImage.applyFilters(filters)

        // @ts-ignore
        const dataURL = adjustedStaticImage._element.toDataURL({
            format: 'png',
            quality: 1.0,
          });
        return dataURL;
    }

      /********************************************
       * 2) Convert RGBA → separate Lab channels
       ********************************************/
      /**
       * Convert an RGBA Image to separate Lab channels + alpha channel.
       * Each Lab channel is in float (kind='GREY', bitDepth=32).
       * @param {Image} rgbaImage - an ImageJS object with alpha: true
       * @returns {[Image, Image, Image, Image]} => [L, A, B, alphaChan]
       */
      private rgbaImageToLab = (image) => {

            // Create a new image to store LAB channels
        const labImage = new ImageJs(image.width, image.height, { kind: RGB });
        for (let y = 0; y < image.height; y++) {
          for (let x = 0; x < image.width; x++) {
              const [r, g, b] = image.getPixelXY(x, y);
              const xyz = this.rgbToXyz(r, g, b);
              const { l, a, b: labB } = this.xyzToLab(xyz.x, xyz.y, xyz.z);
  
              // Normalize LAB to 8-bit values if needed
              const lNorm = Math.min(255, Math.max(0, (l / 100) * 255));
              const aNorm = Math.min(255, Math.max(0, (a + 128)));
              const bNorm = Math.min(255, Math.max(0, (labB + 128)));
  
              // Save LAB values as RGB components (since image-js is RGB)
              labImage.setPixelXY(x, y, [lNorm, aNorm, bNorm]);
          }
        }
        
        const [Lchan, Achan, Bchan] = labImage.split();  // Split the RGB channels
        return [Lchan, Achan, Bchan];
      }
  
      private rgbToXyz = (r, g, b) => {
        r = r / 255;
        g = g / 255;
        b = b / 255;
    
        // Gamma correction
        r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
        g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
        b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
    
        // Convert to XYZ
        const x = r * 0.4124 + g * 0.3576 + b * 0.1805;
        const y = r * 0.2126 + g * 0.7152 + b * 0.0722;
        const z = r * 0.0193 + g * 0.1192 + b * 0.9505;
    
        return { x: x * 100, y: y * 100, z: z * 100 };
    }
    
      // Function to convert XYZ to LAB
      private xyzToLab = (x, y, z )=> {
        const refX = 95.047; // Reference White D65
        const refY = 100.000;
        const refZ = 108.883;
    
        x = x / refX;
        y = y / refY;
        z = z / refZ;
    
        x = x > 0.008856 ? Math.cbrt(x) : (7.787 * x) + (16 / 116);
        y = y > 0.008856 ? Math.cbrt(y) : (7.787 * y) + (16 / 116);
        z = z > 0.008856 ? Math.cbrt(z) : (7.787 * z) + (16 / 116);
    
        const l = (116 * y) - 16;
        const a = 500 * (x - y);
        const b = 200 * (y - z);
    
        return { l, a, b };
    }

      private  acquireFilters = (rgbaImage): any[] =>  {
        // RGBA → Lab + alpha
        const [Lchan, Achan, Bchan, alphaChan] = this.rgbaImageToLab(rgbaImage);
  
        const adjustmentFilterFactory = CanvasImageRenderer.getInstance().adjustmentFilterFactory;
        
        // using range values from android as this logic was borrowed from there. it's not the same as in iOS - 
        let rangeValueMap = [];
        rangeValueMap['exposure'] = {
          minimumValue: -0.5,
          maximumValue: 0.5
        };
        rangeValueMap['temperature'] = {
          minimumValue: 4000,
          maximumValue: 7000
        };
        rangeValueMap['tint'] = {
          minimumValue: -80,
          maximumValue: 80
        };

        const getValueInRange = (percent: number, max: number, min: number): number => {
            let limitedPercent = Math.max(Math.min(1.0, percent), 0.0)
            let range = max - min
            return min + (limitedPercent * range)
        }

        const getChannelPercentageForAdjust = (channel: ImageJs, adjusttmentName: string, min: number, max: number): number => {
            let matrix = channel.getMatrix().to2DArray()
            const meanValue = this.math.mean(matrix) as number;
            const meanValuePercent = meanValue / 255;
            const cappedMeanValuePercent = Math.min(Math.max(meanValuePercent, min), max);

            let rangedValue = getValueInRange(cappedMeanValuePercent, rangeValueMap[adjusttmentName].minimumValue, rangeValueMap[adjusttmentName].maximumValue);
            return rangedValue;
        }

        let exposure = getChannelPercentageForAdjust(Lchan, 'exposure', 0.4, 0.6);
        let exposureFilter = adjustmentFilterFactory.getFilter(AdjustmentPresetType.exposure)({exposure: exposure});
        
        let tint = getChannelPercentageForAdjust(Achan, 'tint', 0.4, 0.6)
        let temprature = getChannelPercentageForAdjust(Bchan, 'temperature', 0.4, 0.6)
        let tintFilter = adjustmentFilterFactory.getFilter(AdjustmentPresetType.temperatureTint)({temperature: temprature, tint: tint});
        
        let adjustFilters = [exposureFilter, tintFilter]
        let fabricFilters = adjustFilters.map ( (f) => new BasePixiFilter(f, new Size(0, 0), new Inset(0,0,0,0))) 

        return fabricFilters;
      }
}