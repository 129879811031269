import {StaticPathObject} from "./StaticPath";
import { fabric } from 'fabric'
import Image from 'image-js';
import api from '@services/api'
import { MediaImageRepositoryProcessing } from '@scenes/engine/objects/media-repository/media_image_repository_processing'
import RemoveBaseHandler from "../handlers/remove-handler/RemoveBaseHandler";
import { Rectangle } from '@scenes/engine/objects/media-repository/rectangle'
import { Size } from '@scenes/engine/objects/media-repository/size'
import { RemoveHandlerOptions } from '@scenes/engine/common/interfaces'
import { MediaImageRepository } from '@scenes/engine/objects/media-repository/media_image_repository'
import { Point } from "./media-repository/point";
import * as tutorialFakeRemove from '../../../assets/images/tutorial_fake_remove.jpg';
import store from "@/store/store";


class RemoveProcessingInfo {
    mask: HTMLImageElement;
    image: HTMLImageElement;

    croppedMask: HTMLImageElement;
    croppedImage: HTMLImageElement;

    maskRoi: Rectangle;
    suggestedBoundingBox: Rectangle;

    constructor(mask: HTMLImageElement,
                image: HTMLImageElement,
                croppedMask: HTMLImageElement,
                croppedImage: HTMLImageElement,
                maskRoi: Rectangle,
                suggestedBoundingBox: Rectangle) {
        this.mask = mask;
        this.image = image;
        this.croppedMask = croppedMask;
        this.croppedImage = croppedImage;
        this.maskRoi = maskRoi;
        this.suggestedBoundingBox = suggestedBoundingBox;
    }
}


export class RemoveLogicHandler extends RemoveBaseHandler {
    private static instance: RemoveLogicHandler
    private maxEdge = 512;
    private threshold = 20.0;
    private mediaImageRepositoryProcessing: MediaImageRepositoryProcessing;

    public static getInstance(options: RemoveHandlerOptions): RemoveLogicHandler {
        if (!RemoveLogicHandler.instance) {
            RemoveLogicHandler.instance = new RemoveLogicHandler(options)
        }
        return RemoveLogicHandler.instance
    }


    constructor(options: RemoveHandlerOptions) {
        super(options);
        this.mediaImageRepositoryProcessing = MediaImageRepository.getInstance()._mediaImageRepositoryProcessing
    }

    async inpaint(image: HTMLImageElement, mask: HTMLImageElement, isTutorial: boolean = false): Promise<string | null> {
        if (isTutorial) {
            let fakeResult = await this.fakeInpaint(image, mask);
            let inpaintinFakeResultBlob = await this.mediaImageRepositoryProcessing.imageElmToBlob(fakeResult, true);
            return inpaintinFakeResultBlob;
        }

        let preprocessingInfo = await this.preprocess(image, mask);
        try {
            let patchedImage = await this.processRemotely(preprocessingInfo.image, preprocessingInfo.mask);
            let postProcessedImage = await this.applyPatch(patchedImage, image, preprocessingInfo.croppedMask, preprocessingInfo.maskRoi, preprocessingInfo.suggestedBoundingBox);

            let inpaintingResult = await this.mediaImageRepositoryProcessing.imageElmToBlob(postProcessedImage, true);
            return inpaintingResult;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    private async fakeInpaint(image: HTMLImageElement, grayscaleMask: HTMLImageElement): Promise<HTMLImageElement> {
        let tutorialImage = await this.mediaImageRepositoryProcessing.loadImage(tutorialFakeRemove.default);
        let maskedBase64 = await this.mediaImageRepositoryProcessing.applyMaskToImage(tutorialImage, grayscaleMask, false)
        let maskedImage = await this.mediaImageRepositoryProcessing.loadImage(maskedBase64);
        let fakeResult = await this.mediaImageRepositoryProcessing.composite(image, maskedImage, new Point(0, 0));
        return fakeResult;
    }
    
    private async processRemotely(image: HTMLImageElement, grayscaleMask: HTMLImageElement): Promise<HTMLImageElement> {
        const formData = new FormData();

        let grayscale1ChannelMask = await this.imageTo1ChannelPng(grayscaleMask);
        let imageBase64 = this.imageToBase64(image, false, false);
        const imageFile = this.mediaImageRepositoryProcessing.dataURIToBlob(imageBase64)
        formData.append('image', imageFile)
        formData.append('mask', grayscale1ChannelMask)

        const selectedInpaintImageApi = store.getState().editor.user.userIsPremium ? api.inpaintImage.bind(api) : api.inpaintImageUnauth.bind(api)
        const response = await selectedInpaintImageApi(formData)

        if (!response || response.status !== 200) {
            this.editorEventManager.emit('remove-tool:error')
            return null
        }

        let result = await response.data;
        let resultingBase64Url = this.mediaImageRepositoryProcessing.base64ToBlobUrl(result.data);
        let resultingImage = await this.convertBase64ToHtmlImage(resultingBase64Url, image.width, image.height);

        let imageBase64WithTransparency = await this.mediaImageRepositoryProcessing.imageElmToBlob(image, true);
        let maskInfo = await this.mediaImageRepositoryProcessing.extractMask(imageBase64WithTransparency)
        if (maskInfo.hasTransparency) {
            let originalMask = maskInfo.blob;
            let originalMaskImage = await this.convertBase64ToHtmlImage(originalMask, image.width, image.height);
            let resultingMaskedBase64Image = await this.mediaImageRepositoryProcessing.applyMaskToImage(resultingImage, originalMaskImage, true)
            let resultingMaskedImage = await this.convertBase64ToHtmlImage(resultingMaskedBase64Image, image.width, image.height);
            return resultingMaskedImage
        } else {
            return resultingImage
        }
    }

    private imageToBase64(image: HTMLImageElement, isPNG: boolean = false, clearBackground: boolean = true): string {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;

        const ctx = canvas.getContext('2d');
        if (!clearBackground) {
            ctx.fillStyle = "#ffffff";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        }

        if (!ctx) {
            throw new Error('Unable to get canvas rendering context');
        }

        // Draw the original image onto the canvas
        ctx.drawImage(image, 0, 0);

        if (isPNG) {
            return canvas.toDataURL('image/png');
        } else {
            return canvas.toDataURL("image/jpeg", 1.0);
        }

    }

    private async pathToCanvas(path): Promise<any> {
        // Create an off-screen canvas
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = path.width + path.strokeWidth;
        tempCanvas.height = path.height + path.strokeWidth;
        const tempCtx = tempCanvas.getContext('2d');

        // Move the path to the top-left corner of the temp canvas
        const pathClone = fabric.util.object.clone(path);
        pathClone.set({ left: 0, top: 0 });

        // Render the path on the temp canvas
        const pathGroup = new fabric.Group([pathClone]);
        pathGroup.render(tempCtx);

        return new Promise((resolve, reject) => {
            // Create a Fabric Image from the temp canvas data
            fabric.Image.fromURL(tempCanvas.toDataURL(), img => {
                img.set({
                    left: path.left,
                    top: path.top,
                    scaleX: path.scaleX,
                    scaleY: path.scaleY,
                    angle: path.angle,
                    originX: path.originX,
                    originY: path.originY
                });
                resolve(img);
            });
        });
    }

    private async pathToImage(drawing: StaticPathObject): Promise<HTMLImageElement> {
        let imgDrawing = await this.pathToCanvas(drawing);
        const canvas = new fabric.Canvas('drawingCanvas', {
            width: drawing.canvas.width,
            height: drawing.canvas.height
        });
        canvas.add(imgDrawing);
        canvas.renderAll();

        let that = this;
        return new Promise((resolve, reject) => {
            fabric.Image.fromURL(canvas.toDataURL(), async (img) => {
                // Render the Fabric Image on the canvas
                canvas.clear();
                canvas.add(img);
                canvas.renderAll();

                // Step 3: Get the Base64 Encoding
                let base64String = canvas.toDataURL()
                let image = await that.convertBase64ToHtmlImage(base64String, img.width, img.height);
                resolve(image);
            });
        });
    }

    async convertBase64ToHtmlImage(base64String: string, width: number, height: number): Promise<HTMLImageElement> {
        return new Promise(function(resolve, reject) {
            const image = document.createElement('img') as HTMLImageElement;
            image.width = width;
            image.height = height;
            image.onload = function() {
                resolve(image);
            };
            image.src = base64String;
        });
    }

    async preprocess(image: HTMLImageElement, originalMask: HTMLImageElement): Promise<RemoveProcessingInfo> {
        let rgbaMask = await this.mediaImageRepositoryProcessing.resizeToEdge(originalMask, Math.max(image.width, image.height), false)
        const originalMaskRoi = await this.mediaImageRepositoryProcessing.extractBoundingBox(rgbaMask.src, false)
        let maskSuggestedBoundingBox = await this.getSuggestedRoi(rgbaMask, originalMaskRoi)

        let maskSuggestedROI = maskSuggestedBoundingBox.multiply(new Size(rgbaMask.width, rgbaMask.height));
        let imageSuggestedBoundingBox = maskSuggestedBoundingBox.multiply(new Size(image.width, image.height));

        let croppedMask = await this.mediaImageRepositoryProcessing.cropHtmlImage(rgbaMask, maskSuggestedROI);
        let croppedImage = await this.mediaImageRepositoryProcessing.cropHtmlImage(image, maskSuggestedROI);

        let resizedImage = await this.mediaImageRepositoryProcessing.resizeToEdge(croppedImage, this.maxEdge);
        let resizedMask = await this.mediaImageRepositoryProcessing.resizeToEdge(croppedMask, this.maxEdge);

        let preprocessingInfo = new RemoveProcessingInfo(resizedMask, resizedImage, croppedMask, croppedImage, originalMaskRoi, maskSuggestedBoundingBox);
        return preprocessingInfo;
    }

    private async getSuggestedRoi(mask: HTMLImageElement, roi: Rectangle): Promise<Rectangle> {
        const imWidth = mask.width;
        const imHeight = mask.height;

        const bbWidth = roi.width;
        const bbHeight = roi.height;

        const maxEdge = Math.max(bbWidth, bbHeight);

        let outerBbMinY = roi.center().y - maxEdge;
        let outerBbMaxY = roi.center().y + maxEdge;
        let outerBbMinX = roi.center().x - maxEdge;
        let outerBbMaxX = roi.center().x + maxEdge;

        const maxXMargin = imWidth - outerBbMaxX;
        const minXMargin = outerBbMinX;
        const maxYMargin = imHeight - outerBbMaxY;
        const minYMargin = outerBbMinY;

        if (minXMargin < 0) {
            outerBbMinX += Math.abs(minXMargin);
        }
        if (maxXMargin < 0) {
            outerBbMaxX -= Math.abs(maxXMargin);
        }
        if (minYMargin < 0) {
            outerBbMinY += Math.abs(minYMargin);
        }
        if (maxYMargin < 0) {
            outerBbMaxY -= Math.abs(maxYMargin);
        }

        const crop = new Rectangle(
          outerBbMinX / mask.width,
          outerBbMinY / mask.height,
          (outerBbMaxX - outerBbMinX) / mask.width,
          (outerBbMaxY - outerBbMinY) / mask.height
        );
        return crop;
    }

    private async applyPatch(patchedImage: HTMLImageElement,
                             image: HTMLImageElement,
                             inputMask: HTMLImageElement,
                             originalMaskRoi: Rectangle,
                             suggestedBoundingBox: Rectangle): Promise<HTMLImageElement | null> {
        let shouldBlurMask = originalMaskRoi.width > this.maxEdge && originalMaskRoi.height > this.maxEdge;
        let mask = inputMask;
        let sourceImageSize = new Size(image.width, image.height)
        if (shouldBlurMask) {
            mask = await this.blurMask(inputMask, sourceImageSize);
        }
        if (mask.width != patchedImage.width || mask.height != patchedImage.height) {
            mask = await this.mediaImageRepositoryProcessing.resize(mask, patchedImage.width / mask.width, patchedImage.height / mask.height);
        }
        let maskedPatchBase64 = await this.mediaImageRepositoryProcessing.applyMaskToImage(patchedImage, mask, false)
        let maskedPatchImage = await this.convertBase64ToHtmlImage(maskedPatchBase64, patchedImage.width, patchedImage.height);
        let crop = suggestedBoundingBox.multiply(sourceImageSize);
        let paddedPatchImage = await this.mediaImageRepositoryProcessing.padImage(maskedPatchImage, sourceImageSize, crop);

        let paddedImage = await this.mediaImageRepositoryProcessing.composite(image, paddedPatchImage, new Point(0, 0));
        return paddedImage;
    }

    private async blurMask(mask: HTMLImageElement, maxSize: Size): Promise<HTMLImageElement | null> {
        // TODO: implement it in the future
        return mask;
    }

    private async imageTo1ChannelPng(htmlImage: HTMLImageElement): Promise<Blob> {
        let that = this;
        let imgSrc = htmlImage.src;
        let base64Image = imgSrc;
        if  (imgSrc.indexOf(';base64') == -1){
            base64Image = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.blobUrlToBase64(imgSrc);
            base64Image = 'data:image/png;base64,' + base64Image;
        } 
        let image = await Image.load(base64Image)
        let resizedImage = image.resize({ width: htmlImage.width, height: htmlImage.height })

        let grey = resizedImage.grey({
            keepAlpha: false,
            mergeAlpha: false,
            algorithm: (a) => {
                return a < that.threshold ? 0 : 255;
            }
        })

        let base64gray = await grey.toBase64();
        let blob = this.mediaImageRepositoryProcessing.dataURIToBlob(base64gray);
        return blob;
    }
}