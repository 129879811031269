import { lightTheme } from '@/customTheme'
import Icons from '@/scenes/Editor/components/Icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

function ContentForPlanV3({ planActive, planDetail }) {
  const { t } = useTranslation()
  return (
    <>
      <div style={{paddingTop: '182px'}}>
        <h4
          style={{
            margin: 0,
            ...lightTheme.typography.Header20semibold,
          }}
        >
          {planActive === 2
            ? planDetail?.year?.canFreeTrial
              ? t('%d days free', { arg: 7 })
              : t('Bazaart Premium')
            : planDetail?.month?.canFreeTrial
            ? t('%d days free', { arg: 7 })
            : t('Included in Premium')}
        </h4>
        <h5
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            marginBottom: 0,
            marginTop: '22px',
            ...lightTheme.typography.Small16Bold,
            fontWeight: 600,
          }}
        >
          {t(
            `${
              planActive === 1
                ? planDetail?.month?.canFreeTrial
                  ? 'then '
                  : ''
                : planDetail?.year?.canFreeTrial
                ? 'then '
                : ''
            }%@ per ${planActive === 2 ? 'year' : 'month'}`,
            { arg: planActive === 2 ? planDetail?.year?.price : planDetail?.month?.price }
          )}
          .
        </h5>
        {planActive === 2 && planDetail?.year?.canFreeTrial ? (
          <p
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '8px',
              marginBottom: 0,
              marginTop: '22px',
              ...lightTheme.typography.Small14regular,
            }}
          >
            <Icons.CheckIcon fill="#FF0560" />
            <span style={{ ...lightTheme.typography.Small14regular }}>
              {t('Unlock all tools and features')}
            </span>
          </p>
        ) : null}
        <p
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            marginBottom: 0,
            marginTop: '22px',
            ...lightTheme.typography.Small14regular,
          }}
        >
          <Icons.CheckIcon fill="#FF0560" />
          <span style={{ ...lightTheme.typography.Small14regular }}>
            {planActive === 2
              ? planDetail?.year?.canFreeTrial
                ? t('Pay absolutely nothing today')
                : t('Unlock all tools and features')
              : planDetail?.month?.canFreeTrial
              ? t('Pay absolutely nothing today')
              : t('Unlock all tools and features')}
          </span>
        </p>
        <p
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            marginBottom: 0,
            marginTop: '22px',
            ...lightTheme.typography.Small14regular,
          }}
        >
          <Icons.CheckIcon fill="#FF0560" />
          <span style={{ ...lightTheme.typography.Small14regular }}>
            {t('Cancel effortlessly in the app or online')}
          </span>
        </p>
        <p
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            marginBottom: 0,
            marginTop: '22px',
            ...lightTheme.typography.Small14regular,
          }}
        >
          <Icons.CheckIcon fill="#FF0560" />
          <span style={{ ...lightTheme.typography.Small14regular }}>
            {t('Join 35 million+ people who tried Bazaart')}
          </span>
        </p>
        <p
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            marginBottom: 0,
            marginTop: '22px',
            ...lightTheme.typography.Small14regular,
          }}
        >
          <Icons.CheckIcon fill="#FF0560" />
          <span style={{ ...lightTheme.typography.Small14regular }}>
            {t('Use Premium on web and mobile')}
          </span>
        </p>
      </div>
      <p
        style={{
          ...lightTheme.typography.Small12medium,
          color: lightTheme.colors.grayScale400,
          position: 'absolute',
          bottom: '16px',
          left: 0,
          right: 0,
          textAlign: 'center',
        }}
      >
        {planActive === 2 ? t('Free trial available if you haven’t used it before.') : null}
      </p>
    </>
  )
}

export default ContentForPlanV3
