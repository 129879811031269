import { useEffect, useState } from 'react'
import { IStorePack } from '@/interfaces/editor'
import { useSelector } from 'react-redux'
import { selectPack } from '@/store/slices/packs/selectors'
import { useAppDispatch } from './store/store'
import { getPack } from './store/slices/packs/actions'
import { LabelLarge, ParagraphXSmall } from 'baseui/typography'
import { styled } from 'baseui'
import { PackItem } from './components/Gallery/Gallery'
import { lightTheme } from './customTheme'
import { useTranslation } from 'react-i18next'
import Search from './scenes/Editor/components/Toolbox/ToolboxItems/components/Search'
import Icons from '@/components/icons'
import ButtonCustom from './components/ButtonCustom'
import { SizeButton } from './constants/sizeButton'
import { StatefulTooltip } from 'baseui/tooltip'
import { DotLottiePlayer } from '@dotlottie/react-player'
// @ts-ignore
import ArrowAnimation from '@assets/lottie/arrow_animation.lottie'
import { useStyletron } from 'styletron-react'
import LottiePlayerWrapper from './libs/dotLottiePlayer/LottiePlayerWrapper'

const Outline1 = styled('div', (props: { $active: boolean }) => ({
  position: 'absolute',
  inset: 0,
  borderRadius: '8px',
  border: `2px solid ${props.$active ? '#FF0560' : 'transparent'}`,
}))
const Outline2 = styled('div', (props: { $active: boolean }) => ({
  position: 'absolute',
  inset: '2px',
  borderRadius: '6px',
  border: `2px solid ${props.$active ? '#fff' : 'transparent'}`,
}))

interface props {
  pack: IStorePack
  style: React.CSSProperties
  onItemSelected: Function
  handleClickSubAction?: Function
  itemsPerLine: 'auto' | number
  bgActiveID?: Object
  hasPremium?: boolean,
  showSearchButton?: boolean
  handleClickSearchIcon?: Function
}

function PackContainer({
  pack,
  style,
  onItemSelected,
  handleClickSubAction,
  itemsPerLine,
  bgActiveID,
  hasPremium,
  showSearchButton = false,
  handleClickSearchIcon
}: props) {

  const packObjects = useSelector(selectPack(pack && pack.id))
  const isCompact = !!handleClickSubAction

  const dispath = useAppDispatch()
  const { t } = useTranslation()

  const [defaultItemTotal, setDefaultItemTotal] = useState(10)
  const [nameSubAction, setNameSubAction] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const placeholderFill = new Array(itemsPerLine === 'auto' ? 10 : itemsPerLine * 2).fill(null);

  useEffect(() => {
    setDefaultItemTotal(itemsPerLine === 'auto' ? 10 : itemsPerLine * 2)
    if (!packObjects && pack) {
      dispath(getPack(pack && pack.id))
    }
    setNameSubAction(isCompact ? 'See all' : 'Show more')
  }, [pack])

  useEffect(() => {
    if (packObjects && packObjects.length > 0) {
      setIsLoaded(true);
    }
  }, [packObjects]);

  const handleAction = () => {
    if (isCompact) {
      handleClickSubAction()
    } else {
      setDefaultItemTotal(pre =>
        pre !== packObjects?.length ? packObjects.length : itemsPerLine === 'auto' ? 10 : itemsPerLine * 2
      )
      setNameSubAction(pre => (pre === 'Show more' ? 'Show less' : 'Show more'))
    }
  }

  const handleSelectItem = packItem => {
    onItemSelected(packItem)
  }
  const [css] = useStyletron()
  const [showToggleBtn, setShowToggleBtn] = useState(false)

  return (
    <div style={{ width: '300px', ...style }} onMouseEnter={() => setShowToggleBtn(true)} onMouseLeave={() => setShowToggleBtn(false)}>
      <div
        style={{
          height: '26px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}
      >
        <LabelLarge
          $style={{
            ...lightTheme.typography.Small14Semibold,
            color: lightTheme.colors.text.text_black_title,
          }}
        >
          {isCompact ? t('Fill') : pack && t(pack.name)}
        </LabelLarge>
        <ParagraphXSmall
          onClick={() => handleAction()}
          $style={{
            boxSizing: 'border-box',
            cursor: 'pointer',
            padding: '0 8px',
            borderRadius: '12px',
            whiteSpace: 'nowrap',
            ':hover': {
              background: 'rgb(229, 229, 229)',
            },
            ...lightTheme.typography.Small12medium,
            color: lightTheme.colors.text.text_black_title,
          }}
        >
          {t(nameSubAction)}
        </ParagraphXSmall>
      </div>
      {showSearchButton ? <div onClick={() => handleClickSearchIcon()}><Search
        handleValueChange={() => {}}
        size="standard"
        valueExternal={''}
        setValueExternal={() => {}}
        handleReset={() => {}}
      /></div> : null}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          columnGap: '8px',
          rowGap: '8px',
          marginTop: '16px'
        }}
      >
        {/* packObjects can be null when first loaded */}
        {(isLoaded ? (Array.isArray(packObjects) ? packObjects : placeholderFill) : placeholderFill).map(
          (packObject, index) => {
            if (index < (itemsPerLine === 'auto' ? 10 : itemsPerLine * 2)) {
              let isSelected = bgActiveID == packObject?.id
              return (
                <div style={{ display: 'flex', position: 'relative' }} key={packObject?.id ? packObject.id : index}>
                  <PackItem
                    onClick={() => {
                      if (packObject) {
                        handleSelectItem(packObject)
                      }
                    }}
                    style={{
                      wrap: {
                        width: itemsPerLine === 4 ? '67px' : '52px',
                        height: itemsPerLine === 4 ? '67px' : '52px',
                        padding: '0',
                        zIndex: '1',
                      },
                      placehoder: {
                        inset: 0,
                      },
                      image: {
                        objectFit: 'cover',
                      },
                    }}
                    pack={{
                      thumbnail: packObject?.thumbnail,
                      id: packObject?.id,
                    }}
                    onDrag={() => {}}
                    isSelected={isSelected}
                    isLocked={!hasPremium && [0, 3, 4].includes((index+1) % 5)}
                    CTA="BtSubscriptionBackgrounds"
                  >
                    <Outline1 $active={isSelected}></Outline1>
                    <Outline2 $active={isSelected}></Outline2>
                  </PackItem>
                </div>
              )
            } else {
              if (defaultItemTotal === packObjects.length) {
                let isSelected = bgActiveID == packObject.id
                let is4sItemPerLine = itemsPerLine === 4
                return (
                  <div style={{ display: 'flex', position: 'relative' }} key={packObject.id ? packObject.id : index}>
                    <PackItem
                      onClick={() => handleSelectItem(packObject)}
                      style={{
                        wrap: {
                          width: is4sItemPerLine ? '67px' : '52px',
                          height: is4sItemPerLine ? '67px' : '52px',
                          padding: '0',
                          zIndex: '1',
                        },
                        placehoder: {
                          inset: 0,
                        },
                        image: {
                          objectFit: 'cover',
                        },
                      }}
                      pack={{
                        thumbnail: packObject.thumbnail,
                        id: packObject.id,
                      }}
                      onDrag={() => {}}
                      isSelected={isSelected}
                      isLocked={!hasPremium && [0, 3, 4].includes((index+1) % 5)}
                      CTA="BtSubscriptionBackgrounds"
                    >
                      <Outline1 $active={isSelected}></Outline1>
                      <Outline2 $active={isSelected}></Outline2>
                    </PackItem>
                  </div>
                )
              }
            }
          }
        )}
      </div>
      {
        !isCompact ? <div
        className={css({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '13px auto',
          gap: '6px',
          background: lightTheme.colors.grayScale50,
          ':hover': {
            background: lightTheme.colors.grayScale100,
          },
          width: 'fit-content',
          height: '24px',
          borderRadius: '360px',
          padding: '0 8px',
          opacity: showToggleBtn ? 1 : 0,
          transition: 'opacity 0.3s',
        })}
      >
        <ParagraphXSmall
          onClick={() => handleAction()}
          $style={{
            boxSizing: 'border-box',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            ...lightTheme.typography.Small12medium,
            color: lightTheme.colors.text.text_black_title,
          }}
        >
          {t(nameSubAction)}
        </ParagraphXSmall>
        <LottiePlayerWrapper
          play={showToggleBtn}
          src={ArrowAnimation}
          autoplay={false}
          loop
          style={
            {
              width: '10px',
              height: '14px',
              transform: nameSubAction === 'Show more' ? 'none' : 'rotate(180deg)',
            }
          }
        ></LottiePlayerWrapper>
      </div> : null
      }
    </div>
  )
}

export default PackContainer
