import { lightTheme } from '@/customTheme'
import { ThemeProvider, styled } from 'baseui'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectTemplatesSuperCategories } from '@/store/slices/templates/selectors'
import { useAppDispatch } from '@/store/store'
import { getTemplatesSuperCategories } from '@/store/slices/templates/actions'
import { useHistory } from 'react-router'
import { setCallbackFromCreationPage } from '@/store/slices/editor/action'
import { ActionType } from '@/store/slices/editor/reducer'
import { useTranslation } from 'react-i18next'
import PopularImage from '@assets/images/super-caterories/popular.png'
import BusinessImage from '@assets/images/super-caterories/business.png'
import SocialImage from '@assets/images/super-caterories/social.png'
import CelebrationImage from '@assets/images/super-caterories/celebration.png'
import ValentineImage from '@assets/images/super-caterories/valentine.png'
import PersonalImage from '@assets/images/super-caterories/personal.png'
import HolidayImage from '@assets/images/super-caterories/holiday.png'
import SalesImage from '@assets/images/super-caterories/sales.png'
import Icons from '../Editor/components/Icons'
import { useStyletron } from 'styletron-react'
import ScrollButton from './components/ScrollButton'

const ImagesForSuperCategoriesHolidays = [
  {
    src: PopularImage,
    backgroundColor: '#FCF0DA',
  },
  {
    src: HolidayImage,
    backgroundColor: '#E0E9F7',
  },
  {
    src: SalesImage,
    backgroundColor: '#E5DAFC',
  },
  {
    src: SocialImage,
    backgroundColor: '#FADFF9',
  },
  {
    src: BusinessImage,
    backgroundColor: '#E0F2E5',
  },
]

const ImagesForSuperCategoriesValentain = [
  {
    src: PopularImage,
    backgroundColor: '#FCF0DA',
  },
  {
    src: ValentineImage,
    backgroundColor: '#FADFF9',
  },
  {
    src: SocialImage,
    backgroundColor: '#E5DAFC',
  },
  {
    src: BusinessImage,
    backgroundColor: '#E0F2E5',
  },
  {
    src: PersonalImage,
    backgroundColor: '#E0E9F7',
  }
]

const ImagesForSuperCategories = [
  {
    src: PopularImage,
    backgroundColor: '#FCF0DA',
  },
  {
    src: SocialImage,
    backgroundColor: '#FADFF9',
  },
  {
    src: BusinessImage,
    backgroundColor: '#E0F2E5',
  },
  {
    src: PersonalImage,
    backgroundColor: '#E0E9F7',
  },
  {
    src: CelebrationImage,
    backgroundColor: '#E5DAFC',
  },
]

const WrapTemplateItem = styled('div', (props: any) => ({
  position: 'relative',
  minWidth: '271px',
  height: '144px',
  borderRadius: '16px',
  overflow: 'hidden',
  cursor: 'pointer',
  gap: '12px',
  padding: '16px',
  ':hover': {
    backgroundColor: lightTheme.colors.grayScale100,
  },
  ':active': {
    backgroundColor: lightTheme.colors.grayScale150,
  },
  ...props.styles,
}))

function Templates() {
  const dispatch = useAppDispatch()
  const templatesSuperCategories = useSelector(selectTemplatesSuperCategories)

  useEffect(() => {
    if (!templatesSuperCategories.length) {
      dispatch(getTemplatesSuperCategories())
    }
  }, [])

  const history = useHistory()
  const { t } = useTranslation()
  const [css] = useStyletron()
  const containerRef = useRef<HTMLDivElement>(null)

  const [visibleScrollBtn, setVisibleScrollBtn] = useState({ left: false, right: false })
  useEffect(() => {
    const handleResize = () => {
      // 1452 is the width of max items in the container
      if(containerRef.current.clientWidth < 1452) {
        setVisibleScrollBtn({ left: false, right: true })
      } else {
        setVisibleScrollBtn({ left: false, right: false })
      }
      if(containerRef.current.scrollLeft === 0) {
        setVisibleScrollBtn(pre => ({ ...pre, left: false }))
      } else {
        setVisibleScrollBtn(pre => ({ ...pre, left: true }))
      }
    };
    handleResize()
    // Add the event listener
    window.addEventListener('resize', handleResize);

    // Remove the event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleScroll = (direction: 'left' | 'right') => {
    const widthTemplateItem = 271
    const gap = 24
    let itemsVissible = Math.floor(containerRef.current.clientWidth / (widthTemplateItem + gap))
    if(direction === 'left') {
      if(containerRef.current.scrollLeft + containerRef.current.clientWidth === containerRef.current.scrollWidth) {
        containerRef.current.scrollLeft -= containerRef.current.clientWidth
      } else {
      containerRef.current.scrollLeft -= (widthTemplateItem + gap) * itemsVissible
      }
      const isReachEnd = containerRef.current.scrollLeft >= containerRef.current.scrollWidth
      setVisibleScrollBtn(pre => ({ left: containerRef.current.scrollLeft < containerRef.current.clientWidth ? false : true, right: isReachEnd ? false : true }))
    } else {
      let newValue = containerRef.current.scrollLeft + (widthTemplateItem + gap) * itemsVissible
      containerRef.current.scrollLeft = newValue
      const isReachEnd = newValue + containerRef.current.clientWidth >= containerRef.current.scrollWidth
      setVisibleScrollBtn(pre => ({ left: true, right: isReachEnd ? false : true }))
    }
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          marginTop: '22px',
          position: 'relative',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h2 style={{ ...lightTheme.typography.Header20bold }}>{t('Discover templates')}</h2>
          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              borderRadius: '12px',
              padding: '0 4px 0 8px',
              height: '24px',
              ':hover': {
                background: lightTheme.colors.grayScale50,
              },
              ':active': {
                background: lightTheme.colors.grayScale100,
              },
            })}
            onClick={() => {
              dispatch(
                setCallbackFromCreationPage({
                  action: ActionType.ADD_TEMPLATE,
                  value: {
                    name: 'Popular',
                    superCategoryId: 25,
                  },
                })
              )
              history.push('/')
            }}
          >
            <p
              style={{
                ...lightTheme.typography.Small12medium,
              }}
            >
              {t('See all')}
            </p>
            <Icons.RedArrowRight fill={lightTheme.colors.blackGray} />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '24px', overflow: 'hidden', width: '100%', scrollBehavior: 'smooth' }} ref={containerRef}>
          {templatesSuperCategories.map((superCategory, index) => {
            return ImagesForSuperCategoriesValentain[index] ? (
              <TemplateItem
                onClick={() => {
                  dispatch(
                    setCallbackFromCreationPage({
                      action: ActionType.ADD_TEMPLATE,
                      value: {
                        superCategoryName: superCategory.name,
                        superCategoryId: superCategory.id,
                      },
                    })
                  )
                  history.push('/')
                }}
                backgroundColor={ImagesForSuperCategoriesValentain[index].backgroundColor}
                backgroundSrc={ImagesForSuperCategoriesValentain[index].src}
                superCategory={superCategory}
              />
            ) : null
          })}
        </div>
        {visibleScrollBtn.left ? <ScrollButton direction="left" handle={() => handleScroll('left')} styles={{ left: '0px', top: '100px', transform: 'translateX(-50%)' }} /> : null}
        {visibleScrollBtn.right ? <ScrollButton direction="right" handle={() => handleScroll('right')} styles={{ right: '0px', top: '100px', transform: 'translateX(50%)' }} /> : null}
      </div>
    </ThemeProvider>
  )
}

export default Templates

function TemplateItem({ onClick, superCategory, backgroundColor, backgroundSrc }) {
  const { t } = useTranslation()
  const [isHover, setIsHover] = useState(false)

  return (
    <WrapTemplateItem
      onClick={onClick}
      key={superCategory.id}
      style={{
        background: backgroundColor,
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div
        style={{
          width: isHover ? '134px' : '120px',
          height: isHover ? '134px' : '120px',
          borderRadius: '8px',
          position: 'absolute',
          right: '0px',
          bottom: '0px',
          transition: 'all .3s ease-out'
        }}
      >
        <img
          src={backgroundSrc}
          alt="template category img"
          style={{
            width: '100%',
            height: '100%',
            filter: '0px 4px 12px 0px rgba(0, 0, 0, 0.12)',
            pointerEvents: 'none',
          }}
        />
      </div>
      <p style={{ margin: 0, ...lightTheme.typography.Header20bold, width: '147px', overflowWrap:'break-word' }}>{t(superCategory.name)}</p>
    </WrapTemplateItem>
  )
}
