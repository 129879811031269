// @ts-nocheck

import { fabric } from 'fabric'
import { controlPositionIcons } from './controls'
import * as ControlsCrop from './controlsCrop';
import { loadImageFromURL } from '../../utils/image-loader'
import { MediaImageRepository } from '@scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@scenes/engine/objects/media-repository/media_image_type'
import { Rectangle } from '@scenes/engine/objects/media-repository/rectangle'
import { Inset } from '@scenes/engine/objects/media-repository/inset'
import { Size } from '@scenes/engine/objects/media-repository/size'
import { nanoid } from 'nanoid'
import CanvasImageRenderer from '@scenes/engine/utils/canvasImageRenderer'
import store from '@/store/store'
import { ObjectType } from '../../common/constants'
import { customAmplitude } from '@/utils/customAmplitude'

export enum ControlPositions {
  TOP_LEFT = 'tl',
  TOP = 't',
  TOP_RIGHT = 'tr',
  RIGHT = 'r',
  BOTTOM_RIGHT = 'br',
  BOTTOM = 'b',
  BOTTOM_LEFT = 'bl',
  LEFT = 'l',
}

export class StaticImageObject extends fabric.Image {
  static type = 'StaticImage'
  public _editingMode = false
  __editingImage = null
  private _inset: Inset = null
  private boundingBox: Rectangle | null = null;
  private _controlsBeforeCrop = this.controls;
  private _duringEnterEditingMode = false
  private _duringExitEditingMode = false
  private _stackIndexBeforeEditingMode = -1;
  private _listenersBeforeEditingMode = []
  private aspectRatio = 1

  cornerLengthEditing = 5
  effects: {
    [key: string]: {
      [key: string]: any
    }
  } = {}
  effects_from_template: {
    [key: string]: {
      [key: string]: any
    }
  } = {}
  
  useBzrtBgMask = false
  isLatest = false

  /**
   * Color of the corner stroke in editing mode.
   */
  cornerStrokeColorEditing = 'black'

  /**
   * Size of the corner stroke in editing mode.
   */
  cornerSizeEditing = 2

  hasTransparency = true

  layerAssetStateId = null

  sizeOnCanvas = {
    width: 0.5,
    height: 0.5,
  }

  isTemplateLayer = false

  _filterScalingX = 1
  _filterScalingY = 1

  _filterScalingXBeforCrop = 1;
  _filterScalingYBeforCrop = 1;

  cacheCounter = 0

  isIntializedNormalizeMask = true
  isIntializedNormalizedImage = true
  
  isIntialized () {
    return this.isIntializedNormalizeMask && this.isIntializedNormalizedImage;
  };

  useNewTextureNextTime() {
    this.cacheCounter ++
  }

  getTextureKey(){
    return `${this.cacheKey}_${this.layerAssetStateId}_${this.cacheCounter}`;
  }

  useTextureOf(sourceObject:StaticImage)
  {
    this.cacheKey = sourceObject.cacheKey
    this.layerAssetStateId = sourceObject.layerAssetStateId
    this.cacheCounter = sourceObject.cacheCounter
  }

  async replaceImage(
    image: base64Image,
    withResize: boolean,
    magicLayer?: boolean = false
  ): Promise<boolean> {
    let self = this
    return loadImageFromURL(image).then(img => {
      //@ts-ignore

      let imageScaleX = self.width / img.width
      let imageScaleY = self.height / img.height

      !magicLayer && self.setElement(img)

      if (withResize) {
        let newScale = Math.max(imageScaleX, imageScaleY)
        self._originalScaleX *= self.type === ObjectType.BAZAART_IMAGE ? imageScaleX : newScale
        self._originalScaleY *= self.type === ObjectType.BAZAART_IMAGE ? imageScaleY : newScale
        self.scaleX *= self.type === ObjectType.BAZAART_IMAGE ? imageScaleX : newScale
        self.scaleY *= self.type === ObjectType.BAZAART_IMAGE ? imageScaleY : newScale
      }
      
      self.set('isLatest', false);
      self.set('dirty', true)
      self.useNewTextureNextTime();
        //@ts-ignore
      self.applyFilters(self.filters)
    })
  }

  async replaceImageUrl(url: string, withResize: boolean, magicLayer?: boolean = false, generateNewMask: boolean = false) {
    let assetStateId = nanoid()
    let prevMask = await MediaImageRepository.getInstance().getImage(this.id ,this.layerAssetStateId, MediaImageType.mask)

    await MediaImageRepository.getInstance().storeImageUrl(
      this.id,
      assetStateId,
      MediaImageType.original,
      url
    )

    await MediaImageRepository.getInstance().storeImageBlobString(
      this.id,
      assetStateId,
      MediaImageType.mask,
      prevMask
    )
    

    let latestImageInfo = await MediaImageRepository.getInstance().generateLatestImageInfo(this.id, assetStateId)
    await MediaImageRepository.getInstance().storeLatestImageInfo(this.id, assetStateId, latestImageInfo)
    
    this.boundingBox = latestImageInfo.boundingBox;
    await this.replaceImage(latestImageInfo.latestImage.toDataURL(), withResize, magicLayer)

    this.set('layerAssetStateId', assetStateId)
    if(!magicLayer) {
      this.set('dirty', true)
      this.applyFilters()
      this.canvas?.requestRenderAll()
    }
  }

  disableObjectModifiedEvents() {
    this._listenersBeforeEditingMode = this.canvas.__eventListeners?.['object:modified']?.slice() || [];
    this.canvas.off('object:modified')
  }

  restoreObjectModifiedEvents() {  
    this.canvas.off('object:modified')
    this._listenersBeforeEditingMode.forEach((listener) => this.canvas.on('object:modified', listener));
  }


  registerEditingEvents() {
    // register to dubble click event to start crop mode - disbable for now
    // this.on('mousedblclick', () => {
    //   if (this.type != 'bazaart.item.image') {
    //     return
    //   }
    //   if (!this._editingMode) {
    //     return this.enterEditingMode()
    //   } else {
    //     this.exitEditingMode()
    //   }
    // })
    // replace image :
    var input = document.createElement('input')
    input.type = 'file'
    input.onchange = e => {
      var file = e.target.files[0]
      if (file) {
        var reader = new FileReader()
        reader.onload = async event => {
          await this.replaceImageUrl(event.target.result)
        }

        reader.readAsDataURL(file)
      }
    }

    this.on('mousedblclick', () => {
      // input.click();
      // return;
    })
    this.on('deselected', () => {
      // if (this._editingMode) {
      //   this.exitEditingMode()
      // }
      this.perPixelTargetFind = true
    })
    this.on('selected',()=>{
      // on selected state we want that transpaernt pixels will be selectable too, for batter dragging (using on _checkTarget func)
      this.perPixelTargetFind = false
    })
  }

  setAnchorToTopLeft() {
    // Get the center of the image
    const center = this.getCenterPoint();
    const originOffsetX = (this.width * this.scaleX) / 2;
    const originOffsetY = (this.height * this.scaleY) / 2;
  
    // Calculate top and left positions
    const unrotatedTopLeft = new fabric.Point(center.x - originOffsetX, center.y - originOffsetY)
    const rotatedTopLeft = fabric.util.rotatePoint(unrotatedTopLeft, center, fabric.util.degreesToRadians(this.angle));

    // Update the position to switch to top-left anchor
    this.set({
      originX: 'left',
      originY: 'top',
      left: rotatedTopLeft.x,
      top: rotatedTopLeft.y,
    });
  
    // Update the coordinates in Fabric.js
    this.setCoords();
  }
    
  setAnchorToCenter() {
    // Get the current top-left point of the image
    const topLeft = new fabric.Point(this.left, this.top);
    const originOffsetX = (this.width * this.scaleX) / 2;
    const originOffsetY = (this.height * this.scaleY) / 2;

    // Calculate center point position
    const unrotatedCenter = new fabric.Point(topLeft.x + originOffsetX, topLeft.y + originOffsetY)
    const rotatedCenter = fabric.util.rotatePoint(unrotatedCenter, topLeft, fabric.util.degreesToRadians(this.angle));

    // Update the position to switch to center anchor
    this.set({
      originX: 'center',
      originY: 'center',
      left: rotatedCenter.x,
      top: rotatedCenter.y,
    });
  
    // Update the coordinates in Fabric.js
    this.setCoords();
  }
  
  setEditingImage() {
    const angleInRadians = fabric.util.degreesToRadians(this.__editingImage.angle);
  
    // Crop offsets in the rotated frame
    let cropOffsetX = 0
    let cropOffsetY = 0
    if (this.flipX) {
      cropOffsetX = (this.__editingImage._element.naturalWidth - this.width - this.cropX) * this.__editingImage.scaleX
    }
    else {
      cropOffsetX = this.__editingImage.cropX * this.__editingImage.scaleX
    }

    if (this.flipY) {
      cropOffsetY = (this.__editingImage._element.naturalHeight - this.height - this.cropY) * this.__editingImage.scaleY
    }
    else {
      cropOffsetY = this.__editingImage.cropY * this.__editingImage.scaleY
    }
    
    const rotationAnchor = new fabric.Point(this.__editingImage.left, this.__editingImage.top)
    const unrotatedTopLeft = fabric.util.rotatePoint(new fabric.Point(this.__editingImage.left, this.__editingImage.top), rotationAnchor, -fabric.util.degreesToRadians(this.angle))
    const unrotatedTopLeftWithOffset = new fabric.Point(unrotatedTopLeft.x - cropOffsetX, unrotatedTopLeft.y - cropOffsetY)
    const rotatedTopLeftWithOffset = fabric.util.rotatePoint(unrotatedTopLeftWithOffset, rotationAnchor, fabric.util.degreesToRadians(this.angle))
  
    // Update the position to remove the crop offsets
    this.__editingImage.set({
      left: rotatedTopLeftWithOffset.x,
      top: rotatedTopLeftWithOffset.y,
      cropX: 0,
      cropY: 0,
    });
  
    // Ensure coordinates are updated
    this.__editingImage.setCoords();
  }  

  toggleEditingMode() {
    if (this._editingMode) {
      this.exitEditingMode()
    } else {
      this.enterEditingMode()
    }
  }

  async enterEditingMode() {
    if (this._editingMode || this._duringEnterEditingMode || this._duringExitEditingMode) {
      return
    }
    let edit = async ()=> {
      if (this.selectable && this.canvas) {
        this.setAnchorToTopLeft()
        this.originalLeft = this.left;
        this.originalTop = this.top;
        
        this.clone((image: fabric.Image) => {
          const element = image.getElement()
          this.__editingImage = image
          this.setEditingImage()
          this.__editingImage.set({
            height: element.height,
            width: element.width,
            opacity: 0.5,
            selectable: false,
            evented: false,
            excludeFromExport: true,
            clipPath: null
          })
  
          this.canvas!.add(this.__editingImage)
          this.on('moving', (this.__editingOnMoving = this.__editingOnMoving.bind(this)))
          this.on('mouseup', () => {
            this.startPointer = null;
            this.removeGrid()
          });
          this._controlsBeforeCrop = { ...this.controls }
          this.controls = this.__editingControls()
          this.canvas?.requestRenderAll()
        })
      }
      this._editingMode = true
    }

    // Save z-index and bring to front
    this._stackIndexBeforeEditingMode = this.canvas.getObjects().indexOf(this)
    this.isolateImage()

    if (this.isLatest && !this._duringEnterEditingMode && !this._editingMode) {
      this._duringEnterEditingMode = true
      let renderer = CanvasImageRenderer.getInstance();
      // TODO: change this to parameter size instead of square size.
      let canvasSize = new Size(2048, 2048);

      // let canvasSize = new Size(beforeCrop.width, beforeCrop.height);
      let filtersColorCube = store.getState().editor.imageElements.imageElements;
      await renderer.render(this, canvasSize, filtersColorCube);

      // we need to render the layer twice, we need the first one to set the correct image size and then the second time to be relative to the correct size.
      // this is a more basic bug we should fix sometime
      await renderer.render(this, canvasSize, filtersColorCube);

      this.canvas.renderAll()

      this._duringEnterEditingMode = false
      setTimeout(async () => {
        await this.enterEditingMode()
      })
      return;
    }
    // fire event in order to add to history
    this.canvas.fire('object:modified', { target: this })
    this.disableObjectModifiedEvents()
    customAmplitude('Selected tool', {
      Tool: 'bazaart.cutout.crop',
    })

    const beforeCrop = this.getElement() // for discard purposes

    const loadAssetAsImage = (type: MediaImageType): Promise<HTMLImageElement> => {
      return MediaImageRepository.getInstance()
        .getImage(this.bazaartGuid, this.layerAssetStateId, type)
        .then((base64) => {
          if (!base64) {
            return null;
          }
          return MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(base64);
        });
    };
    
    
    let promises = [loadAssetAsImage(MediaImageType.original), loadAssetAsImage(MediaImageType.maskWithoutCrop), loadAssetAsImage(MediaImageType.mask)]
    Promise.all(promises).then(async (images) => {
        let original = images[0];
        let mask = images[1] ?? images[2]
        let latest = this._element
    
        let hasTransparency = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.hasTransparency(latest.src);
        let maskedImage: HTMLImageElement;
        if (hasTransparency) {
          let maskedBase64Image = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.applyMaskToImage(original, mask);
          maskedImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(maskedBase64Image);
        } else {
          maskedImage = original;
        }
        

        if (this._duringEnterEditingMode || this._editingMode) {
          return
        }
        this._duringEnterEditingMode = true
        this.setElement(maskedImage)
        
        this._filterScalingXBeforCrop = this._filterScalingX;
        this._filterScalingYBeforCrop = this._filterScalingY;
  
        this.scaleX *= this._filterScalingX;
        this.scaleY *= this._filterScalingY;
        
        this._filterScalingX = 1;
        this._filterScalingY = 1;

        let cropX = this.boundingBox.x * original.width
        let cropY = this.boundingBox.y * original.height
        this.set({
          cropX: cropX,
          cropY: cropY,
          width: this.boundingBox.width * original.width,
          height: this.boundingBox.height * original.height,
        })
        await edit()
        this._duringEnterEditingMode = false
      })
  }

  async exitEditingMode(cb?: ()=>void) {
    if (!this._editingMode || this._duringExitEditingMode) {
      return
    }
    this._duringExitEditingMode = true
    
    // restore zIndex position on the canvas
    this.moveTo(this._stackIndexBeforeEditingMode)

    if (this.selectable && this.canvas) {
      if (this.__editingImage) {
        this.canvas.remove(this.__editingImage)
        this.__updateBoundingBox();
        this.__editingImage = null
        this.setAnchorToCenter()
      }
      this.off('moving', this.__editingOnMoving)
      this.controls = this._controlsBeforeCrop
      this.setCoords()
      this.canvas?.requestRenderAll()

      let layerId = this.id
      let currentAssetStateId = this.layerAssetStateId
      let newAssetStateId = nanoid()
      let absolueBoundingBox = new Rectangle(
        this.cropX ?? 0,
        this.cropY ?? 0,
        this.width,
        this.height
      )
      this.removeIsolation()
      
      let latest = this._element
      let hasTransparency = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.hasTransparency(latest.src)

      let original = await MediaImageRepository.getInstance().getImage(layerId, currentAssetStateId, MediaImageType.original)
      await MediaImageRepository.getInstance().storeImageBlobString(layerId, newAssetStateId, MediaImageType.original, original)

      let currentMask = await MediaImageRepository.getInstance().getImage(layerId, currentAssetStateId, MediaImageType.maskWithoutCrop)
      if (!currentMask) {
        currentMask = await MediaImageRepository.getInstance().getImage(layerId, currentAssetStateId, MediaImageType.mask)
      }
      await MediaImageRepository.getInstance().storeImageBlobString(layerId, newAssetStateId, MediaImageType.maskWithoutCrop, currentMask)
      
      let currentMaskImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(currentMask);  
      if (!hasTransparency){
        let emptyMaskBase64 = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.createColorImage('#000000', currentMaskImage.width, currentMaskImage.height)
        currentMaskImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage( emptyMaskBase64);
      }

      
      let croppedMask = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.cropHtmlImage(currentMaskImage, absolueBoundingBox);
      let paddedMask = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.padImage(croppedMask, new Size(currentMaskImage.width, currentMaskImage.height,), absolueBoundingBox, "#ffffff");
      let greyMask = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractGreyMaskFromColorImage(paddedMask.src);
      await MediaImageRepository.getInstance().storeImageBase64(layerId, newAssetStateId, MediaImageType.mask, greyMask.src)


      let latestImageInfo = await MediaImageRepository.getInstance().generateLatestImageInfo(layerId, newAssetStateId, null, absolueBoundingBox)
      await MediaImageRepository.getInstance().storeLatestImageInfo(layerId, newAssetStateId, latestImageInfo)

      this._filterScalingX = this._filterScalingXBeforCrop;
      this._filterScalingY = this._filterScalingYBeforCrop;

      this._originalScaleX = this.scaleX * this._filterScalingX
      this._originalScaleY = this.scaleY * this._filterScalingY

      this.scaleX *= this._filterScalingX;
      this.scaleY *= this._filterScalingY;
      
      this._filterScalingXBeforCrop = 1.0;
      this._filterScalingYBeforCrop = 1.0;
      
      this._originalScaleX = this.scaleX * this._filterScalingX
      this._originalScaleY = this.scaleY * this._filterScalingY

      this.set('layerAssetStateId', newAssetStateId) // should be call before replaceImage because texture using it, and its should be updated.
      this._editingMode = false

      const dataURL = latestImageInfo.latestImage.toDataURL()
      await this.replaceImage(dataURL, true)
      this.set({
        cropX: 0,
        cropY: 0
      })
      
      this.canvas?.renderAll()

      this.set('isLatest', false);

      this.applyFilters()
    }
    this._duringExitEditingMode = false
    cb?.()
    this.restoreObjectModifiedEvents()
    this.canvas.fire('object:modified', { target: this })
  }

  isolateImage() {
    if (!this.canvas) {
      console.error('A valid Fabric canvas instance is required.')
      return;
    }

    if (this._overlay) {
      console.warn('Overlay already exists for this object.')
      return;
    }

    const canvasElement = this.canvas.getElement() as HTMLCanvasElement
    const canvasRect = canvasElement.getBoundingClientRect()
    const canvasWidth = canvasRect.width / this.canvas.getZoom() * 10
    const canvasHeight = canvasRect.height / this.canvas.getZoom() * 10
    // Create a dark overlay that fills the visible canvas
    const overlay = new fabric.Rect({
      left: -canvasWidth / 2,
      top: -canvasHeight / 2,
      width: canvasWidth, // Use the visible width
      height: canvasHeight, // Use the visible height
      fill: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
      selectable: false,
      evented: true, // Prevent interactions with the overlay
      excludeFromExport: true,
      clipPath: null
    })
    
    this.canvas.add(overlay)

    // Add event listener for window resize to re-render the overlay
    window.addEventListener('resize', () => {
      this._resizeOverlay()
    })
    
    this.canvas.sendToBack()

    this.bringToFront()
    this._overlay = overlay

    this.canvas.renderAll()
  }

  removeIsolation() {
    if (!this._overlay) {
      console.warn('No overlay exists for this object.')
      return;
    }

    // Remove the overlay from the canvas
    this.canvas.remove(this._overlay)
    this._overlay = undefined
    window.removeEventListener('resize', this._resizeHandler);
    this.canvas.renderAll()
  }

  _resizeOverlay() {
    if (this._overlay) {
      setTimeout(() => {
        this.removeIsolation()
        this.isolateImage()
      })
    }
  }

  // @ts-ignore
  __editingControls() {
    const controls = Object.values(ControlPositions);
    const controlDict = controls.reduce((acc, control) => {
      acc[control] = this.__createEditingControl(control); // Use the control value as the key
      return acc;
    }, {});

    let sourceControls;
    if (this.flipX && this.flipY) {
      sourceControls = ControlsCrop.flipXYCropControls
    }
    else if (this.flipX) {
      sourceControls = ControlsCrop.flipXCropControls
    }
    else if (this.flipY) {
      sourceControls = ControlsCrop.flipYCropControls
    }
    else {
      sourceControls = ControlsCrop.croppingControlSet
    }

    return {
      ...controlDict,
      ...sourceControls,
    };
  }

  __createEditingControl(position: ControlPositions) {
    // Flip the position dynamically based on flipX and flipY
    let flippedPosition = position;
  
    if (this.flipX) {
      flippedPosition = flippedPosition.replace(/l|r/g, (m) => (m === 'l' ? 'r' : 'l'));
    }
  
    if (this.flipY) {
      flippedPosition = flippedPosition.replace(/t|b/g, (m) => (m === 't' ? 'b' : 't'));
    }
  
    // Determine the cursor style based on the flipped position
    const cursor = flippedPosition
      .replace('t', 's')
      .replace('l', 'e')
      .replace('b', 'n')
      .replace('r', 'w');
  
    // Create and return the control
    return new fabric.Control({
      cursorStyle: `${cursor}-resize`,
      actionName: `edit_${this.type}`,
      render: controlPositionIcons[flippedPosition],
      positionHandler: this.__editingControlPositionHandler.bind(this, position),
      actionHandler: this.__editingActionHandlerWrapper(flippedPosition)
    })
  }

  __editingActionHandlerWrapper(position: ControlPositions) {
    return (_event: MouseEvent, _transform: any, x: number, y: number) => {
      this.drawGrid()
      this.__editingSetCropResize(position, x, y)
      return true
    }
  }

  // @ts-ignore
  __editingOnMoving(event: fabric.IEvent) {
    // Reset move of active object
    this.left = this.originalLeft
    this.top = this.originalTop
    this.drawGrid()
    if (!this.startPointer) {
      this.startPointer = { x: event.pointer.x, y: event.pointer.y };
    }

    if (event.pointer) {
      const deltaX = event.pointer.x - this.startPointer.x
      const deltaY = event.pointer.y - this.startPointer.y

      // Save pointer position for next frame
      this.startPointer = { x: event.pointer.x, y: event.pointer.y };

      if (this._editingMode && event.pointer) {
          this.__editingMoveCrop(ControlPositions.TOP_LEFT, deltaX, deltaY)
      }
    }
  }
  
  __editingMoveCrop(
    position: ControlPositions,
    deltaX: number,
    deltaY: number
  ) {
    if (this.__editingImage) {
      // Convert top and left into unrotated space
      const rotationAnchor = new fabric.Point(this.left, this.top)
      const topLeftEditingImage = new fabric.Point(this.__editingImage.left + deltaX, this.__editingImage.top + deltaY)
      const topLeftEditingImageWithoutDelta = new fabric.Point(this.__editingImage.left, this.__editingImage.top)

      const unrotatedTopLeft = fabric.util.rotatePoint(topLeftEditingImage, rotationAnchor, -fabric.util.degreesToRadians(this.angle))
      const unrotatedTopLeftWithoutDelta = fabric.util.rotatePoint(topLeftEditingImageWithoutDelta, rotationAnchor, -fabric.util.degreesToRadians(this.angle))

      // Perform cropping calculations in unrotated space
      let unrotatedTop = unrotatedTopLeft.y
      let minUnrotatedTop = this.top + ((this.height - this.__editingImage.height) * this.__editingImage.scaleY)
      let maxUnrotatedTop = this.top
      unrotatedTop = Math.max(unrotatedTop, minUnrotatedTop)
      unrotatedTop = Math.min(unrotatedTop, maxUnrotatedTop)

      let unrotatedLeft = unrotatedTopLeft.x
      const minUnrotatedLeft = this.left + ((this.width - this.__editingImage.width) * this.__editingImage.scaleX)
      const maxUnrotatedLeft = this.left
      unrotatedLeft = Math.max(unrotatedLeft, minUnrotatedLeft)
      unrotatedLeft = Math.min(unrotatedLeft, maxUnrotatedLeft)

      // Update cropY in unrotated space
      let unrotatedCropY = 0
      if (this.flipY) {
        unrotatedCropY = ((unrotatedTopLeftWithoutDelta.y/this.scaleY) + this.__editingImage.height - ((this.top/this.scaleY) + this.height))
      }
      else {
        unrotatedCropY = Math.abs(unrotatedTop - this.top) / this.__editingImage.scaleY
      }

      let unrotatedCropX = 0
      if (this.flipX) {
        unrotatedCropX = ((unrotatedTopLeftWithoutDelta.x/this.scaleX) + this.__editingImage.width - ((this.left/this.scaleX) + this.width))
      }
      else {
        unrotatedCropX = Math.abs(unrotatedLeft - this.left) / this.__editingImage.scaleX
      }
      

      // Convert back to rotated space
      const rotatedTopLeft = fabric.util.rotatePoint(
        new fabric.Point(unrotatedLeft, unrotatedTop),
        rotationAnchor,
        fabric.util.degreesToRadians(this.angle)
      );

      // Update crop values
      if (position.includes('t')) {
        this.__editingImage.top = rotatedTopLeft.y
        this.cropY = unrotatedCropY
      }
      if (position.includes('l')) {
        this.__editingImage.left = rotatedTopLeft.x
        this.cropX = unrotatedCropX
      }
    }
  }

  __constrainTop(unrotatedEditingImageTopLeft: Point, unrotatedXY: Point) {
    const { height = 0, scaleY = 1 } = this.__editingImage
    const top = unrotatedEditingImageTopLeft.y
    let maxTop = top + height * scaleY
    let minTop = Math.min(unrotatedXY.y, maxTop, this.top! + this.getScaledHeight())
    this.top = Math.max(minTop, top)
  }

  __constrainLeft(unrotatedEditingImageTopLeft: Point, unrotatedXY: Point) {
    const { width = 0, scaleX = 1 } = this.__editingImage
    const left = unrotatedEditingImageTopLeft.x

    let maxLeft = left + width * scaleX
    let minLeft = Math.min(unrotatedXY.x, maxLeft, this.left! + this.getScaledWidth())
    this.left = Math.max(minLeft, left)
  }

  __editingSetCropResize(
    position: ControlPositions,
    x: number,
    y: number
  ) {
    if (!this.__editingImage) {
      return
    }
    const { width = 0, height = 0, scaleX = 1, scaleY = 1 } = this.__editingImage
    const rotationAnchor = new fabric.Point(this.left, this.top)
  
    // Calculate unrotated points
    const unrotatedXY = this.__getUnrotatedPoint(x, y, rotationAnchor)
    const unrotatedEditingImageTopLeft = this.__getUnrotatedPoint(
      this.__editingImage.left,
      this.__editingImage.top,
      rotationAnchor
    )
    const top = unrotatedEditingImageTopLeft.y
    const left = unrotatedEditingImageTopLeft.x
  
    if (position.includes('t')) {
      this.__handleTopEdge(position, unrotatedXY, top, height, scaleY, unrotatedEditingImageTopLeft)
      this.__rotateAndApplyTopLeft(rotationAnchor)
    }
  
    if (position.includes('b')) {
      this.__handleBottomEdge(unrotatedXY, top, height, scaleY)
    }
  
    if (position.includes('l')) {
      this.__handleLeftEdge(position, unrotatedXY, left, width, scaleX, unrotatedEditingImageTopLeft)
      this.__rotateAndApplyTopLeft(rotationAnchor)
    }
  
    if (position.includes('r')) {
      this.__handleRightEdge(unrotatedXY, left, width, scaleX)
    }

    if (this && this.aspectRatio) {
      let newHeight = this.height
      let newWidth = this.width
  
      if (position == "br") {
        // Determine new dimensions
        if (this.width / this.height > this.aspectRatio) {
          // Width is too large, fit to height
          // newHeight = Math.min(this.height, this.__editingImage.height - this.cropY);
          newWidth = newHeight * this.aspectRatio;
          newWidth = Math.min(newWidth, this.__editingImage.width - this.cropX);
          console.log("width is larger")
        } else {
          // Height is too large, fit to width
          // newWidth = Math.min(this.width, this.__editingImage.width - this.cropX);
          newHeight = newWidth / this.aspectRatio;
          newHeight = Math.min(newHeight, this.__editingImage.height - this.cropY);
          console.log("height is larger")
        }
      }
      if (position == "tr") {
          // newHeight = Math.min(this.height, this.__editingImage.height - this.cropY);
          newWidth = newHeight * this.aspectRatio;
          newWidth = Math.min(newWidth, this.__editingImage.width - this.cropX);
          console.log("width is larger")
      }
      if (position == "bl") {
        // newWidth = Math.min(this.width, this.__editingImage.width - this.cropX);
        newHeight = newWidth / this.aspectRatio;
        newHeight = Math.min(newHeight, this.__editingImage.height - this.cropY);
      }
      if (position === "l") {
          newHeight = newWidth / this.aspectRatio;
          newHeight = Math.min(newHeight, this.__editingImage.height - this.cropY);
      }
      if (position === "t") {
        newWidth = newHeight * this.aspectRatio;
        if (newWidth > this.__editingImage.width - this.cropX) {
          newWidth = this.__editingImage.width - this.cropX
          newHeight = newWidth / this.aspectRatio
        }

      }
      if (position == "tl") {
        if (this.width / this.height > this.aspectRatio) {
          newHeight = newWidth / this.aspectRatio;
          newHeight = Math.min(newHeight, this.__editingImage.height - this.cropY);
          console.log("TL: wider")
        }
        else {
          newWidth = newHeight * this.aspectRatio;
          newWidth = Math.min(newWidth, this.__editingImage.width - this.cropX);
          console.log("TL: higher")
        }

      }

    
      this.width = newWidth// + ((this.width - newWidth)/2)
      this.height = newHeight// + ((this.height - newHeight)/2)
      
    }

  
    this.originalLeft = this.left
    this.originalTop = this.top
  }
  
  private __getUnrotatedPoint(x: number, y: number, anchor: fabric.Point) {
    return fabric.util.rotatePoint(
      new fabric.Point(x, y),
      anchor,
      -fabric.util.degreesToRadians(this.angle)
    )
  }
  
  private __rotateAndApplyTopLeft(rotationAnchor: fabric.Point) {
    const rotatedTopLeft = fabric.util.rotatePoint(
      new fabric.Point(this.left, this.top),
      rotationAnchor,
      fabric.util.degreesToRadians(this.angle)
    )
    this.top = rotatedTopLeft.y
    this.left = rotatedTopLeft.x
  }
  
  private __handleTopEdge(
    position: ControlPositions,
    unrotatedXY: fabric.Point,
    top: number,
    height: number,
    scaleY: number,
    unrotatedEditingImageTopLeft: fabric.Point
  ) {
    if (this.flipY) {
      this.height = (height - this.cropY - ((unrotatedXY.y - top)/scaleY))
      this.height = this.__clampValue(this.height, 0, height - this.cropY)
      this.top = top + (height - this.height - this.cropY) * scaleY
    } else {
      this.__constrainTop(unrotatedEditingImageTopLeft, unrotatedXY)
      const cropY = this.__calculateCropY(unrotatedXY, top, height, scaleY)
      this.__applyTopCrop(cropY, height)
    }
  }
  
  private __handleBottomEdge(
    unrotatedXY: fabric.Point,
    top: number,
    height: number,
    scaleY: number
  ) {
    if (this.flipY) {
      let top_area = (this.top - top) / scaleY
      let bottom_area = Math.max(0, (top/scaleY) + height - (unrotatedXY.y/scaleY))
      this.height = height - top_area - bottom_area      
      this.cropY = Math.max(0, (bottom_area))
      this.height = this.__clampValue(this.height, 0, Math.max(0, height - top_area - this.cropY))
    } else {
      const minHeight = Math.min(
        (unrotatedXY.y - top) / scaleY - this.cropY!,
        height - this.cropY!
      )
      this.height = Math.max(0, minHeight)
    }
  }
  
  private __handleLeftEdge(
    position: ControlPositions,
    unrotatedXY: fabric.Point,
    left: number,
    width: number,
    scaleX: number,
    unrotatedEditingImageTopLeft: fabric.Point
  ) {
    if (this.flipX) {
      this.width = (((width - this.cropX)) - ((unrotatedXY.x - left)/scaleX))
      this.width = this.__clampValue(this.width, 0, width - this.cropX)
      this.left = left + (width - this.width - this.cropX) * scaleX

    } else {
      this.__constrainLeft(unrotatedEditingImageTopLeft, unrotatedXY)
      const cropX = Math.min((Math.min(Math.max(unrotatedXY.x, left), this.left) - left) / scaleX, width)
      this.width = Math.max(0, Math.min(this.width! + (this.cropX! - cropX), width))
      this.cropX = cropX
      if (position.includes('t')) {
        this.__constrainTop(unrotatedEditingImageTopLeft, unrotatedXY)
      }
    }
  }
  
  private __handleRightEdge(
    unrotatedXY: fabric.Point,
    left: number,
    width: number,
    scaleX: number
  ) {
    if (this.flipX) {
      let leading_area = (this.left - left) / scaleX
      let trailing_area = Math.max(0, (left/scaleX) + width - (unrotatedXY.x/scaleX))
      this.width = width - leading_area - trailing_area
      this.cropX = Math.max(0, (trailing_area))
      this.width = this.__clampValue(this.width, 0, Math.max(0, width - leading_area - this.cropX))
    } else {
      const minWidth = Math.min(
        (unrotatedXY.x - left) / scaleX - this.cropX!,
        width - this.cropX!
      )
      this.width = Math.max(0, minWidth)
    }
  }
  
  private __clampValue(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max)
  }
  
  private __calculateCropY(
    unrotatedXY: fabric.Point,
    top: number,
    height: number,
    scaleY: number
  ): number {
    const minCrop = (Math.min(Math.max(unrotatedXY.y, top), this.top) - top) / scaleY
    return Math.min(minCrop, height)
  }
  
  private __applyTopCrop(cropY: number, height: number) {
    const minHeight = Math.min(this.height! + (this.cropY! - cropY), height)
    this.height = Math.max(0, minHeight)
    this.cropY = cropY
  }
  
  private __calculateCropX(
    unrotatedXY: fabric.Point,
    left: number,
    width: number,
    scaleX: number
  ): number {
    return Math.min(
      (Math.min(Math.max(unrotatedXY.x, left), this.left) - left) / scaleX,
      width
    )
  }
  
  private __applyLeftCrop(cropX: number, width: number) {
    this.width = Math.max(0, Math.min(this.width! + (this.cropX! - cropX), width))
    this.cropX = cropX
  }

  __editingControlPositionHandler(this: fabric.Image, position: ControlPositions) {
    const xMultiplier = position.includes('l') ? -1 : position.length > 1 || position === 'r' ? 1 : 0
    const yMultiplier = position.includes('t') ? -1 : position.length > 1 || position === 'b' ? 1 : 0
    const x = (this.width! / 2) * xMultiplier
    const y = (this.height! / 2) * yMultiplier

    return fabric.util.transformPoint(
      new fabric.Point(x, y),
      fabric.util.multiplyTransformMatrices(this.canvas!.viewportTransform!, this.calcTransformMatrix())
    )
  }

  __renderEditingControl(
    position: ControlPositions,
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number
  ) {
    ctx.save()
    ctx.strokeStyle = this.cornerStrokeColorEditing
    ctx.lineWidth = this.cornerSizeEditing
    ctx.translate(left, top)
    if (this.angle) {
      ctx.rotate(fabric.util.degreesToRadians(this.angle))
    }
    ctx.beginPath()
    const x = position.includes('l') ? -ctx.lineWidth : position.includes('r') ? ctx.lineWidth : 0
    const y = position.includes('t') ? -ctx.lineWidth : position.includes('b') ? ctx.lineWidth : 0
    if (position === 'b' || position === 't') {
      ctx.moveTo(x - this.cornerLengthEditing / 2, y)
      ctx.lineTo(x + this.cornerLengthEditing, y)
    } else if (position === 'r' || position === 'l') {
      ctx.moveTo(x, y - this.cornerLengthEditing / 2)
      ctx.lineTo(x, y + this.cornerLengthEditing)
    } else {
      if (position.includes('b')) {
        ctx.moveTo(x, y - this.cornerLengthEditing)
      } else if (position.includes('t')) {
        ctx.moveTo(x, y + this.cornerLengthEditing)
      }
      ctx.lineTo(x, y)
      if (position.includes('r')) {
        ctx.lineTo(x - this.cornerLengthEditing, y)
      } else if (position.includes('l')) {
        ctx.lineTo(x + this.cornerLengthEditing, y)
      }
    }
    ctx.stroke()
    ctx.restore()
  }

  public setAspectRatio(aspectRatio: number | null) {
    console.log("setAspectRatio", aspectRatio)
    this.aspectRatio = aspectRatio;  // Set aspect ratio: 16/9, 9/16, 1, or null (freeform)
    
    if (this && this.aspectRatio) {
      // Original image aspect ratio
      const originalAspectRatio = this.__editingImage.width / this.__editingImage.height;
  
      let newWidth = this.width
      let newHeight = this.height
      // Determine new dimensions
      if (this.width / this.height > this.aspectRatio) {
          // Width is too large, fit to height
          newHeight = Math.min(this.height, this.__editingImage.height);
          newWidth = newHeight * this.aspectRatio;
      } else {
          // Height is too large, fit to width
          newWidth = Math.min(this.width, this.__editingImage.width);
          newHeight = newWidth / this.aspectRatio;
      }
      this.width = newWidth
      this.height = newHeight
    }

    // After setting the new aspect ratio, you might want to trigger a re-render of the object
    this.canvas?.renderAll();  // Update the canvas to reflect the changes
  }
  

  __updateBoundingBox() {
    this.boundingBox = new Rectangle(
      this.cropX / this.__editingImage.width,
      this.cropY / this.__editingImage.height,
      this.width / this.__editingImage.width,
      this.height / this.__editingImage.height,
    )
  }

  //@ts-ignore
  initialize(element, options) {
    // @ts-ignore
    this.registerEditingEvents()
    this._inset = new Inset(0, 0, 0, 0)
    this.boundingBox = options.boundingBox ?? new Rectangle(0, 0, 1, 1)
    
    if (options._originalScaleX && options._originalScaleY) {
      options.scaleX = options._originalScaleX / (options._filterScalingX ? options._filterScalingX : 1)
      options.scaleY = options._originalScaleY / (options._filterScalingY ? options._filterScalingY : 1)
    } else {
      options._originalScaleX = options.scaleX
      options._originalScaleY = options.scaleY
    }
    
    super.initialize(element, options)
    
    let that = this
    this.on('scaling', function (e) {
      that._originalScaleX = that.scaleX * that._filterScalingX
      that._originalScaleY = that.scaleY * that._filterScalingY
    })

    return this
  }  

  static fromObject(options: any, callback: Function) {
    fabric.util.loadImage(
      options.src,
      async function (img) {
        let state = store.getState()
        options.filters = await CanvasImageRenderer.getInstance().getFilters(options, state.editor?.imageElements?.imageElements);
        let staticImage = new fabric.StaticImage(img, options)

        return callback && callback(staticImage)
      },
      null,
      // @ts-ignore
      { crossOrigin: 'anonymous' }
    )
  }

  toObject(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude)
  }

  toJSON(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude)
  }

  applyFilters(inputFilters?, filterBackend?) {
    if (!this.dirty || this.isLatest) {
      return this;
    }
    let filters = inputFilters || this.filters || []
    this.filters = filters;
    
    let imgElement = this._originalElement
    let inputSize = new Size(imgElement.width, imgElement.height)
    
    let bb = new Rectangle(0, 0, 1, 1)
    let roi = bb.multiply(inputSize);
    // let roi = this.boundingBox.multiply(inputSize);

    roi.round();
    

    let paddedSize = filters.reduce(
      (total, filter) => (filter.padSize ? filter.padSize(total) : total),
      roi.size()
    )
    let scaleX = roi.width / paddedSize.width
    let scaleY = roi.height / paddedSize.height

    let inputInset = new Inset(0, 0, 0, 0)
    let inset = filters.reduce(
      (total, filter) => (filter.applyInset ? filter.applyInset(total, inputSize) : total),
      inputInset
    )

    filters = filters.filter(function (filter) {
      return filter && !filter.isNeutralState()
    })

    let backend = filterBackend ?? fabric.filterBackend;
    this._element = backend.applyFiltersWithBoundingBox(
      filters,
      this._originalElement,
      roi.size(),
      inset,
      scaleX,
      scaleY,
      this.getTextureKey(),
      bb // this.boundingBox
    )

    this.filters = filters;
    this._filterScalingX = scaleX
    this._filterScalingY = scaleY
    this._inset = inset

    this.scaleX = this._originalScaleX / this._filterScalingX
    this.scaleY = this._originalScaleY / this._filterScalingY


    return this
  }
  
  _calculateCurrentDimensions() {
    let wh = super._calculateCurrentDimensions()
    wh.x *= this._filterScalingX
    wh.y *= this._filterScalingY
    return wh
  }

  toLocalPoint(point, originX, originY) {
    point.x /= this._filterScalingX
    point.y /= this._filterScalingY

    var center = this.getCenterPoint(),
      p,
      p2

    center.x /= this._filterScalingX
    center.y /= this._filterScalingY

    if (typeof originX !== 'undefined' && typeof originY !== 'undefined') {
      p = this.translateToGivenOrigin(center, 'center', 'center', originX, originY)
    }
    else {
      p = new fabric.Point(this.left, this.top)
    }

    p2 = new fabric.Point(point.x, point.y)
    if (this.angle) {
      p2 = fabric.util.rotatePoint(p2, center, -fabric.util.degreesToRadians(this.angle))
    }
    return p2.subtractEquals(p)
  }

  _calcYOffset(): number {
    return this._inset.top - this._inset.bottom
  }

  _calcXOffset(): number {
    return this._inset.left - this._inset.right
  }

  _getCacheCanvasDimensions() {
    let dims = super._getCacheCanvasDimensions()
    if(!this._originalElement) { return dims }
    let insetOffsetX =
      (this._filterScalingX * Math.abs(this._calcXOffset()) * dims.width) / this._originalElement.width
    let insetOffsetY =
      (this._filterScalingY * Math.abs(this._calcYOffset()) * dims.height) / this._originalElement.height
    dims.width += insetOffsetX
    dims.height += insetOffsetY
    return dims
  }

  _renderFill(ctx) {
    var elementToDraw = this._element
    if (!elementToDraw) {
      return
    }

    let insetOffsetX = this.isLatest || this._editingMode ? 0 : this._calcXOffset() * this._filterScalingX
    let insetOffsetY = this.isLatest || this._editingMode ? 0 : this._calcYOffset() * this._filterScalingY

    var min = Math.min,
        max = Math.max;

    var scaleX = this._filterScalingX,
      scaleY = this._filterScalingY,
      w = this.width,
      h = this.height,
      // crop values cannot be lesser than 0.
      cropX = (this._editingMode || this.filters.length == 0) ? max(this.cropX, 0) : 0,
      cropY = (this._editingMode || this.filters.length == 0) ? max(this.cropY, 0) : 0,
      elWidth = elementToDraw.naturalWidth || elementToDraw.width,
      elHeight = elementToDraw.naturalHeight || elementToDraw.height,
      sX = cropX * scaleX,
      sY = cropY * scaleY,
      // the width height cannot exceed element width/height, starting from the crop offset.
      sW = min(w * 1.0, elWidth - sX),
      sH = min(h * 1.0, elHeight - sY),
      x = -(w + insetOffsetX) / 2,
      y = -(h + insetOffsetY) / 2,
      maxDestW = min(w, elWidth / scaleX - cropX),
      maxDestH = min(h, elHeight / scaleY - cropY)

    elementToDraw && ctx.drawImage(elementToDraw, sX, sY, sW, sH, x, y, maxDestW, maxDestH)
  }

  drawGrid() {
    const { left, top, width, height, scaleX, scaleY } = this
    const gridColor = 'rgba(255, 255, 255, 1)'
    const gridWidth = 1
    // Clear previous grid lines if any exist
    this.removeGrid()
  
    const scaledWidth = width * scaleX
    const scaledHeight = height * scaleY
  
    this.gridLines = []
  
    // Draw vertical grid lines
    for (let i = 1; i < 3; i++) {
      const x = left + (i * scaledWidth) / 3
      const verticalLine = new fabric.Line([x, top, x, top + scaledHeight], {
        stroke: gridColor,
        strokeWidth: gridWidth / this.canvas.getZoom(),
        selectable: false,
        evented: false,
      })
      this.gridLines.push(verticalLine)
      this.canvas.add(verticalLine)
      verticalLine.bringToFront()
    }
  
    // Draw horizontal grid lines
    for (let i = 1; i < 3; i++) {
      const y = top + (i * scaledHeight) / 3;
      const horizontalLine = new fabric.Line([left, y, left + scaledWidth, y], {
        stroke: gridColor,
        strokeWidth: gridWidth / this.canvas.getZoom(),
        selectable: false,
        evented: false,
      });
      this.gridLines.push(horizontalLine)
      this.canvas.add(horizontalLine)
      horizontalLine.bringToFront()
    }
  }

  removeGrid() {
    if (this.gridLines) {
      // Remove all grid lines from the canvas
      this.gridLines.forEach((line) => this.canvas.remove(line));
  
      // Clear the gridLines array
      this.gridLines = [];
    }
  }
}

fabric.StaticImage = fabric.util.createClass(StaticImageObject, {
  type: StaticImageObject.type,
  perPixelTargetFind: true,
  useBzrtBgMask: false,
  isLatest: false,
  cacheCounter: 0
})
fabric.StaticImage.fromObject = StaticImageObject.fromObject

export interface StaticImageOptions extends fabric.IImageOptions {
  id: string
  name?: string
  description?: string
  subtype: string
  src: string
  useBzrtBgMask: boolean,
  isLatest: boolean,
  cacheCounter: number
}

declare module 'fabric' {
  namespace fabric {
    class StaticImage extends StaticImageObject {
      registerEditingEvents: () => void
      constructor(element: any, options: any)
    }

    interface IUtil {
      isTouchEvent(event: Event): boolean
      getPointer(event: Event, a?: any): Point
    }
    interface Image {
      useNewTextureNextTime: () => void
      toggleEditingMode(): () => void
      enterEditingMode(): void
      isIntialized: () => void
      exitEditingMode(cb?: ()=>void): void
      isolateImage: (canvas: fabric.Canvas) => void;
      removeIsolation: (canvas: fabric.Canvas) => void;    
      _editingMode: boolean
      __editingImage: Image | null
      __editingOnMoving: (event: IEvent) => void
      isLatest: boolean
      hasTransparency: boolean
      cornerLengthEditing: number
      cornerSizeEditing: number
      cornerStrokeColorEditing: string
      __editingControls(): { [key: string]: Control }
      __editingControlPositionHandler(position: ControlPositions): Point
      __editingSetCrop(position: ControlPositions, x: number, y: number, resize?: boolean): void
      __createEditingControl(position: ControlPositions): Control
      __editingActionHandlerWrapper(position: ControlPositions): Control['actionHandler']
      _overlay?: fabric.Rect
    }
  }
}
