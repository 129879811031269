import { styled, useStyletron } from 'baseui'
import { lightTheme } from '@/customTheme'
import { useEffect, useState } from 'react'
import {
  formatSizesBasic,
  formatSizesSocicalMedia,
  formatECommerce,
  formatBusiness,
  formatPrint,
  formatStandard,
} from '@/constants/format-sizes'
import { useEditorContext } from '@/scenes/engine'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import Loading from '@/components/Loading'
import * as React from 'react'
import isEmpty from 'lodash/isEmpty'
import Icons from '../../Icons'
import { selectUserIsPremium } from '@/store/slices/user/selectors'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store/store'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'
import { KIND, SHAPE } from 'baseui/button'
import { selectCallbackFromCreationPage } from '@/store/slices/editor/selector'
import { ActionType } from '@/store/slices/editor/reducer'
import { setCallbackFromCreationPage } from '@/store/slices/editor/action'
import ModalCustomSize, { convertUnitValue } from '@/components/ShowModal/ModalCustomSize'
import { InspectorPanelType, useInspector } from '../../Toolbox/InspectorDataProvider'
import { DotLottiePlayer } from '@dotlottie/react-player'
// @ts-ignore
import ArrowAnimation from '@assets/lottie/arrow_animation.lottie'
import LottiePlayerWrapper from '@/libs/dotLottiePlayer/LottiePlayerWrapper'

export const LIST_RESIZE = [
  { Basic: formatSizesBasic },
  { 'Social media': formatSizesSocicalMedia },
  { 'E-commerce': formatECommerce },
  { Business: formatBusiness },
  { Print: formatPrint },
  { Standard: formatStandard },
]

export function findSizeId(width, height, returnIcon = false){
  let sizeId = null
  let icon = null
  LIST_RESIZE.forEach(groupItem => {
    let key = Object.keys(groupItem)[0]
    if (sizeId === null) {
      groupItem[key].forEach(sizeItem => {
        if (sizeItem.size.width === width && sizeItem.size.height === height && !sizeId) {
          sizeId = (`${sizeItem.id}_${sizeItem.name}`)
          icon = sizeItem.icon
        }
      })
    }
  })
  if(returnIcon) {
    return {sizeId, icon}
  }
  return sizeId;
}

export default function Resize({ resizeActiveItem, setResizeActiveItem }: { resizeActiveItem: Object, setResizeActiveItem: Function }) {
  const [uiValue, setUiValue] = useState({ width: 0, height: 0 })
  
  const [aspectRatio, setAspectRatio] = useState(1)
  
  const [isOpenCustomSize, setIsOpenCustomSize] = useState(false)
  const [sectionActive, setSectionActive] = useState(null)

  const [sizeId, setSizeId] = useState('')
  
  const [unit, setUnit] = useState('px')
  
  const [sizeFromTemplate, setSizeFromTemplate] = useState('')
  const { t } = useTranslation()

  const hasPremium = useSelector(selectUserIsPremium)
  const dispatch = useAppDispatch()

  const { editor, canvas } = useEditorContext()
  const { addInspector, hideInspector } = useInspector();

  const [css] = useStyletron()
  const updateFormatSize = value => {
    // setValue(value)
    // resize
    editor.handlers.frameHandler.update({ ...value })
    const frame = editor.handlers.frameHandler.get()
    const hasTransparentBg = frame.fill && !(typeof frame.fill  === 'string')
    hasTransparentBg && editor.handlers.frameHandler.setTransparentBg(false)

    const eventProperties = {
      Tool: 'bazaart.canvas_size',
    }
    customAmplitude('Selected tool', eventProperties)
  }
  const applyCustomSize = ({width, height}) => {
    const { width: widthUi, height: heightUi } = convertUnitValue('px', unit, {width, height})
    setSizeId('custom-size')
    setResizeActiveItem({
      name: 'Custom size',
      icon: Icons.CustomSize,
      description: `${unit === 'px' ? width : widthUi.toFixed(2)} x ${unit === 'px' ? height : heightUi.toFixed(2)}`,
      size: {
        width: width,
        height: height,
      },
      unit: unit
    })
    const frame = editor.handlers.frameHandler.get()
    const hasTransparentBg = frame.fill && !(typeof frame.fill  === 'string')
    editor.handlers.frameHandler.update({width: width, height: height, sizeId: 'custom-size', icon: Icons.CustomSize, unit: unit })
    hasTransparentBg && editor.handlers.frameHandler.setTransparentBg(false)
    const eventProperties = {
      Tool: 'bazaart.canvas_size',
    }
    customAmplitude('Selected tool', eventProperties)
  }
  const handleSelect = (size, nameSection, sizeId, item) => {
    setResizeActiveItem({
      ...item,
      description: `${size.width} x ${size.height}`,
      unit: 'px'
    })
    setSizeId(sizeId)
    setSectionActive(nameSection)
    if (!isEmpty(size)) {
      updateFormatSize({ ...size, sizeId, icon: item.icon, unit: 'px' })
      setUnit('px')
    } else {
      setIsOpenCustomSize(true)
    }
  }

  const updateSizeIdFromFrame = (isUpdate = true) => {
    let frame = editor.handlers.frameHandler.get()

    // @ts-ignore
    let frame_sizeId = frame.sizeId
    const width = frame.width
    const height = frame.height
    // @ts-ignore
    const { width: widthUi, height: heightUi } = convertUnitValue('px', frame.unit, {width: width, height: height})
    setUiValue({ width: widthUi, height: heightUi })
    // @ts-ignore
    setUnit(frame.unit ? frame.unit : 'px')
    setAspectRatio(frame.width / frame.height)
    if (frame_sizeId && isUpdate) {
      setSizeId(frame_sizeId)
      if(!resizeActiveItem) {
        let name =  frame_sizeId !== 'custom-size' ? frame_sizeId.split('_')[1] : 'Custom size'
        setResizeActiveItem({
          name: name,
          description: `${widthUi} x ${heightUi}`,
          size: {
            width: width,
            height: height,
          },
          // @ts-ignore
          icon: frame.icon,
          // @ts-ignore
          unit: frame.unit,
        })
      }
    }
  }

  useEffect(() => {
    if (canvas) {
      canvas.on('mouse:up', e => {
        hideInspector(InspectorPanelType.Resize)
      })
    }
  }, [canvas])

  useEffect(() => {
    editor.handlers.frameHandler.setOriginalObjectsState()
  }, [])

  useEffect(() => {
    let isMounted = true
    if (editor) {
      const safeUpdateSizeIdFromFrame = () => {
        if (isMounted) {
          updateSizeIdFromFrame();
        }
      }
      safeUpdateSizeIdFromFrame()

      if (isMounted) {
        setAspectRatio(editor.handlers.frameHandler.get().width / editor.handlers.frameHandler.get().height)
      }
      editor.on('history:changed', e => {
        if (e.afterUndo) {
          safeUpdateSizeIdFromFrame()
        }
      })
      editor.on('frame:changed', e => {
        safeUpdateSizeIdFromFrame()
      })
      return () => {
        isMounted = false
        editor.off('history:changed', () => {})
        editor.off('frame:changed', () => {})
      }
    }
    return () => {
      isMounted = false;
    }
  }, [editor])

  return (
    <>
      <PackItem
        onClick={e => {
          if (!hasPremium) {
            const eventProperties = {
              Source: 'BtSubscriptionCanvasSize',
              Type: 'Standard',
            }
            customAmplitude('Premium Prompt', eventProperties)
            dispatch(
              setOpenModalTryPremium({
                isOpen: true,
                source: 'BtSubscriptionCanvasSize',
                callback: () => {
                  setIsOpenCustomSize(true)
                },
              })
            )
            return
          } else {
            setIsOpenCustomSize(true)
          }
          customAmplitude('Selected tool', {
            Tool: 'bazaart.canvas_size',
          })
        }}
        $active={sizeId === 'custom-size'}
        className='mb-50'
      >
        <>
          <div
            style={{
              padding: '8px',
              width: '66px',
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: `50px`,
                width: `50px`,
                background: lightTheme.colors.grayScale100,
                borderRadius: '4px',
              }}
            >
              <Icons.CustomSize />
            </div>
          </div>
          <span
            style={{
              position: 'absolute',
              left: '66px',
              top: '8px',
              bottom: '9px',
              borderRight: '1px solid rgba(229, 229, 229, 1)',
            }}
          ></span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '16px 0 16px 8px',
              gap: '2px',
            }}
          >
            <p style={{...lightTheme.typography.Small11medium, color: sizeId === 'custom-size' ? '#FF0560' : lightTheme.colors.contentPrimary}}>{t('Custom size')}</p>
            {sizeId === 'custom-size' ? <p style={{...lightTheme.typography.Small11medium, color: lightTheme.colors.contentPrimary}}>{uiValue.width} x {uiValue.height} {t(unit)}</p> : null}
          </div>
          {!hasPremium ? (
            <div style={{ position: 'absolute', top: '4px', right: '4px', zIndex: 1 }}>
              <Icons.PremiumIcon />
            </div>
          ) : null}
          {sizeId === 'custom-size' ? (
            <div
              style={{
                position: 'absolute',
                right: '8px',
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ButtonCustom
                shape={SHAPE.square}
                kind={KIND.minimal}
                type={SizeButton.SMALL}
                onClick={() => {
                  if (!hasPremium) {
                    const eventProperties = {
                      Source: 'BtSubscriptionCanvasSize',
                      Type: 'Standard',
                    }
                    customAmplitude('Premium Prompt', eventProperties)
                    dispatch(
                      setOpenModalTryPremium({
                        isOpen: true,
                        source: 'BtSubscriptionCanvasSize',
                        callback: () => {
                          setIsOpenCustomSize(true)
                        },
                      })
                    )
                    return
                  } else {
                    setIsOpenCustomSize(true)
                  }
                }}
              >
                <Icons.Adjust size={24} />
              </ButtonCustom>
            </div>
          ) : null}
        </>
        </PackItem>
        {LIST_RESIZE.map((item, index) => {
          return (
            <Section
              key={index}
              name={Object.keys(item)[0]}
              listItem={item[Object.keys(item)[0]]}
              handleSelect={handleSelect}
              sizeIdActive={sizeId}
              setSizeIdActive={setSizeId}
              sizeFromTemplate={sizeFromTemplate}
              setSizeFromTemplate={setSizeFromTemplate}
              // style={index === 0 ? { marginTop: 0 } : {}}
            />
          )
        })}
        <div>
          {/*  */}
          <ModalCustomSize 
            isOpen={isOpenCustomSize}
            onClose={() => setIsOpenCustomSize(false)}
            customSize={uiValue}
            setCustomSize={setUiValue}
            applyCustomSize={applyCustomSize}
            aspectRatio={aspectRatio}
            setAspectRatio={setAspectRatio}
            unit={unit}
            setUnit={setUnit}
          />
      </div>
    </>
  )
}

function Section({
  name,
  listItem,
  handleSelect,
  sizeIdActive,
  setSizeIdActive,
  sizeFromTemplate,
  setSizeFromTemplate,
  style,
}: {
  name: string
  listItem: {
    id: number
    name: string
    description?: string
    size?: { width: number; height: number }
    icon?: (props: any) => JSX.Element
  }[]
  handleSelect: Function
  sizeIdActive: string
  setSizeIdActive: Function
  sizeFromTemplate: string
  setSizeFromTemplate: Function
  style?: React.CSSProperties
}) {
  const [indexActive, setIndexActive] = useState(null)
  const [maxItem, setMaxItem] = useState(4)
  const [showToggleBtn, setShowToggleBtn] = useState(false)

  const [css, theme] = useStyletron()
  const dispatch = useAppDispatch()
  const hasPremium = useSelector(selectUserIsPremium)

  const { t } = useTranslation()

  return (
    <div style={{ ...style }} className={`section ${listItem.length > 4 ? '' : 'mb-50'}`} onMouseEnter={() => setShowToggleBtn(true)} onMouseLeave={() => setShowToggleBtn(false)}>
      {name && (
        <div
          style={{
            height: '26px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '8px',
          }}
        >
          <span
            className={css({
              ...lightTheme.typography.Small14Semibold,
              color:lightTheme.colors.text.text_black_title,
              textTransform: 'lowercase',
              '::first-letter': {
                textTransform: 'capitalize',
              },
            })}
          >
            {t(name)}
          </span>
          {listItem.length <= 4 ? null : (
            <ButtonCustom
              type={SizeButton.EXTRA_SMALL}
              kind="minimal"
              onClick={() => {
                setMaxItem(pre => (pre === 4 ? listItem.length : 4))
              }}
              style={{
                width: 'fit-content',
                ...lightTheme.typography.Small12medium,
                padding: '0 8px',
              }}
            >
              <>{maxItem === 4 ? t('Show more') : t('Show less')}</>
            </ButtonCustom>
          )}
        </div>
      )}
      {listItem && listItem.length > 0 ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {listItem.map((i, index) => {
            let Icon = i.icon ?? null
            let height, width
            if (i.size.width > i.size.height) {
              width = 50
              height = (i.size.height / i.size.width) * width
            } else {
              height = 50
              width = (i.size.width / i.size.height) * height
            }
            if (index < maxItem) {
              let customId = `${i.id}_${i.name}`
              // if (sizeFromTemplate) {
              //   if (`${i.size.width}_${i.size.height}` === sizeFromTemplate) {
              //     setSizeIdActive(customId)
              //     setSizeFromTemplate(null)
              //   }
              // } else {
              //   active = sizeIdActive === customId
              // }
              return (
                <PackItem
                  onClick={e => {
                    if (!hasPremium && name === 'Business') {
                      dispatch(
                        setOpenModalTryPremium({
                          isOpen: true,
                          source: 'BtSubscriptionCanvasSize',
                          callback: () => {
                            setIndexActive(index)
                            handleSelect(i.size, name, customId, {
                              ...i,
                              icon: i.icon
                            })
                          },
                        })
                      )
                      return
                    }
                    setIndexActive(index)
                    handleSelect(i.size, name, customId, {
                      ...i,
                      icon: i.icon
                    })
                  }}
                  key={i.id}
                  $active={customId === sizeIdActive}
                >
                  <>
                    <div
                      style={{
                        padding: '8px',
                        width: '66px',
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'relative',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: `${height > 16 ? height : 16}px`,
                          width: `${width > 16 ? width : 16}px`,
                          background: lightTheme.colors.grayScale100,
                          borderRadius: '4px',
                        }}
                      >
                        {Icon ? <Icon /> : <></>}
                      </div>
                    </div>
                    <span
                      style={{
                        position: 'absolute',
                        left: '66px',
                        top: '8px',
                        bottom: '9px',
                        borderRight: '1px solid rgba(229, 229, 229, 1)',
                      }}
                    ></span>
                    <div
                      style={{
                        width: '170px',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '16px 0 16px 8px',
                        gap: '2px',
                      }}
                    >
                      {i.name ? (
                        <p
                          style={{
                            margin: 0,
                            ...lightTheme.typography.Small11medium,
                            color: customId === sizeIdActive ? '#FF0560' : lightTheme.colors.contentPrimary,
                          }}
                        >
                          {t(i.name)}
                        </p>
                      ) : null}
                      {i.description ? (
                        <p
                          style={{
                            margin: 0,
                            ...lightTheme.typography.Small11medium,
                            color: lightTheme.colors.contentPrimary,
                          }}
                        >
                          {t(i.description)} {t('px')}
                        </p>
                      ) : null}
                    </div>
                    {!hasPremium && name === 'Business' ? (
                      <div style={{ position: 'absolute', top: '4px', right: '4px', zIndex: 1 }}>
                        <Icons.PremiumIcon />
                      </div>
                    ) : null}
                  </>
                </PackItem>
              )
            }
            return null
          })}
        </div>
      ) : (
        <Loading />
      )}
      {listItem.length > 4 ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '13px 0', opacity: showToggleBtn ? 1 : 0, transform: 'transition .3s ease'}}>
        <ButtonCustom
          type={SizeButton.EXTRA_SMALL}
          kind="minimal"
          onClick={() => {
            setMaxItem(pre => (pre === 4 ? listItem.length : 4))
          }}
          style={{
            height: '24px',
            width: 'fit-content',
            ...lightTheme.typography.Small12medium,
            padding: '0 8px',
            borderRadius: '360px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '6px',
            background: lightTheme.colors.grayScale50,
            ':hover': {
              background: lightTheme.colors.grayScale100,
            },
          }}
        >
          <>{maxItem === 4 ? t('Show more') : t('Show less')}</>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <LottiePlayerWrapper
            src={ArrowAnimation}
            play={showToggleBtn}
            autoplay={false}
            loop
            style={{
              width: '10px',
              height: '14px',
              transform: maxItem === 4 ? 'none' : 'rotate(180deg)',
            }}
          >
          </LottiePlayerWrapper>
          </div>
        </ButtonCustom>
      </div> : null}
    </div>
  )
}


const PackItem = styled('div', (props: any) => {
  return {
    display: 'flex',
    alignItems: 'center',
    border: props.$active ? '2px solid #FF0560' : lightTheme.borders.borderDefault,
    borderRadius: '8px',
    height: '66px',
    cursor: 'pointer',
    position: 'relative',
    boxSizing: 'border-box',
    ':hover': {
      background: props.$active ? '#fff' : lightTheme.colors.grayScale50,
    },
  }
})
