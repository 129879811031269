/**
 * Returns the number of transparent pixels
 * @memberof Image
 * @instance
 * @param {object} [options]
 * @param {number} [options.alpha=1] - Value of the alpha value to count.
 * @return {number} Number of transparent pixels
 */
export default function hasTransparency(options = {}) {
    this.checkProcessable('countAlphaPixels', {
      bitDepth: [8, 16],
      alpha: 1,
    });

    if (!(this.channels.length === 4 || this.alpha == 1)) {
        return false;
    }
    for (let i = this.components; i < this.data.length; i += this.channels) {
        if (this.data[i] !== 255) {
          return true
        }
      }
    return false;
}