// @ts-nocheck
import { fabric } from 'fabric'
import { ObjectType } from '../common/constants'
import { Size } from '@scenes/engine/objects/media-repository/size'
import { Inset } from '@scenes/engine/objects/media-repository/inset'
import { Rectangle } from '@scenes/engine/objects/media-repository/rectangle'

export class BackgroundImageObject extends fabric.Image {
  static type = ObjectType.BAZAART_BG
  //   @ts-ignore
  initialize(element, options) {
    options.type = ObjectType.BAZAART_BG
    //   @ts-ignore

    this.on('mouseup', ({ target }) => {
      const activeSelection = this.canvas.getActiveObject()
      if (!activeSelection && target === this) {
        this.canvas.fire('background:selected')
      }
    })

    this.on('mousedblclick', () => {
      this.set({
        hasControls: true,
        lockMovementY: false,
        lockMovementX: false,
        hasBorders: true,
      })
      this.canvas.setActiveObject(this)
      this.canvas.requestRenderAll()
    })
    super.initialize(element, {
      ...options,
      hasControls: false,
      lockMovementY: true,
      lockMovementX: true,
      selectable: false,
      hoverCursor: 'default',
      hasBorders: false,
    })
    return this
  }

  static fromObject(options: any, callback: Function) {
    fabric.util.loadImage(
      options.src,
      function (img) {
        //   @ts-ignore
        return callback && callback(new fabric.BackgroundImage(img, options))
      },
      null,
      // @ts-ignore
      { crossOrigin: 'anonymous' }
    )
  }
  getTextureKey(){
    return `${this.cacheKey}_${this.layerAssetStateId}_${this.cacheCounter}`;
  }
  toObject(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude)
  }
  toJSON(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude)
  }
  applyFilters(inputFilters?, filterBackend?) {
    if (!this.dirty || this.isLatest) {
      return this;
    }
    let filters = inputFilters || this.filters || []
    this.filters = filters;
    
    let imgElement = this._originalElement
    let inputSize = new Size(imgElement.width, imgElement.height)
    
    let bb = new Rectangle(0, 0, 1, 1)
    let roi = bb.multiply(inputSize);
    roi.round();

    let paddedSize = filters.reduce(
      (total, filter) => (filter.padSize ? filter.padSize(total) : total),
      roi.size()
    )
    let scaleX = roi.width / paddedSize.width
    let scaleY = roi.height / paddedSize.height

    let inputInset = new Inset(0, 0, 0, 0)
    let inset = filters.reduce(
      (total, filter) => (filter.applyInset ? filter.applyInset(total, inputSize) : total),
      inputInset
    )

    filters = filters.filter(function (filter) {
      return filter && !filter.isNeutralState()
    })

    let backend = filterBackend ?? fabric.filterBackend;
    this._element = backend.applyFiltersWithBoundingBox(
      filters,
      this._originalElement,
      roi.size(),
      inset,
      scaleX,
      scaleY,
      this.getTextureKey(),
      bb // this.boundingBox
    )

    this.filters = filters;
    this._filterScalingX = scaleX
    this._filterScalingY = scaleY
    this._inset = inset

    this.scaleX = this._originalScaleX / this._filterScalingX
    this.scaleY = this._originalScaleY / this._filterScalingY


    return this
  }
}

fabric.BackgroundImage = fabric.util.createClass(BackgroundImageObject, {
  type: BackgroundImageObject.type,
})
fabric.BackgroundImage.fromObject = BackgroundImageObject.fromObject

export interface BackgroundImageOptions extends fabric.IImageOptions {
  id: string
  name?: string
  description?: string
  subtype: string
  src: string
}

declare module 'fabric' {
  namespace fabric {
    class BackgroundImage extends BackgroundImageObject {
      constructor(element: any, options: any)
    }

    interface IUtil {
      isTouchEvent(event: Event): boolean
      getPointer(event: Event, a?: any): Point
    }
  }
}
