import React, { useEffect, useState } from 'react'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND, SHAPE } from 'baseui/button'
import { CheckIndeterminate, Plus } from 'baseui/icon'
import { Slider } from 'baseui/slider'
import useAppContext from '@/hooks/useAppContext'
import { useEditorContext, useRemoveEditorContext } from '@/scenes/engine'
import { LabelLarge } from 'baseui/typography'
import { useTranslation } from 'react-i18next'
import { FRAME_PADDING_ADD_MENU, FRAME_PADDING_INSPECTOR } from '@/scenes/engine/common/constants'
import { selectZoomRatio } from '@/store/slices/editor/selector'
import { useSelector } from 'react-redux'
import EraserObjectHandler from '@/scenes/engine/handlers/remove-handler/EraserObjectHandler'

function RemoveToolFooter({ pixelManipulationIsInProcess }) {
  const { isOpenInspector, toolType,isOpenTutorial } = useAppContext()
  const { editor, canvas } = useRemoveEditorContext()
  const mainEditor = useEditorContext().editor
  const [zoomRatio, setZoomRatio] = useState(1)
  const [openResetZoom, setOpenResetZoom] = useState(false)
  const mainZoomRatio = useSelector(selectZoomRatio)

  useEffect(() => {
    if (!canvas) {
      return
    }
    setOpenResetZoom(zoomRatio !== 1)
    editor.handlers.zoomRemoveHandler.currentZoom = zoomRatio
    if(!editor.handlers.pixelManipulationObjectHandler.isZoomAvaliable()) {
      return
    }
    editor.handlers.canvasRemoveHandler.setBrushSizeOnZoom()
  }, [zoomRatio])

  useEffect(() => {
    /**
     * Only eraser tool mirror the changes of main canvas 
     */
    if(toolType !== 'Eraser') {
      return
    }
    if (mainZoomRatio && mainEditor && mainZoomRatio !== 1) {
      if (
        Math.floor(mainEditor.handlers.frameHandler.getFitRatio() * 100) >= Math.floor(mainZoomRatio * 100) - 2.5 &&
        Math.floor(mainEditor.handlers.frameHandler.getFitRatio() * 100) <= Math.floor(mainZoomRatio * 100) + 2.5
      ) {
        setOpenResetZoom(false)
      } else {
        setOpenResetZoom(true)
      }
    }
  }, [mainEditor && mainEditor.handlers.frameHandler.getFitRatio(), mainZoomRatio])

  useEffect(() => {
    if (!editor) {
      return
    }
    editor.on('updateZoomRatio', zoomRatio => {
      setZoomRatio(zoomRatio)
    })
    return () => {
      editor.off('updateZoomRatio', zoomRatio => {
        setZoomRatio(zoomRatio)
      })
    }
  }, [editor])

  const { t } = useTranslation()
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '16px',
        left: isOpenInspector ? `calc(50% - ${FRAME_PADDING_INSPECTOR / 2}px + ${FRAME_PADDING_ADD_MENU / 2}px)` : `50%`,
        zIndex: 10,
        boxSizing: 'border-box',
        background: pixelManipulationIsInProcess ? '#F2F2F2' : '#fff',
        height: '40px',
        display: toolType === 'MagicBg' || toolType === 'Enhance' ? 'none' : 'flex',
        gap: '8px',
        borderRadius: '8px',
        padding: '4px',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.08)',
        transform: 'translateX(-50%)',
      }}
    >
      <ButtonCustom
        kind={KIND.minimal}
        type={SizeButton.SMALL}
        shape={SHAPE.square}
        onClick={() => {
          if (toolType === 'Eraser') {
            editor.handlers.pixelManipulationObjectHandler.isEraserToolResize = true
            mainZoomRatio >= 0.1 && mainEditor.handlers.zoomHandler.zoomOut()
            editor.handlers.pixelManipulationObjectHandler.updatePositionFollowMainCanvas()
          } else {
            const zoomStep = editor.handlers.zoomRemoveHandler.baseStep
            if (Number((zoomRatio - zoomStep).toFixed(1)) < 1) {
              return
            }
            setZoomRatio(pre => Number((pre - zoomStep).toFixed(1)))
            editor.handlers.zoomRemoveHandler.zoomOut()
          }
        }}
        tooltip={{
          content: 'Zoom out',
          placement: 'top',
        }}
        style={{
          opacity: pixelManipulationIsInProcess ? 0.55 : 1,
          pointerEvents: pixelManipulationIsInProcess ? 'none' : 'auto',
        }}
      >
        <CheckIndeterminate color="#666666" size={24} title="" />
      </ButtonCustom>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '118px',
        }}
      >
        <Slider
          value={[toolType === 'Eraser' ? mainZoomRatio * 100 > 300 ? 300 : mainZoomRatio * 100 : zoomRatio]}
          min={editor.handlers.zoomRemoveHandler.minZoom}
          max={editor.handlers.zoomRemoveHandler.maxZoom}
          step={editor.handlers.zoomRemoveHandler.sliderStep}
          onChange={({ value }) => {
            if (toolType === 'Eraser') {
              editor.handlers.pixelManipulationObjectHandler.isEraserToolResize = true
              let ratio = value[0] / 100
              value && mainEditor.handlers.zoomHandler.zoomToRatio(ratio)
              return editor.handlers.pixelManipulationObjectHandler.updatePositionFollowMainCanvas()
            } else {
              setZoomRatio(value[0])
              editor.handlers.zoomRemoveHandler.prevZoom = editor.handlers.zoomRemoveHandler.currentZoom
              editor.handlers.zoomRemoveHandler.currentZoom = value[0]
              editor.handlers.zoomRemoveHandler.updateZoom()
            }
          }}
          overrides={{
            Track: {
              style: ({ $theme }) => ({
                paddingLeft: '10px',
                paddingRight: '10px',
              }),
            },
            InnerThumb: ({ $value, $thumbIndex }) => <>{ }</>,
            ThumbValue: () => null,
            Thumb: {
              style: () => ({
                height: '20px',
                width: '20px',
                borderRadius: '50%',
                boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.3)',
                backgroundColor: pixelManipulationIsInProcess ? '#F2F2F2' : '#fff',
              }),
            },
            InnerTrack: {
              style: ({ $theme }) => {
                return {
                  // @ts-ignore
                  background: `linear-gradient(to right, ${$theme.colors.grayScale600} 0%, ${
                    // @ts-ignore
                    $theme.colors.grayScale600
                    // @ts-ignore
                    } ${toolType === 'Eraser' ? ((mainZoomRatio - 0.1) / 2.9) * 100 : ((zoomRatio - 1) / 4) * 100}%, ${$theme.colors.grayScale100} ${toolType === 'Eraser' ? ((mainZoomRatio - 0.1) / 2.9) * 100 : ((zoomRatio - 1) / 4) * 100}%, 
                    ${
                    // @ts-ignore
                    $theme.colors.grayScale100
                    } 100%)`,
                  width: '100%',
                }
              },
            },
            Tick: {
              style: ({ $theme }) => ({
                display: 'none',
              }),
            },
            TickBar: {
              style: ({ $theme }) => ({
                display: 'none',
              }),
            },
          }}
          disabled={pixelManipulationIsInProcess}
        />
      </div>
      <ButtonCustom
        kind={KIND.minimal}
        type={SizeButton.SMALL}
        shape={SHAPE.square}
        onClick={() => {
          if (toolType === 'Eraser') {
            mainZoomRatio <= 30 && mainEditor.handlers.zoomHandler.zoomIn()
            editor.handlers.pixelManipulationObjectHandler.isEraserToolResize = true
            editor.handlers.pixelManipulationObjectHandler.updatePositionFollowMainCanvas()
          } else {
            const zoomStep = editor.handlers.zoomRemoveHandler.baseStep
            if (zoomRatio + zoomStep > 5) return
            setZoomRatio(pre => pre + zoomStep)
            editor.handlers.zoomRemoveHandler.zoomIn()
          }
        }}
        tooltip={{
          content: 'Zoom in',
          placement: 'top',
        }}
        style={{
          opacity: pixelManipulationIsInProcess ? 0.55 : 1,
          pointerEvents: pixelManipulationIsInProcess ? 'none' : 'auto',
        }}
      >
        <Plus color="#666666" size={24} title="" />
      </ButtonCustom>

      <ButtonCustom
        kind={KIND.minimal}
        type={SizeButton.STANDARD}
        onClick={() => {
          if (toolType === 'Eraser') {
            mainEditor.handlers.zoomHandler.zoomToFit()
            mainEditor.handlers.scrollbarHandler.hideScrollbar()
            editor.handlers.pixelManipulationObjectHandler.updatePositionFollowMainCanvas()
          } else {
            editor.handlers.zoomRemoveHandler.zoomToFit()
          }
          setOpenResetZoom(false)
        }}
        style={{
          display: openResetZoom ? 'block' : 'none',
          position: 'absolute',
          left: 'calc(100% + 8px)',
          top: 0,
          bottom: 0,
          background: pixelManipulationIsInProcess ? '#F2F2F2' : '#fff',
          boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.08)',
          padding: '8px 12px',
          width: 'max-content',
          minWidth: '106px',
          letterSpacing: '-0.006em',
          opacity: 1,
          pointerEvents: pixelManipulationIsInProcess ? 'none' : 'auto',
          transition: 'opacity .4s linear',
          ':hover': {
            background: '#F9F9F9'
          },
          ':active': {
            background: '#F2F2F2'
          }
        }}
      >
        <LabelLarge $style={{ color: pixelManipulationIsInProcess ? '#CCCCCC' : '#666666' }}>{t('Reset Zoom')}</LabelLarge>
        <style></style>
      </ButtonCustom>
    </div>
  )
}

export default RemoveToolFooter