import { StaticImage } from 'fabric/fabric-impl'
import { ObjectType } from '../common/constants'
import { HandlerOptions } from '../common/interfaces'
import BaseHandler from './BaseHandler'

class CropHandler extends BaseHandler {
  
  constructor(props: HandlerOptions) {
    super(props)
    this.initialize()
  }

  initialize() {
    this._registerEvent()
  }

  // Register Event
  private _registerEvent = () => {
    this.canvas.on('mouse:dblclick', this.dbClick)
  }

  dbClick = event => {
    const { target } = event
    if (target && target.type === ObjectType.BAZAART_IMAGE) {
      const frame = this.root.frameHandler.get()
      console.log('dbClick', target)
      console.log('frame', frame)
    }
  }

  destroy() {
    this.canvas.off({
      'mouse:dblclick': this.dbClick
    })
  }
}

export default CropHandler
