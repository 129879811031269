export class Inset {
    left: number;
    top: number;
    right: number;
    bottom: number;

    constructor(left: number, top: number, right: number, bottom: number) {
        this.left = left;
        this.top = top;
        this.right = right;
        this.bottom = bottom;
    }

    get height(): number {
        return this.top + this.bottom;
    }

    get width(): number {
        return this.left + this.right;
    }

    static combine(a: Inset, b: Inset): Inset {
        const newInset = new Inset(
            a.left + b.left,
            a.top + b.top,
            a.right + b.right,
            a.bottom + b.bottom,
        )
        return newInset;
    }

    mulitply(xRatio: number, yRatio: number): Inset {
        return new Inset(
            this.left * xRatio,
            this.top * yRatio,
            this.right * xRatio,
            this.bottom * yRatio
        );
    }

    static empty(): Inset{
        return new Inset(0, 0, 0, 0);
    }
}