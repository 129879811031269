import { lightTheme } from '@/customTheme'

function InspectorSectionTitle({ title } : { title: string}) {
    return (
        <div
            style={{
            width: '292px',
            height: '26px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '8px',
            }}
            >
            <span style={{ ...lightTheme.typography.Small14Semibold, color:lightTheme.colors.text.text_black_title, textTransform: 'capitalize' }}>{title}</span>
        </div>
    )
}

export default InspectorSectionTitle