
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '@store/store'

// Inspector Context
import { InspectorPanelType, InspectorPanelInputData } from './InspectorDataProvider'
import InspectorPanel from "./InspectorPanel"

// Contents
import Background from '../Panels/PanelItems/Background';
import Text from './ToolboxItems/Text';
import Image from './ToolboxItems/Image';
import Sticker from './ToolboxItems/Sticker'
import OutlineText from './ToolboxItems/components/OutlineText';
import OutlineImage from './ToolboxItems/components/OutlineImage';
import Resize from '@/scenes/Editor/components/Navbar/components/Resize'
import ShadowImage from './ToolboxItems/components/ShadowImage';
import ShadowText from './ToolboxItems/components/ShadowText';
import Adjust from './ToolboxItems/components/Adjust';
import FontFamily from '../Panels/PanelItems/FontFamily';
import Fill, { FillProps } from '../Panels/PanelItems/Fill';
import Filter from './ToolboxItems/components/Filter';
import EraserTool from './ToolboxItems/EraserTool';
import MagicBgTool from './ToolboxItems/MagicBgTool';
import CategoryDetail, { CategoryDetailProps } from './ToolboxItems/MagicBgToolSubComponent/CategoryDetail';
import RemoveTool from './ToolboxItems/RemoveTool';
import EnhanceTool from './ToolboxItems/components/EnhanceTool';
import { setIntroVideoDetail } from '@/store/slices/user/actions'
import MultiElement from './ToolboxItems/MultiElement';
import Crop from './ToolboxItems/Crop';

interface InspectorContentProps {
    type: InspectorPanelType,
    data?: InspectorPanelInputData,
    // addInspector: (type: InspectorPanelType, data: InspectorPanelInputData, addWithAnimation: boolean)=>void,
    hideInspector: (type: InspectorPanelType, closeWithAnimation: boolean)=>void,
    pixelManipulationDoneClick: ()=>void,
    pixelManipulationCancelClick: ()=>void,
    didPixelManipulationPerformAction: boolean,
    isPixelManipulationInProcess: boolean,
    isDoneButtonAvailableLocal: boolean,
    setIsDoneButtonAvailableLocal: Function
}

const InspectorContent: React.FC<InspectorContentProps> = (props) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const { 
        type, 
        data, 
        hideInspector, 
        pixelManipulationDoneClick, 
        pixelManipulationCancelClick, 
        didPixelManipulationPerformAction, 
        isPixelManipulationInProcess,
        isDoneButtonAvailableLocal,
        setIsDoneButtonAvailableLocal
     } = props;

    const getInspectorContent = () => {
        if (type == InspectorPanelType.Canvas) {
            return <InspectorPanel title={t("Canvas")}>
                <Background openResize={data?.props?.openResize ?? false}></Background>
            </InspectorPanel>
        }
        else if (type == InspectorPanelType.Resize) {
            return <InspectorPanel title={t("Resize")} hasNavigation={true}>
                <Resize resizeActiveItem={data.state} setResizeActiveItem={data.setState}/>
            </InspectorPanel>
        }
        else if (type == InspectorPanelType.Sticker) {
            return <InspectorPanel title={t("Sticker")} hasClose={true}>
                <Sticker></Sticker>
            </InspectorPanel>
        }
        else if (type == InspectorPanelType.OutlineText) {
            return <InspectorPanel title={t("Outline")} hasNavigation={true}>
                <OutlineText></OutlineText>
            </InspectorPanel>
        }
        else if (type == InspectorPanelType.OutlineImage) {
            return <InspectorPanel title={t("Outline")} hasNavigation={true}>
                <OutlineImage></OutlineImage>
            </InspectorPanel>
        }
        else if (type == InspectorPanelType.ShadowText) {
            return <InspectorPanel title={t("Shadow")} hasNavigation={true}>
                <ShadowText></ShadowText>
            </InspectorPanel>
        }
        else if (type == InspectorPanelType.ShadowImage) {
            return <InspectorPanel title={t("Shadow")} hasNavigation={true}>
                <ShadowImage></ShadowImage>
            </InspectorPanel>
        }
        else if (type == InspectorPanelType.Adjust) {
            return <InspectorPanel title={t("Adjust")} hasNavigation={true}>
                <Adjust inputObject={data.object}></Adjust>
            </InspectorPanel>
        }
        else if (type == InspectorPanelType.Text) {
            return <InspectorPanel title={t("Text")} hasClose={true}>
                <Text></Text>
            </InspectorPanel>
        }
        else if (type == InspectorPanelType.FontFamily) {
            return <InspectorPanel title={t("Font Family")} hasNavigation={true}>
                <FontFamily></FontFamily>
            </InspectorPanel>
        }
        else if (type == InspectorPanelType.Image) {
            return <InspectorPanel title={t("Photo")} hasClose={true}>
                <Image openRemoveObjects={data?.props?.openRemoveObjects ?? false} openMagicBackground={data?.props?.openMagicBackground ?? false} openEnhance={data?.props?.openEnhance ?? false}></Image>
            </InspectorPanel>
        }
        else if (type == InspectorPanelType.Fill) {
            const fillProps = data.props as FillProps;
            return <InspectorPanel title={t("Fill")} hasNavigation={true} hasAutoScroll={false}>
                <Fill { ...fillProps } bgActiveID={data.state} setBgActiveID={data.setState}></Fill>
            </InspectorPanel>
        }
        else if (type == InspectorPanelType.Filter) {
            return <InspectorPanel title={t("Filter")} hasNavigation={true}>
                <Filter filterObject={data.object}></Filter>
            </InspectorPanel>
        }
        else if (type == InspectorPanelType.Eraser) {
            return <InspectorPanel title={t("Eraser")} 
                hasFooterActionButtons={true} 
                onClickPrimary={ ()=>{ hideInspector(InspectorPanelType.Eraser, true); pixelManipulationDoneClick()} } 
                onClickSecondary={ () => { hideInspector(InspectorPanelType.Eraser, true); pixelManipulationCancelClick()} }
                isPrimaryButtonActive={ didPixelManipulationPerformAction }>
                <EraserTool></EraserTool>
            </InspectorPanel>
        }
        else if (type == InspectorPanelType.MagicBg) {
            return <InspectorPanel title={t("Magic Background")} 
                hasFooterActionButtons={true} 
                onClickPrimary={ ()=>{ hideInspector(InspectorPanelType.MagicBg, true); pixelManipulationDoneClick()} } 
                onClickSecondary={ () => { hideInspector(InspectorPanelType.MagicBg, true); pixelManipulationCancelClick()} }
                isPrimaryButtonActive={ isDoneButtonAvailableLocal }
                hasQuestionMarkButton={true}
                questionMarkButtonAction={()=>{
                    dispatch(
                        setIntroVideoDetail({
                        isOpen: true,
                        title: 'Create stunning AI backgrounds',
                        subtitle: 'Make your photos look professional.',
                        videoSrc: 'magic-bg',
                        contentBtn: 'Got it',
                        onClickButton: () => {
                            dispatch(setIntroVideoDetail(null))
                        },
                        })
                    )
                }}>
                <MagicBgTool isDoneButtonAvailable={isDoneButtonAvailableLocal} setIsDoneButtonAvailable={setIsDoneButtonAvailableLocal} />
            </InspectorPanel>
        }
        else if (type == InspectorPanelType.MagicBgCategory) {   
            const categoryDetailProps = data.props as CategoryDetailProps;

            return <InspectorPanel title={data.props.categoryActive?.title ?? " "} hasNavigation={true} hasFooterActionButtons={true} onClickSecondaryShowDiscardModal={true}
                onClickPrimary={ ()=>{ 
                    hideInspector(InspectorPanelType.MagicBgCategory, true); 
                    hideInspector(InspectorPanelType.MagicBg, true); 
                    pixelManipulationDoneClick()} } 
                onClickSecondary={ () => { 
                    hideInspector(InspectorPanelType.MagicBgCategory, true)
                    hideInspector(InspectorPanelType.MagicBg, true)
                    pixelManipulationCancelClick()
                } }
                isPrimaryButtonActive={ isDoneButtonAvailableLocal }>
                <CategoryDetail {...categoryDetailProps}/>
            </InspectorPanel>
        }
        else if (type == InspectorPanelType.RemoveObjects) {
            return <InspectorPanel title={t("Remove Objects")} 
                hasFooterActionButtons={true} 
                onClickPrimary={ ()=>{ hideInspector(InspectorPanelType.RemoveObjects, true); pixelManipulationDoneClick()} } 
                onClickSecondary={ () => { hideInspector(InspectorPanelType.RemoveObjects, true); pixelManipulationCancelClick()} }
                isPrimaryButtonActive={ didPixelManipulationPerformAction && !isPixelManipulationInProcess}
                isSecondaryButtonActive={ !isPixelManipulationInProcess }
                hasQuestionMarkButton={true}
                questionMarkButtonAction={()=>{                    
                    dispatch(setIntroVideoDetail({
                        isOpen: true,
                        title: 'Keep what’s important. Remove the rest.',
                        subtitle: t('Easily remove people and objects.'),
                        videoSrc: 'remove',
                        contentBtn: 'Got it',
                        onClickButton: () => {
                            dispatch(setIntroVideoDetail(null))
                        },
                    })
                )}}>
                <RemoveTool />
            </InspectorPanel>
        }
        else if (type == InspectorPanelType.Enhance) {
            return (
                <InspectorPanel
                  title={t('Enhance')}
                  hasFooterActionButtons={true}
                  onClickPrimary={() => {
                    hideInspector(InspectorPanelType.Enhance, true)
                    pixelManipulationDoneClick()
                  }}
                  onClickSecondary={() => {
                    hideInspector(InspectorPanelType.Enhance, true)
                    pixelManipulationCancelClick()
                  }}
                  isPrimaryButtonActive={didPixelManipulationPerformAction}
                  isSecondaryButtonActive={true}
                  hasQuestionMarkButton={true}
                  questionMarkButtonAction={() => {
                    dispatch(
                      setIntroVideoDetail({
                        isOpen: true,
                        title: t('Enhance your photos with AI'),
                        subtitle: t('Increase photo quality in a click.'),
                        videoSrc: 'enhance',
                        contentBtn: 'Got it',
                        onClickButton: () => {
                          dispatch(setIntroVideoDetail(null))
                        },
                      })
                    )
                  }}
                >
                  <EnhanceTool></EnhanceTool>
                </InspectorPanel>
              )
        }
        else if (type == InspectorPanelType.MultiElement) {
            return <InspectorPanel title={t("Multi Element")} hasClose={true}>
                <MultiElement/>
            </InspectorPanel>
        }
        else if (type == InspectorPanelType.Crop) {
            return <InspectorPanel title={t("Crop")} 
            hasClose={true}
            hasFooterActionButtons={true} 
            onClickPrimary={ ()=>{ hideInspector(InspectorPanelType.Crop, true); pixelManipulationDoneClick()} } 
            onClickSecondary={ () => { hideInspector(InspectorPanelType.Crop, true); pixelManipulationCancelClick()} }
            isPrimaryButtonActive={ didPixelManipulationPerformAction }
            >
                <Crop/>
            </InspectorPanel>
        }
        return <></>
    }



    return (
        <>
        { getInspectorContent() }
        </>
    )
}

export default InspectorContent;

