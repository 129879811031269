export const initializeProfitWell = (userId) => {
    let profitwell = (window as any).profitwell;
    if (profitwell) {
        profitwell('start', {
            user_id: userId,
        });
        console.log('ProfitWell initialized with user ID:', userId);
    } else {
        console.error('ProfitWell script is not loaded.');
    }
};