import Icons from '../../Icons'
import { useEffect, useState } from 'react'
import { useEditorContext } from '@/scenes/engine'
import Spacing from './components/Spacing'
import { lightTheme } from '@/customTheme'
// import Font from
import Back from '@/components/icons/Back'
import ColorsPackContainer from '@/ColorsPackContainer'
import BtnOpenNewSlide from '@/components/BtnOpenNewSlide'
import Opacity from './components/Opacity'
import Transform from './components/Transform'
import { MODE, ButtonGroup } from 'baseui/button-group'
import TextFontSize from './components/TextFontSize'
import { styled } from 'baseui'
import FontFamily from '../../Panels/PanelItems/FontFamily'
import { useSelector } from 'react-redux'
import { selectFonts } from '@/store/slices/fonts/selectors'
import { ObjectType } from '@/scenes/engine/common/constants'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'
import OutlineText from './components/OutlineText'
import ShadowText from './components/ShadowText'
import { InspectorPanelType, useInspector } from '../InspectorDataProvider'
import { Button } from 'baseui/button'
import { StaticText } from 'fabric/fabric-impl'

interface TextOptions {
  name: string
  fontFamily: string
  fontSize: number
  fontWeight: string | number
  opacity: number[]
  italic: string
  textAlign: string
  underline: string
  fill: string
}

const defaultOptions = {
  name: '',
  fontFamily: '',
  fontWeight: 'normal',
  fontSize: 12,
  opacity: [1],
  italic: 'true',
  textAlign: 'center',
  underline: 'true',
  fill: lightTheme.colors.blackGray,
}

const LIST_ALIGN = [
  {
    value: 'left',
  },
  {
    value: 'center',
  },
  {
    value: 'right',
  },
]

function Text() {
  const activeObject = useEditorContext().activeObject as StaticText
  const [options, setOptions] = useState<TextOptions>(defaultOptions)
  const { editor } = useEditorContext()
  const [fillSelected, setFillSelected] = useState(null)
  const fonts = useSelector(selectFonts)
  const { addInspector } = useInspector();

  useEffect(() => {
    updateOptions(activeObject)
    if (activeObject?.fill) {
      setFillSelected(activeObject?.fill)
    }
  }, [activeObject])

  useEffect(() => {
    const handleChanges = () => {
      updateOptions(activeObject)
    }
    editor.on('history:changed', handleChanges)
    return () => {
      editor.off('history:changed', handleChanges)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  const updateOptions = (object: fabric.TextOptions) => {
    if (!object) {
      return
    }
    let name
    let desc
    let fontSelected = fonts.filter(f => f.family === object?.fontFamily)[0]
    if (fontSelected) {
      name = fontSelected.name
      desc = fontSelected.desc
    } else {
      name = object?.name
      desc = object?.fontFamily
    }

    const textOptions = {
      name: name,
      fontFamily: desc,
      fontSize: object?.fontSize,
      fontWeight: object?.fontWeight,
      opacity: [object?.opacity * 100],
      italic: object?.fontStyle,
      textAlign: object?.textAlign,
      underline: object?.underline,
      fill: object?.fill,
    } as unknown as TextOptions

    setOptions(textOptions)
  }

  const checkBold = (value: string | number) => {
    return value === 'bold' || value === 700
  }

  const toggleBold = () => {
    const isBold = checkBold(options.fontWeight)
    editor.handlers.objectsHandler.update({ fontWeight: isBold ? 400 : 700 })
  }

  const toggleUnderline = () => {
    editor.handlers.objectsHandler.update({ underline: activeObject.underline ? false : true })
  }

  const checkIsItalic = (value: string) => {
    const isItalic = value === 'italic'
    return isItalic
  }

  const toggleItalic = () => {
    const isItalic = checkIsItalic(activeObject.fontStyle)
    editor.handlers.objectsHandler.update({ fontStyle: isItalic ? 'normal' : 'italic' })
  }

  const getNextTextAlign = (current: string) => {
    const positions = ['left', 'center', 'right', 'left']
    const currentIndex = positions.findIndex(v => v === current)
    const nextAlign = positions[currentIndex + 1]
    return nextAlign
  }

  const toggleTextAlign = () => {
    const currentValue = activeObject?.textAlign
    const nextTextAlign = getNextTextAlign(currentValue)
    editor.handlers.objectsHandler.update({ textAlign: nextTextAlign })
  }
  const updateTextAlign = value => {
    editor.handlers.objectsHandler.update({ textAlign: value })
    activeObject.setCoords()
    editor.handlers.objectsHandler.setTextInitialPosition(activeObject)
  }

  const getTextAlignIcon = value => {
    // const currentValue = activeObject.textAlign
    const Icon =
      value === 'left'
        ? Icons.TextAlignLeft
        : value === 'center'
        ? Icons.TextAlignCenter
        : value === 'right'
        ? Icons.TextAlignRight
        : Icons.TextAlignJustify
    return Icon
  }

  const updateFontSize = (value: number) => {
    editor.handlers.objectsHandler.update({ fontSize: value })
  }

  // const TextAlignIcon = getTextAlignIcon()
  const updateColor = (color: string) => {
    editor.handlers.objectsHandler.update({ fill: color })
    setFillSelected(color)
    const eventProperties = {
      Tool: 'bazaart.style.fill',
      Type: 'color',
      'Layer Type': ObjectType.BAZAART_TEXT,
    }
    customAmplitude('Selected tool', eventProperties)
  }
  const { t } = useTranslation()

  return (
    <>
        {/* open list font */}
          <div>
            <div
              style={{
                // width: '292px',
                height: '26px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '8px',
              }}
            >
              <span
                style={{
                  ...lightTheme.typography.Small14Semibold,
                  color:lightTheme.colors.text.text_black_title 
                }}
              >
                {t('Font')}
              </span>
            </div>
            <BtnOpenNewSlide
              handleOnClick={() => addInspector(InspectorPanelType.FontFamily)}
              endEnhancer={<Back size={24} />}
              nameAction={options.name ?? t('Default')}
              subAction={options.fontFamily}
              style={{ marginTop: '8px' }}
            ></BtnOpenNewSlide>
            {/* font family */}
          </div>
          {/* font size */}
          <TextFontSize />

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                height: '26px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '8px',
              }}
              className='mt-50'
            >
              <span
                style={{
                  ...lightTheme.typography.Small14Semibold,
                  color:lightTheme.colors.text.text_black_title 
                }}
              >
                {t('Align')} & {t('Spacing')}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <div
                style={{
                  height: '40px',
                  width: '217px',
                  border: '1px solid rgba(229, 229, 229, 1)',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <ButtonGroup
                  mode={MODE.radio}
                  overrides={{
                    Root: {
                      style: ({ $theme }) => ({
                        width: '217px',
                      }),
                    },
                  }}
                >
                  {LIST_ALIGN.map(align => {
                    let AlignIcon = getTextAlignIcon(align.value)
                    return (
                      <Button
                        onClick={() => updateTextAlign(align.value)}
                        key={align.value}
                        overrides={{
                          BaseButton: {
                            style: ({ $theme }) => ({
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: '100%',
                              height: '40px',
                              outline: 'none',
                              border: '0',
                              backgroundColor: '#fff',
                              cursor: 'pointer',
                              ':hover': {
                                svg: {
                                  fill: '#ff3462',
                                },
                              },
                            }),
                          },
                        }}
                      >
                        <AlignIcon
                          size={24}
                          color={(activeObject?.textAlign ?? options.textAlign) === align.value ? '#ff3462' : '#666666'}
                        />
                      </Button>
                    )
                  })}
                </ButtonGroup>
              </div>
              <Spacing />
            </div>
          </div>
          <ColorsPackContainer
            style={{
              boxSizing: 'border-box',
              padding: '0',
            }}
            onItemSelected={updateColor}
            colorSelected={fillSelected}
          ></ColorsPackContainer>
          {/* <Animate /> */}
          {/* btn fill */}

          {/* <BtnOpenNewSlide
            handleOnClick={() => {}}
            startEnhancer={<FillText size={24} />}
            endEnhancer={<Back size={24} />}
            nameAction="Fill"
            style={{
              marginTop: '32px',
            }}
          ></BtnOpenNewSlide> */}

          {/* TODO */}
          {/* <Curve /> */}
          {/* btn background */}
          {/* <BtnOpenNewSlide
            handleOnClick={() => {}}
            startEnhancer={<Background size={24} />}
            endEnhancer={
              <Checkbox
                checked={toggleBackground}
                onChange={e => {
                  setToggleBackground(e.currentTarget.checked)
                }}
                checkmarkType={STYLE_TYPE.toggle_round}
                overrides={{
                  ToggleTrack: {
                    style: ({ $theme }) => {
                      return {
                        // background: 'red',
                      }
                    },
                  },
                }}
              ></Checkbox>
            }
            nameAction="Background"
            style={{
              marginTop: '50px',
            }}
          ></BtnOpenNewSlide> */}
          {/* btn outline */}
          <BtnOpenNewSlide
            handleOnClick={() => addInspector(InspectorPanelType.OutlineText)}
            startEnhancer={<Icons.Outline size={24} />}
            endEnhancer={<Back size={24} />}
            nameAction={t("Outline")}
          ></BtnOpenNewSlide>
          {/* btn shadow */}
          <BtnOpenNewSlide
            handleOnClick={() => addInspector(InspectorPanelType.ShadowText)}
            startEnhancer={<Icons.Shadow size={24} />}
            endEnhancer={<Back size={24} />}
            nameAction={t("Shadow")}
            style={{
              marginTop: '16px',
            }}
          ></BtnOpenNewSlide>
          <Opacity />
          {/* blending */}
          {/* <Blending /> */}
          {/* TODO */}
          <Transform />
          {/* <ButtonCustom
            kind={KIND.tertiary}
            type={SizeButton.LARGE}
            startEnhancer={<Erase size={24} />}
            style={{
              margin: '50px 0',
            }}
          >
            <LabelLarge>Erase</LabelLarge>
          </ButtonCustom> */}
    </>
  )
}
export default Text