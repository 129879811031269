import React, { useEffect, useRef } from 'react';
import { DotLottiePlayer, Props } from '@dotlottie/react-player';
import '@dotlottie/react-player/dist/index.css';

interface LottiePlayerWrapperProps extends Omit<Props, 'dotLottieRefCallback'> {
  play: boolean; // Control play/pause
}

const LottiePlayerWrapper: React.FC<LottiePlayerWrapperProps> = ({ play, src, ...props }) => {
  const playerRef = useRef<any>(null);

  useEffect(() => {
    if (playerRef.current) {
      if (play) {
        playerRef.current.play();
      } else {
        playerRef.current.stop();
      }
    }
  }, [play]);

  return (
    <DotLottiePlayer
      src={src} 
      {...props}
      ref={playerRef}    />
  );
};

export default LottiePlayerWrapper;
