import { fabric } from 'fabric'
import BaseHandler from './BaseHandler'
import shourcutsManager from '../utils/shourcutsManager'
import { HandlerOptions } from '../common/interfaces'
import { ObjectType } from '../common/constants'
import { getTourState } from '@/utils/tutorial'
import { StaticText } from 'fabric/fabric-impl'

class EventsHandler extends BaseHandler {

  stateBeforeChange: { version: string; objects: Object[] }
  isSpaceKeyPressed = false
  isPanning = false
  originalCursorStyles = {}
  lastPointerX
  lastPointerY
  currentActiveObjectId = ""
  textChangedActiveObjects = []
  originalDiscardActiveObjects: Function
  allowFireObjectChanging = true

  constructor(props: HandlerOptions) {
    super(props)
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.initialize()
  }

  initialize() {
    this.canvas.wrapperEl.tabIndex = 1
    this.canvas.wrapperEl.style.outline = 'none'
    this.originalDiscardActiveObjects = this.canvas.discardActiveObject.bind(this.canvas)
    // @ts-ignore
    this.canvas.on({
      'selection:created': this.handleSelection,
      'selection:cleared': this.handleSelection,
      'selection:updated': this.handleSelection,
      'mouse:wheel': this.onMouseWheel,
      'mouse:out': this.onMouseOut,
      'mouse:down': this.onMouseDown,
      'mouse:up': this.onMouseUp,
      'mouse:move': this.onMouseMove,
      'object:modified': this.objectModified,
      'object:moving': this.objectChanging,
      'object:scaling': this.objectScaling,
      'object:rotating': this.objectRotating,
      'object:resizing': this.objectResizing,
      'object:skewing': this.objectChanging,
      'object:selected': this.objectSelected,
      'text:editing:entered': this.textEditingEntered,
      'text:changed': this.onTextChanged,
      'before:transform': this.objectBeforeTransform,
      'after:render': this.onAfterRender,
    })

    // this.canvas.wrapperEl.addEventListener('keydown', this.onKeyDown, false)
    document.addEventListener('keydown', this.onKeyDown, false)
    // this.canvas.wrapperEl.addEventListener('keyup', this.onKeyUp, false)
    document.addEventListener('keyup', this.onKeyUp, false)
    window.addEventListener('wheel', this.onWindowMouseWheel.bind(this), { passive: false })
    document.addEventListener('mouseup', this.onDocumentMouseUp.bind(this))
  }

  destroy() {
    this.canvas.off({
      'selection:created': this.handleSelection,
      'selection:cleared': this.handleSelection,
      'selection:updated': this.handleSelection,
      'mouse:wheel': this.onMouseWheel,
      'mouse:out': this.onMouseOut,
      'mouse:down': this.onMouseDown,
      'mouse:up': this.onMouseUp,
      'mouse:move': this.onMouseMove,
      'object:modified': this.objectModified,
      'object:moving': this.objectChanging,
      'object:scaling': this.objectChanging,
      'object:rotating': this.objectRotating,
      'object:resizing': this.objectResizing,
      'object:skewing': this.objectChanging,
      'text:editing:entered': this.textEditingEntered,
      'text:changed': this.onTextChanged,
      'before:transform': this.objectBeforeTransform,
      'after:render': this.onAfterRender,
    })

    // this.canvas.wrapperEl.removeEventListener('keydown', this.onKeyDown)
    document.removeEventListener('keydown', this.onKeyDown, false)
    // this.canvas.wrapperEl.removeEventListener('keyup', this.onKeyUp)
    document.removeEventListener('keyup', this.onKeyUp)
    window.removeEventListener('wheel', this.onWindowMouseWheel.bind(this))
    document.removeEventListener('mouseup', this.onDocumentMouseUp.bind(this))
  }

  objectBeforeTransform = () => {
    this.stateBeforeChange = this.root.canvasHandler.exportToCanvasJSON()
  }

  objectChanging = () => {
    if(this.allowFireObjectChanging) {
      this.root.transactionHandler.fireWithoutSave('object:changing')
    }
  }
  objectRotating = (e) => {
    this.objectChanging()
    // const target = e.target
    // if(!target) { return }
    // if (target.type === ObjectType.BAZAART_TEXT) {
    //   target.isResized = true
    //   target.splitByGrapheme = true
    // }
  }

  objectScaling = (e) => {
    this.root.transactionHandler.fireWithoutSave('object:changing')
    const activeObject = this.canvas.getActiveObject()
    this.root.personalizationHandler.updateDrawBorder(activeObject)
  }
  objectSelected = (e) => {
    console.log(e)
  }

  objectModified = (e) => {
    const target = e.target
    this.root.transactionHandler.save(this.stateBeforeChange)
    if (target.type === ObjectType.BAZAART_TEXT) {
      target._originalHeight = target.height
      target._originalWidth = target.width
      if(target.shadow) {
        const text = target
        text.shadow = {
          ...text.shadow,
          offsetX: Math.floor((text.shadow.offsetX / e.transform.width) * e.transform.target.width),
          offsetY: Math.floor((text.shadow.offsetY / e.transform.width) * e.transform.target.width),
          blur: Math.floor((text.shadow.blur / e.transform.width) * e.transform.target.width),
        }
      }
    }
    if (target.type === ObjectType.ACTIVE_SELECTION) {
      this.canvas.discardActiveObject()
      // @ts-ignore
      this.canvas.getObjects().map(o => o.set({ _originalScaleX: o.scaleX, _originalScaleY: o.scaleY }))
      const instance = new fabric.ActiveSelection(target._objects, { canvas: this.canvas })
      this.canvas.setActiveObject(instance).requestRenderAll()
    }

    if (target.type === ObjectType.GROUP) {
      target.getObjects().map(obj => {
        const scaleX = target.scaleX * obj.scaleX;
        const scaleY = target.scaleY * obj.scaleY;
        const _originalScaleX = target.scaleX * obj._originalScaleX;
        const _originalScaleY = target.scaleY * obj._originalScaleY;
        const left = obj.left * target.scaleX
        const top = obj.top * target.scaleY
        // Update scale and position of each object in the group
        if(obj.type === ObjectType.BAZAART_TEXT) {
          obj.set({
            fontSize: obj.fontSize! * scaleX!,
            width: obj.width! * scaleX!,
            left,
            top,
            scaleX: 1,
            scaleY: 1,
          });
        } else {
          obj.set({
            scaleX,
            scaleY,
            // @ts-ignore
            _originalScaleX,
            _originalScaleY,
            left,
            top
          });
        }
        obj.setCoords();
      })
      // Update the group scale and size to make sure that that scaling transformation is applied directly to the child objects, and the group's scaling transformation is neutralized
      target.set({
        width: target.getScaledWidth(),
        height: target.getScaledHeight(),
        scaleX: 1,
        scaleY: 1
      })
      target.setCoords();
      this.canvas.renderAll();
    }
    this.root.objectsHandler.updateSizeOnCanvas(target)
  }

  objectResizing = (e) => {
    this.root.transactionHandler.fireWithoutSave('object:changing')
    const target = e.target
    if(!target) { return }
    if (target.type === ObjectType.BAZAART_TEXT) {
      target.isResized = true
      target.splitByGrapheme = true
      this.root.objectsHandler.handleTextSizeOnChanged(target)
    }
  }

  onTextChanged = (e) => {
    const target = e.target
    if(!target) { return }
    this.root.objectsHandler.handleTextSizeOnChanged(target)
  }

  textEditingEntered = (e) => {
    const target = e.target
    this.root.objectsHandler.setTextInitialPosition(target)
  }

  onMouseOut = () => {
    this.canvas.renderAll()
  }

  onMouseDown = (e) => {
    if (this.isSpaceKeyPressed && e.button === 1) {
      this.canvas.selection = false
      this.isPanning = true
      this.lastPointerX = e.pointer.x
      this.lastPointerY = e.pointer.y
    }
  }

  onMouseUp = () => {
    this.canvas.selection = true
    this.isPanning = false
  }

  onDocumentMouseUp = () => {
    this.checkTextEditing()
  }

  onMouseMove = (event) => {
    this.handlePanning(event)
  }

  onMouseWheel = event => {
    if ((shourcutsManager.isMeta(event.e)) || (shourcutsManager.isCtrl(event.e))) {
      this.handleZoom(event)
    }
  }

  handleZoom = event => {
    const delta = event.e.deltaY
    delta > 0 ? this.root.zoomHandler.zoomOut(event.pointer) : this.root.zoomHandler.zoomIn(event.pointer)
    event.e.preventDefault()
    event.e.stopPropagation()
  }

  onKeyDown(event) {
    const activeElement = document.activeElement as HTMLElement | null;

    if (activeElement && (
      activeElement.tagName === 'INPUT' ||
      activeElement.tagName === 'TEXTAREA' ||
      activeElement.tagName === 'SELECT' ||
      activeElement.isContentEditable
    )) {
      // Skip logic if a form field or content-editable area is focused
      return;
    }
    if (this.root.canvasHandler.isOpenPixelManipulationObject) { return true }
    if (shourcutsManager.isCtrlZero(event)) {
      event.preventDefault()
      this.root.zoomHandler.zoomToFit()
      this.root.scrollbarHandler.updateScrollPosition()
    } else if (shourcutsManager.isCtrlMinus(event)) {
      event.preventDefault()
      this.root.zoomHandler.zoomOut()
    } else if (shourcutsManager.isCtrlEqual(event)) {
      event.preventDefault()
      this.root.zoomHandler.zoomIn()
    } else if (shourcutsManager.isCtrlOne(event)) {
      event.preventDefault()
      this.root.zoomHandler.zoomToOne()
    } else if (shourcutsManager.isCtrlZ(event)) {
      this.root.transactionHandler.undo()
    } else if (shourcutsManager.isCtrlShiftZ(event)) {
      this.root.transactionHandler.redo()
    } else if (shourcutsManager.isCtrlY(event)) {
      this.root.transactionHandler.redo()
    } else if (shourcutsManager.isAltLeft(event)) {
      event.preventDefault()
      this.root.objectsHandler.updateCharSpacing(-10)
    } else if (shourcutsManager.isAltRight(event)) {
      event.preventDefault()
      this.root.objectsHandler.updateCharSpacing(+10)
    } else if (shourcutsManager.isAltUp(event)) {
      event.preventDefault()
      this.root.objectsHandler.updateLineHeight(+0.1)
    } else if (shourcutsManager.isAltDown(event)) {
      event.preventDefault()
      this.root.objectsHandler.updateLineHeight(-0.1)
    } else if (shourcutsManager.isCtrlA(event)) {
      event.preventDefault()
      this.root.objectsHandler.selectAll()
    } else if (shourcutsManager.isDelete(event)) {
      const activeObject = this.root.canvasHandler.canvas.getActiveObject() as StaticText
      if (activeObject && activeObject.type === ObjectType.BAZAART_TEXT && activeObject.isEditing) {
        return true
      }
      event.preventDefault()
      this.root.objectsHandler.remove()
    } else if (shourcutsManager.isCtrlC(event)) {
      event.preventDefault()
      this.root.objectsHandler.copy()
    } else if (shourcutsManager.isCtrlV(event)) {
      if (this.root.objectsHandler.clipboard) {
        event.preventDefault()
      }
      this.root.objectsHandler.paste()
    } else if (shourcutsManager.isCtrlX(event)) {
      event.preventDefault()
      this.root.objectsHandler.cut()
    } else if (shourcutsManager.isCtrlD(event)) {
      event.preventDefault()
      this.root.objectsHandler.clone()
    } else if (shourcutsManager.isCtrlOpeningBrace(event)) {
      event.preventDefault()
      this.root.objectsHandler.bringForward()
    } else if (shourcutsManager.isCtrlClosingBrace(event)) {
      event.preventDefault()
      this.root.objectsHandler.sendBackwards()
    } else if (shourcutsManager.isCtrlAltOpeningBrace(event)) {
      event.preventDefault()
      this.root.objectsHandler.bringToFront()
    } else if (shourcutsManager.isCtrlAltClosingBrace(event)) {
      event.preventDefault()
      this.root.objectsHandler.sendToBack()
    } else if (shourcutsManager.isCtrlG(event)) {
      event.preventDefault()
      this.root.objectsHandler.group()
    } else if (shourcutsManager.isCtrlShiftG(event)) {
      event.preventDefault()
      this.root.objectsHandler.ungroup()
    } else if (shourcutsManager.isArrowUp(event)) {
      let nudgeValue = -1
      if (shourcutsManager.isShift(event)) {
        nudgeValue = -10
      }
      this.root.objectsHandler.moveVertical(nudgeValue)
    } else if (shourcutsManager.isArrowDown(event)) {
      let nudgeValue = 1
      if (shourcutsManager.isShift(event)) {
        nudgeValue = 10
      }
      this.root.objectsHandler.moveVertical(nudgeValue)
    } else if (shourcutsManager.isArrowLeft(event)) {
      let nudgeValue = -1
      if (shourcutsManager.isShift(event)) {
        nudgeValue = -10
      }
      this.root.objectsHandler.moveHorizontal(nudgeValue)
    } else if (shourcutsManager.isArrowRight(event)) {
      let nudgeValue = 1
      if (shourcutsManager.isShift(event)) {
        nudgeValue = 10
      }
      this.root.objectsHandler.moveHorizontal(nudgeValue)
    } else if (shourcutsManager.isSpace(event) && !this.isSpaceKeyPressed) {
      this.isSpaceKeyPressed = true
      const activeObject = this.canvas.getActiveObject()
      this.canvas.forEachObject((object: any) => {
        this.originalCursorStyles[object.id] = { selectable: object.selectable, evented: object.evented, hoverCursor: object.hoverCursor };
        object.hoverCursor = 'grab';
        object.selectable = false;
        object.evented = false;
      });
      if (activeObject) {
        this.canvas.discardActiveObject = function () {
          return this
        }
        activeObject.hasBorders = false;
        activeObject.setControlsVisibility({
          bl: false,
          br: false,
          tl: false,
          tr: false,
          mtr: false,
          mt: false,
          mb: false,
          mr: false,
          ml: false,
          // @ts-ignore
          moveText: false
        });
        const tooltipMenu = document.getElementById("tooltip-menu")
        tooltipMenu.style.opacity = '0';
      }
      this.canvas.setCursor('grab')
      this.canvas.defaultCursor = 'grab'
      this.canvas.renderAll()
    }
  }

  onKeyUp(event) {
    const activeElement = document.activeElement as HTMLElement | null;

    if (activeElement && (
      activeElement.tagName === 'INPUT' ||
      activeElement.tagName === 'TEXTAREA' ||
      activeElement.tagName === 'SELECT' ||
      activeElement.isContentEditable
    )) {
      // Skip logic if a form field or content-editable area is focused
      return;
    }
    if (this.root.canvasHandler.isOpenPixelManipulationObject) { return true }
    if (event.code === 'Space') {
      const activeObject = this.canvas.getActiveObject()
      if (activeObject) {
        const that = this
        this.canvas.discardActiveObject = function () {
          return that.originalDiscardActiveObjects()
        }
        activeObject.hasBorders = true;
        activeObject.setControlsVisibility({
          bl: true,
          br: true,
          tl: true,
          tr: true,
          mtr: true,
          mt: false,
          mb: false,
          mr: activeObject.type === ObjectType.BAZAART_TEXT,
          ml: activeObject.type === ObjectType.BAZAART_TEXT,
          // @ts-ignore
          moveText: activeObject.type === ObjectType.BAZAART_TEXT
        });
        const tooltipMenu = document.getElementById("tooltip-menu")
        tooltipMenu.style.opacity = '1';
      }
      this.isSpaceKeyPressed = false;
      this.isPanning = false
      this.canvas.defaultCursor = 'default'
      this.canvas.setCursor('default');
      this.canvas.forEachObject((object: any) => {
        object.hoverCursor = this.originalCursorStyles[object.id].hoverCursor;
        object.selectable = this.originalCursorStyles[object.id].selectable;
        object.evented = this.originalCursorStyles[object.id].evented;
      });
      this.originalCursorStyles = {}
      this.canvas.renderAll();
    }
  }

  onWindowMouseWheel = event => {
    const tourState = getTourState()
    // Prevent browser zoom when the tour is open
    if (tourState.isOpen && (event.ctrlKey || event.metaKey)) {
      event.preventDefault()
    }
  }

  handlePanning = (event) => {
    if (!this.isPanning) { return }
    this.canvas.setCursor('grabbing');
    var pointer = event.pointer
    let pointX = this.lastPointerX - pointer.x
    let pointY = this.lastPointerY - pointer.y

    this.root.scrollbarHandler.handleScrollX(pointX)
    this.root.scrollbarHandler.handleScrollY(pointY)
    this.root.scrollbarHandler.updateScrollPosition()
    this.root.frameHandler.updateLayersCoords()
    this.lastPointerX = pointer.x;
    this.lastPointerY = pointer.y;
  }

  onAfterRender = (event) => {
    const activeObject: any = this.canvas.getActiveObject();

    if (!activeObject || activeObject.type !== ObjectType.BAZAART_TEXT) {
      return;
    }

    this.currentActiveObjectId = activeObject.id;

    // Check if the active object ID is not already in the list
    if (this.textChangedActiveObjects.includes(activeObject.id)) { return }
    this.textChangedActiveObjects.push(activeObject.id);

    // Define the event handler function
    const onObjectChanged = (e) => {
      if (activeObject.id === this.currentActiveObjectId) {
        this.editorEventManager.emit('after:render');
      }
    };

    // Register the event handler
    activeObject.on('changed', onObjectChanged);
  }

  handleSelection = target => {
    const { personalizationHandler, transactionHandler } = this.root;
    if (target) {
      const selection = this.canvas.getActiveObject()
      if (selection != null || !transactionHandler.active) {
        this.context.setActiveObject(selection)
        this.root.personalizationHandler.updateDrawBorder(selection)
      }
    } else {
      this.context.setActiveObject(null)
    }
  }

  /**
   * This function handles the state of a StaticText object when a mouseup event occurs. 
   * It ensures that the object's editing state is preserved and re-applied without losing the current selection range.
   */
  checkTextEditing = () => {
    const activeObject = this.canvas.getActiveObject() as StaticText
    if(!activeObject) { return }
    if (activeObject.type === ObjectType.BAZAART_TEXT && activeObject.isEditing) {
      this.allowFireObjectChanging = false
      const textState = {
        selectionStart: activeObject.selectionStart,
        selectionEnd: activeObject.selectionEnd
      }
      activeObject.exitEditing()
      activeObject.enterEditing()
      activeObject.setSelectionStart(textState.selectionStart)
      activeObject.setSelectionEnd(textState.selectionEnd)
      this.canvas.requestRenderAll()
      this.allowFireObjectChanging = true
    }
  }
}

export default EventsHandler