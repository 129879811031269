import ImageJs from 'image-js'
import padWithOffset from './padWithOffset'
import extractBoundingBoxFromChannel from './extractBoundingBoxFromChannel'
import greyFromAlpha from './greyFromAlpha'
import alphaFromGrey from './alphaFromGrey'
import hasTransparency from './hasTransparency'

// @ts-ignore
ImageJs.extendMethod('padWithOffset', padWithOffset);
// @ts-ignore
ImageJs.extendMethod('extractBoundingBoxFromChannel', extractBoundingBoxFromChannel);
// @ts-ignore
ImageJs.extendMethod('greyFromAlpha', greyFromAlpha);
// @ts-ignore
ImageJs.extendMethod('alphaFromGrey', alphaFromGrey);
// @ts-ignore
ImageJs.extendMethod('hasTransparency', hasTransparency);
