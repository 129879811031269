import { useStyletron } from 'baseui'
import Icons from '@components/icons'
import useAppContext from '@/hooks/useAppContext'
import { useTranslation } from 'react-i18next'
import { lightTheme } from '@/customTheme'

function PanelListItem({ label, icon, activePanel, disable, panelSelected }: any) {
  const { setActivePanel, isReplacingImage, disableEditor } = useAppContext()
  const [css, theme] = useStyletron()
  const Icon = Icons[icon]

  const { t } = useTranslation()

  const color = panelSelected ? lightTheme.colors.blackGray : '#666'
  const isDisable = disable || (isReplacingImage && label !== 'Photos' && label !== 'Stock') || disableEditor
  return (
    <div
      onClick={() => {
        if (
          label !== 'Photos' &&
          label !== 'Graphics' &&
          label !== 'Text' &&
          label !== 'Templates' &&
          label !== 'Stock'
        ) {
          if (!disable) {
            // editor.deselect()
            setActivePanel(label === activePanel ? 'Background' : label)
          }
        }
      }}
      className={` ${css({
        boxSizing: 'border-box',
        color: color,
        fill: color,
        opacity: isDisable ? 0.5 : 1.0,
        pointerEvents: isDisable ? 'none' : 'auto',
        width: '64px',
        height: '64px',
        borderRadius: '8px',
        background: panelSelected || label === activePanel ? lightTheme.colors.grayScale100 : 'transparent',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        userSelect: 'none',
        transition: 'all 0.5s',
        gap: '4px',
        padding: '10px 0',

        ':hover':
          !disable || (isReplacingImage && label !== 'Photos') || disableEditor
            ? {
                cursor: 'pointer',
                background: lightTheme.colors.grayScale100,
                transition: 'all 1s',
                color: lightTheme.colors.blackGray,
                fill: lightTheme.colors.blackGray,
              }
            : {},
        ':active': {
          background: lightTheme.colors.grayScale150,
          color: lightTheme.colors.blackGray,
          fill: lightTheme.colors.blackGray,
        },
      })}`}
    >
      <Icon size={24} />
      <div
        className={css({
          fontWeight: 600,
          fontSize: '10px',
          letterSpacing: '0.01em',
          lineHeight: '16px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          width: '100%',
          textAlign: 'center',
          color: lightTheme.colors.blackGray,
        })}
      >
        {t(label)}
      </div>
    </div>
  )
}

export default PanelListItem
