import Transform from './components/Transform'
import Icons from '../../Icons'
import { useTranslation } from 'react-i18next'
import InspectorSectionTitle from '@/components/InspectorGrid/InspectorSectionTitle'
import InspectorGrid, { GridSize } from '@/components/InspectorGrid/InspectorGrid'
import InspectorGridButton from '@/components/InspectorGrid/InspectorGridButton'
import { useEditorContext } from '@/scenes/engine'
import { StaticImage } from 'fabric/fabric-impl'

function CropRatio() {
  const { t } = useTranslation()
    const { activeObject } = useEditorContext() as any

  const LIST_RATIO = [
    {
      id: 1,
      title: t('Freeform'),
      icon: Icons.RatioFreeform,
      aspectRatio: null
    },
    {
      id: 2,
      title: t('Square'),
      icon: Icons.RatioSquare,
      aspectRatio: 1
    },
    {
      id: 3,
      title: t('Landscape'),
      icon: Icons.RatioLandscape,
      aspectRatio: 16/9
    },
    {
      id: 4,
      title: t('Portrait'),
      icon: Icons.RatioPortrait,
      aspectRatio: 9/16
    },
  ]
  
  return (
      <InspectorGrid gridSize={GridSize.Grid3}>
        <InspectorSectionTitle title={t('Crop')} />
          { LIST_RATIO.map((ratioElement, index) => {
            return (
              <InspectorGridButton gridSize={GridSize.Grid3} key={ratioElement.id} title={ratioElement.title} onClick={()=>{console.log(ratioElement.title); (activeObject as unknown as StaticImage).setAspectRatio(ratioElement.aspectRatio)}}>
                <ratioElement.icon/>
              </InspectorGridButton>
            )})
          }
      </InspectorGrid>
  )
}

function Crop() {
  return (
      <>
        <CropRatio />
        <Transform />
      </>
  )
}

export default Crop
