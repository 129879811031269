import { LatestImageInfo } from "./latest_image_info"
import { Rectangle } from "./rectangle"
import { Size } from "./size"
import { MaskInfo } from './MaskInfo'
import { fabric } from 'fabric'

import ImageJs from 'image-js'
import { names as GreyAlgorithm } from 'image-js/src/image/transform/greyAlgorithms';

import '../../utils/ImageJsFilters'
import "../../utils/fabricjs-custom-filters/filter-flex-blend-image"
import { Point } from "./point"


export enum ResizeMode {
  aspectFit,
  aspectFill,
  exactSize
}

export interface ResizeOptions {
  allowUpsampling: boolean;
  exportType: string;
  pad: boolean,
  resizeMode: ResizeMode
}

export class MediaImageRepositoryProcessing {
  private static pixijsFilterBackend: any;
  private static webglFilterBackend: any;

  static defaultResizeOptions: ResizeOptions = {
    allowUpsampling: false,
    exportType: 'image/png',
    pad: false,
    resizeMode: ResizeMode.aspectFit
  }

  private static canvasElement: HTMLCanvasElement;
  private static canvas: fabric.Canvas;

  private maskThreshold = 10;

  public async extractBoundingBox(base64Image: string, isMask: boolean, threshold: number | null = null, abortSignal?: AbortSignal): Promise<Rectangle> {
    let image = await ImageJs.load(base64Image);
    // @ts-ignore
    let result = image.extractBoundingBoxFromChannel({
      shouldFlip: isMask,
      threshold: threshold != null ? threshold : this.maskThreshold,
      component: isMask ? 1 : 3,// red
      signal: abortSignal
    });
    let rectangle = new Rectangle(result.x, result.y, result.width, result.height)
    return rectangle
  }

  public async hasTransparency(base64Image: string): Promise<boolean> {
    let image = await ImageJs.load(base64Image)
    // let image = await ImageJs.createFrom(baseImage, {
    //   components: baseImage.alpha ? 4 : 3,
    //   alpha: baseImage.alpha
    // });

    // @ts-ignore
    let hasTransparency = image.hasTransparency()
    return hasTransparency
  }

  public async invertMask(base64Image: string): Promise<string>{
    let greyMask = await ImageJs.load(base64Image);
    let invertedMask = greyMask.invert()
    return invertedMask.toBase64();
  }

  public async extractGreyMaskFromColorImage(base64Image: string): Promise<HTMLImageElement> {
    let colorImage = await ImageJs.load(base64Image);
    let greyMask = colorImage.grey({ algorithm: GreyAlgorithm.AVERAGE, keepAlpha: false });
    let greyMaskBase64 = await greyMask.toBase64();
    let greyMaskDataUrl ='data:image/png;base64,' + greyMaskBase64;
    let greyHtmlMask = await this.loadImage(greyMaskDataUrl);
    return greyHtmlMask;
  }

  public async extractAlphaMask(base64Image: string): Promise<HTMLImageElement> {
    let greyMask = await ImageJs.load(base64Image);
    // @ts-ignore
    let alphaMask = greyMask.alphaFromGrey({});
    let alphaMaskBase64 = await alphaMask.toBase64();
    let alphaMaskDataUrl ='data:image/png;base64,' + alphaMaskBase64;
    let alphaHtmlMask = await this.loadImage(alphaMaskDataUrl);
    return alphaHtmlMask;
  }

  public async extractMask(base64Image: string, threshold: number | null = null, invert:boolean = false, abortSignal?: AbortSignal): Promise<MaskInfo> {
    let image = await ImageJs.load(base64Image);
    let croppedImage: ImageJs;
    try {
      // @ts-ignore
      let boundingBox = image.extractBoundingBoxFromChannel({
        shouldFlip: true,
        threshold: threshold != null ? threshold : this.maskThreshold,
        component: 3,
        signal: abortSignal
      });

      croppedImage = image.crop({
        x: boundingBox.left,
        y: boundingBox.top,
        width: boundingBox.width,
        height: boundingBox.height,
      });

    } catch {
      croppedImage = image;
    }
    let hasTransparency = croppedImage.width !== image.width || croppedImage.height !== image.height

    const alpha = image.getChannel(image.channels - 1);
    
    // @ts-ignore
    const mask = alpha.greyFromAlpha({ threshold: threshold != null ? threshold : this.maskThreshold, invert: invert, signal: abortSignal });
    if (abortSignal?.aborted) {
      return;
    }
    let invertedMask = mask.invert()

    let maskBase64 = await invertedMask.toBase64();
    let size = new Size(invertedMask.width, invertedMask.height);

    let blob = this.base64ToBlobUrl(maskBase64)
    
    let maskInfo = {
      blob: blob,
      base64: 'data:image/png;base64,' + maskBase64,
      size: size,
      hasTransparency: hasTransparency
    };
    return maskInfo;
  }

  // some images are either jpeg or premultiplied and there's no specific alpha channel. 
  // if that's the case we add it.
  async normalizePngImage(originalBase64: string, abortSignal?: AbortSignal){
    // let original = await ImageJs.load(originalBase64);
    // if (original.channels == 3)  { // some images are either jpeg or premultiplied and there's no specific alpha channel. 
    //   // if that's the case we add it.
    //   original = original.rgba8();  
    // }
    // let resultBase64 = 'data:image/png;base64,' + original.toBase64();
    // let blob = this.base64ToBlobUrl(resultBase64)
    // return blob;
    return originalBase64;
  }

  async generateLatestImageInfo(originalBase64: string, 
                                maskBase64: string,
                                threshold: number | null = null,
                                absolueBoundingBox?: Rectangle
                              ): Promise<LatestImageInfo> {
    let original = await ImageJs.load(originalBase64);
    let rgbMask = await ImageJs.load(maskBase64);

    let mask = rgbMask.getChannel(rgbMask.channels - 1); // apparently alpha is always the last channel
    // let invertedMask = mask.invert()
    let aspectFitMask = await this.aspectFitMaskToImage(original, mask);

    // @ts-ignore
    let boundingBox = absolueBoundingBox ?? aspectFitMask.extractBoundingBoxFromChannel({
      shouldFlip: true,
      threshold: threshold != null ? threshold : this.maskThreshold, //255 - (threshold != null ? threshold : this.maskThreshold),
      component: 0 // red
    });

    let fittedImage = original.crop(boundingBox)
    let fittedMask = aspectFitMask.crop(boundingBox);
    
    let latestImage = fittedImage.clone()
    latestImage.setChannel(3, fittedMask.invert())

    let relativeBoundingBox = new Rectangle(
      boundingBox.x / aspectFitMask.width,
      boundingBox.y / aspectFitMask.height,
      boundingBox.width / aspectFitMask.width,
      boundingBox.height / aspectFitMask.height
    );

    let result = new LatestImageInfo(
      latestImage,
      fittedImage,
      aspectFitMask,
      fittedMask,
      relativeBoundingBox
    );

    return result;
}

async resizeMaxEdgeSizeToCanvas(image: string, edge: number, resizeOptions: ResizeOptions = MediaImageRepositoryProcessing.defaultResizeOptions): Promise<HTMLCanvasElement> {
  let size = new Size(edge, edge);
  let result = await this.resizeSizeToCanvas(image, size, resizeOptions);
  return result;
}

async resizeSizeToCanvas(image: string, size: Size, resizeOptions: ResizeOptions = MediaImageRepositoryProcessing.defaultResizeOptions): Promise<HTMLCanvasElement> {
  let htmlImg = await this.loadImage(image);
  if (!resizeOptions.allowUpsampling && Math.max(htmlImg.width, htmlImg.height) < size.maxEdge()) {
    let targetSize = new Size(htmlImg.width, htmlImg.height);
    let canvas = await this.createCanvas(htmlImg, targetSize);
    return canvas;
  }

  let ratio = htmlImg.width / htmlImg.height;

  let width;
  let height;

  
  if (resizeOptions.resizeMode === ResizeMode.exactSize) {
    width = size.width;
    height= size.height;
  } else if (resizeOptions.resizeMode === ResizeMode.aspectFit) {
    // Fit image within bounds without cropping
    if (htmlImg.width > htmlImg.height) {
      width = size.width;
      height = width / ratio;
    } else {
      height = size.height;
      width = height * ratio;
    }
  } else if (resizeOptions.resizeMode === ResizeMode.aspectFill) {
      // Fill image to cover the target square canvas size without squashing
      let scale = Math.max(size.width / htmlImg.width, size.height / htmlImg.height);
      width = htmlImg.width * scale;
      height = htmlImg.height * scale;
  }

  let scaleX = width / htmlImg.width;
  let scaleY = height / htmlImg.height;
  let resizedHtmlImg = await this.resize(htmlImg, scaleX, scaleY, resizeOptions.pad);
  
  // Center the image on the square canvas if using aspect fill
  let offsetX = resizeOptions.resizeMode === ResizeMode.aspectFill ? Math.max(0, (width - size.width)) / 2 : 0;
  let offsetY = resizeOptions.resizeMode === ResizeMode.aspectFill ? Math.max(0, (height - size.height)) / 2 : 0;
  let offset = new Point(offsetX, offsetY);

  let targetSize = resizeOptions.resizeMode === ResizeMode.aspectFit ? new Size(resizedHtmlImg.width, resizedHtmlImg.height) : size;
  let canvas = await this.createCanvas(resizedHtmlImg, targetSize, null, null, offset);
  return canvas;
}

async resizeBlob(image: string, size: Size, signal?: AbortSignal, resizeOptions: ResizeOptions = MediaImageRepositoryProcessing.defaultResizeOptions): Promise<string> {
  let canvas = await this.resizeSizeToCanvas(image, size, resizeOptions)
  if (signal?.aborted) {return null;}
  const blobUrl = await this.canvasToBlobUrl(canvas, resizeOptions.exportType);
  return blobUrl;
}


  async resizeBlobToMaxEdgeSize(image: string, edge: number, signal?: AbortSignal, resizeOptions: ResizeOptions = MediaImageRepositoryProcessing.defaultResizeOptions): Promise<string> {
    let size = new Size(edge, edge);
    let result = await this.resizeBlob(image, size, signal, resizeOptions);
    return result;
  }

  public async canvasToBlobUrl(canvas: HTMLCanvasElement, format: string = 'image/png'): Promise<string> {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const blobUrl = URL.createObjectURL(blob);
          resolve(blobUrl);
        } else {
          reject(new Error('Canvas to blob conversion failed'));
        }
      }, format);
    });
  }

  async flipImage(image: HTMLImageElement, flipX: boolean, flipY: boolean): Promise<HTMLImageElement>{
    if (!flipX && !flipY){
      return Promise.resolve(image);
    }
    let canvas = this.createCanvas(image, new Size(image.width, image.height))
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save(); // Save the current context state
    
    ctx.translate(
      flipX ? canvas.width : 0, 
      flipY ? canvas.height : 0, 
    ); // Move to the right edge of the canvas

    ctx.scale(
      flipX ? -1 : 1,
      flipY ? -1 : 1,
    ); 

    ctx.drawImage(
      image,
      0,
      0,
      canvas.width,
      canvas.height,
      0,
      0,
      canvas.width,
      canvas.height
    )
    ctx.restore(); // Restore the context to its original state
    let flippedImage = await this.loadImage(canvas.toDataURL());
    return flippedImage
  }


  public base64ToBlobUrl(base64:string): string {
    return URL.createObjectURL(this.dataURIToBlob(base64))
  }

  public blobUrlToBase64(blobUrl:string | URL): Promise<string> {
    return fetch(blobUrl)
    .then(response => response.blob()) // Fetch the blob from the blob URL
    .then(blob => {
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result as string;
          resolve(base64data.split(',')[1]); // Remove the 'data:' prefix and get base64 content
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(blob); // Convert Blob to Base64 string
      });
    }).catch((error) => {
      console.error(`error ${error}`)
      return null;
    });
  }

  async createColorImage(color: string, width: number, height: number): Promise<string> {
    // Create a new canvas element
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    // Get the drawing context
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        throw new Error('Unable to get canvas context');
    }

    // Set the fill color and create a rectangle
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, width, height);

    const blobUrl = await this.canvasToBlobUrl(canvas);
    return blobUrl
  }
  async resizeBase64ToMaxEdgeSize(image: string, edge: number, resizeOptions: ResizeOptions = MediaImageRepositoryProcessing.defaultResizeOptions){
    let canvas = await this.resizeMaxEdgeSizeToCanvas(image, edge, resizeOptions)
    let retBase64Image = canvas.toDataURL(resizeOptions.exportType);
    return retBase64Image;
  }

  async resizeToEdge(htmlImg: HTMLImageElement, edge: number, noUpscaling: boolean = true): Promise<HTMLImageElement> {
    if (noUpscaling && (Math.max(htmlImg.width, htmlImg.height) < edge)) {
      return htmlImg;
    }

    let ratio = htmlImg.width / htmlImg.height;
    let width;
    let height;

    if (htmlImg.width > htmlImg.height) {
      width = edge;
      height = width / ratio;
    } else {
      height = edge;
      width = height * ratio;
    }
    let resizedHtmlImg = await this.resize(htmlImg, width / htmlImg.width, height / htmlImg.height);
    return resizedHtmlImg
  }

  async resizeBase64(image: string, ratioX: number, ratioY: number): Promise<string> {
    let htmlImg = await this.loadImage(image);
    let resizedHtmlImg = await this.resize(htmlImg, ratioX, ratioY);
    let targetSize = new Size(resizedHtmlImg.width, resizedHtmlImg.height);
    let canvas = await this.createCanvas(resizedHtmlImg, targetSize);
    let retBase64Image = canvas.toDataURL();
    return retBase64Image;
  }

  async resize(image: HTMLImageElement, ratioX: number, ratioY: number, shouldPad: boolean = false): Promise<HTMLImageElement> {
    const fbImage = new fabric.Image(image, {
      centeredScaling: false,
      scaleX: ratioX,
      scaleY: ratioY,
      width: image.width,
      height: image.height
    });

    let canvasElement = document.createElement("canvas");
    if (shouldPad){
      let maxEdge = Math.max(image.width * ratioX, image.height * ratioY);
      canvasElement.width = maxEdge;
      canvasElement.height = maxEdge;
      fbImage.originX = 'center';
      fbImage.originY = 'center';
      fbImage.top = maxEdge / 2;
      fbImage.left = maxEdge / 2;
    } else {
      canvasElement.width = image.width * ratioX;
      canvasElement.height = image.height * ratioY;
    }

    const canvas = new fabric.Canvas(canvasElement, {
      imageSmoothingEnabled: true,
      enableRetinaScaling: true
    });

    canvas.add(fbImage);
    canvas.renderAll();

    let resizedBase64Image = canvas.toDataURL();
    let resizedImage = await this.loadImage(resizedBase64Image);
    return resizedImage;
  }

  async applyMaskToImage(image: HTMLImageElement, mask: HTMLImageElement, flip: boolean = true): Promise<string> {
    const fbImage = new fabric.Image(image);
    fbImage.dirty = true;
    fbImage.objectCaching = false;

    const fbMask = new fabric.Image(mask);
    fbMask.dirty = true;
    fbMask.objectCaching = false;

    const execute = () => {
      // @ts-ignore
      let maskFilter = new fabric.Image.filters.FlexBlendImage();
      maskFilter.image = fbMask;
      maskFilter.mode = 'mask';
      maskFilter.shouldFlip = flip;
      maskFilter.transparentMask = false;

      MediaImageRepositoryProcessing.canvasElement.width = fbImage.width;
      MediaImageRepositoryProcessing.canvasElement.height = fbImage.height;
      MediaImageRepositoryProcessing.canvas.width = fbImage.width;
      MediaImageRepositoryProcessing.canvas.height = fbImage.height;

      fbImage.filters.push(maskFilter);
    }

    let maskedImage = this.applyFabricJsFilter(fbImage, execute)
    return maskedImage;
  }

  async cropHtmlImage(image: HTMLImageElement, boundingBox: Rectangle): Promise<HTMLImageElement> {
    const img = new Image();
    img.crossOrigin = 'Anonymous'
    let that = this;
    let p = new Promise<HTMLImageElement>(async function (resolve, reject) {
      img.onload = () => {
        resolve(img);
      }
      img.src = await that.doCropHtmlImage(image, boundingBox);
    });
    return p;
  }

  async padImage(image: HTMLImageElement, targetSize: Size, rect: Rectangle, bgColor: string = null): Promise<HTMLImageElement> {
    let padding = new Rectangle(
      rect.x,
      rect.y,
      rect.width,
      rect.height
    );

    let canvas = await this.createCanvas(image, targetSize, padding, bgColor);
    let paddedImageInBase64 = canvas.toDataURL();
    let paddedImage = await this.loadImage(paddedImageInBase64);
    paddedImage.width = targetSize.width;
    paddedImage.height = targetSize.height;
    return paddedImage;
  }

  async composite(bottomImage: HTMLImageElement, topImage: HTMLImageElement, origin: Point): Promise<HTMLImageElement> {
    let targetSize = new Size(bottomImage.width, bottomImage.height);

    const canvas = document.createElement('canvas');
    canvas.width = targetSize.width;
    canvas.height = targetSize.height;

    let ctx = canvas.getContext("2d");
    // Draw the first image onto the canvas
    ctx.drawImage(bottomImage, 0, 0);

    // Set the global composite operation for how the second image should be blended with the first
    ctx.globalCompositeOperation = 'source-over';  // Change 'source-over' to another composite operation if desired

    // Draw the second image onto the canvas
    ctx.drawImage(topImage, origin.x, origin.y);

    let paddedImageInBase64 = canvas.toDataURL();
    let paddedImage = await this.loadImage(paddedImageInBase64);
    paddedImage.width = targetSize.width;
    paddedImage.height = targetSize.height;

    return paddedImage;
  }

  async preprocessForBazaartBg(inputImage: string): Promise<string> {
    let minEdge = 512;
    let maxEdge = 800;
    let imageSize = await this.getImageSize(inputImage);
    let aspectRatio = imageSize.width / imageSize.height;
    let htmlImage = await this.loadImage(inputImage);

    let retHtmlImage: HTMLImageElement;
    let targetSize = new Size(0, 0);
    if (aspectRatio < 1.25 && aspectRatio > (1.0 / 1.25)) {
      targetSize = new Size(minEdge, minEdge);
      retHtmlImage = await this.resize(htmlImage, minEdge / imageSize.width, minEdge / imageSize.height);
    } else if (aspectRatio < 1) {
      targetSize = new Size(minEdge, maxEdge);
      retHtmlImage = await this.resize(htmlImage, minEdge / imageSize.width, maxEdge / imageSize.height);
    } else {
      targetSize = new Size(maxEdge, minEdge);
      retHtmlImage = await this.resize(htmlImage, maxEdge / imageSize.width, minEdge / imageSize.height);
    }

    let canvas = await this.createCanvas(retHtmlImage, targetSize);
    let retBase64Image = canvas.toDataURL();
    return retBase64Image;
  }

  public async trim(base64Image: string, isMask: boolean = false, threshold: number | null = null): Promise<string> {
    // Extract the bounding box of the relevant content in the image
    let boundingBox = await this.extractBoundingBox(base64Image, isMask, threshold);
  
    // Load the image
    let image = await this.loadImage(base64Image);
  
    // Crop the image based on the bounding box
    let croppedImage = await this.cropHtmlImage(image, boundingBox);
  
    // Convert the cropped image back to base64
    let canvas = document.createElement('canvas');
    canvas.width = croppedImage.width;
    canvas.height = croppedImage.height;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(croppedImage, 0, 0);
  
    // Return the new base64 image
    return canvas.toDataURL();
  }

  private base64ToUint8Array(base64) {
    // Decode the base64 string to a binary string
    var binaryString = window.atob(base64);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);

    // Convert the binary string to a type array Uint8Array
    for (var i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  public dataURIToBlob(dataURI: string) {
    let array, binary, i, len
    if (dataURI.indexOf(',') > 0) {
      binary = atob(dataURI.split(',')[1])
    } else {
      binary = atob(dataURI)
    }

    array = []
    i = 0
    len = binary.length
    while (i < len) {
      array.push(binary.charCodeAt(i))
      i++
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg',
    })
  }

  public async imageElmToBlob(image: HTMLImageElement, isPNG: boolean = false, clearBackground: boolean = true): Promise<string> {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;

    const ctx = canvas.getContext('2d');
    if (!clearBackground) {
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    if (!ctx) {
      throw new Error('Unable to get canvas rendering context');
    }

    // Draw the original image onto the canvas
    ctx.drawImage(image, 0, 0);

    if (isPNG) {
      return await this.canvasToBlobUrl(canvas, 'image/png')
    } else {
      return await this.canvasToBlobUrl(canvas, 'image/jpeg')
    }

  }

  async postProcessFromBazaartBg(inputImage: string, ratioX: number, ratioY: number): Promise<string> {
    // const base64InputImage = 'data:image/png;base64,' + inputImage
    let buffer = this.base64ToUint8Array(inputImage);
    let rgbaImage = await ImageJs.load(buffer);
    let image = rgbaImage.getChannel(0);
    let resizedImage = image
      .resize({
        width: image.width * ratioX,
        height: image.height * ratioY,
      });
    //  let blob = await resizedImage.toBlob();
    let base64OutputImage = await resizedImage.toBase64();
    let blob = this.base64ToBlobUrl(base64OutputImage)
    return blob;
    //  let base64OutputImage = resizedImage.toBase64();
    //  return `data:image/png;base64,` + base64OutputImage;
  }

  async getImageSize(url: string): Promise<Size> {
    const img = new Image();
    img.crossOrigin = 'Anonymous'
    let p = new Promise<Size>(function (resolve, reject) {
      img.onload = () => {
        let size = new Size(img.width, img.height);
        resolve(size);
      }
      img.src = url;
    });
    return p;
  }

  async loadImage(base64Image: string): Promise<HTMLImageElement> {
    const img = new Image();
    img.crossOrigin = 'Anonymous'
    let p = new Promise<HTMLImageElement>(function (resolve, reject) {
      img.onload = () => {
        resolve(img);
      }
      img.onerror = (event, err) => {
        reject(err);
      }
      img.src = base64Image;
    });
    return p;
  }

  private async aspectFitMaskToImage(image: ImageJs, mask: ImageJs): Promise<ImageJs> {
    let maskAR = mask.width / mask.height;
    let imageAR = image.width / image.height;
    let stretchRatio = 1.0;
    if (imageAR > maskAR) {
      stretchRatio = image.height / mask.height;
    } else {
      stretchRatio = image.width / mask.width;
    }
    let resizedMask = mask.resize({
      factor: stretchRatio
    })
    let aspectFitMask = resizedMask.pad({
      // @ts-ignore
      size: [Math.floor((image.width - resizedMask.width) / 2), Math.floor((image.height - resizedMask.height) / 2)],
      algorithm: 'set',
      color: [255]
    })
    return aspectFitMask
  }

  public async applyImageJsMaskToImage(image: ImageJs, mask: ImageJs): Promise<ImageJs> {
    let base64Image = await this.loadImage(image.toDataURL())
    let base64Mask = await this.loadImage(mask.toDataURL())
    let base64Masked = await this.applyMaskToImage(base64Image, base64Mask)
    let masked = await ImageJs.load(base64Masked);
    return masked
  }

  private async doCropHtmlImage(image: HTMLImageElement, boundingBox: Rectangle): Promise<string> {
    let targetSize = new Size(boundingBox.width, boundingBox.height);
    let targetCanvas = await this.createCanvas(image, targetSize, null);
    let targetCtx = targetCanvas.getContext("2d");
    targetCtx.clearRect(0, 0, targetSize.width, targetSize.height);

    targetCtx.drawImage(image,
      boundingBox.x, boundingBox.y,   // Start at 70/20 pixels from the left and the top of the image (crop),
      boundingBox.width, boundingBox.height,   // "Get" a `50 * 50` (w * h) area from the source image (crop),
      0, 0,     // Place the result at 0, 0 in the canvas,
      boundingBox.width, boundingBox.height // With as width / height: 100 * 100 (scale)
    );

    // const dataUrl = targetCanvas.toDataURL();
    // return dataUrl;

    const blobUrl = await this.canvasToBlobUrl(targetCanvas);
    return blobUrl;
  }

  private createCanvas(img: HTMLImageElement, size: Size, padding?: Rectangle, bgColor?: string, offset: Point = Point.zero()): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    canvas.width = size.width;
    canvas.height = size.height;
    let ctx = canvas.getContext("2d");

    if (bgColor) {
      ctx.fillStyle = bgColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    if (padding) {
      ctx.drawImage(img, padding.x + offset.x, padding.y + offset.y, padding.width, padding.height);
    } else {
      ctx.drawImage(img, offset.x, offset.y, size.width, size.height, 0, 0, size.width, size.height);
    }

    return canvas;
  }

  private applyFabricJsFilter(fbImage: any, executeFilter: () => void): string {
    MediaImageRepositoryProcessing.pixijsFilterBackend = fabric.filterBackend;
    if (MediaImageRepositoryProcessing.webglFilterBackend && MediaImageRepositoryProcessing.webglFilterBackend.gl.drawingBufferHeight != 0 && MediaImageRepositoryProcessing.webglFilterBackend.gl.drawingBufferWidth != 0) {
      fabric.filterBackend = MediaImageRepositoryProcessing.webglFilterBackend;
    } else {
      let prevGL = MediaImageRepositoryProcessing.webglFilterBackend?.gl;
      // @ts-ignore
      MediaImageRepositoryProcessing.webglFilterBackend = fabric.initFilterBackend();
      fabric.filterBackend = MediaImageRepositoryProcessing.webglFilterBackend;

      if (!prevGL) {
        MediaImageRepositoryProcessing.canvasElement = document.createElement("canvas");
        MediaImageRepositoryProcessing.canvas = new fabric.Canvas(MediaImageRepositoryProcessing.canvasElement);
      }
    }

    MediaImageRepositoryProcessing.canvasElement.addEventListener('webglcontextlost', function (event) {
      event.preventDefault();
      console.log('webglcontextlost');
    }, false);

    MediaImageRepositoryProcessing.canvasElement.addEventListener('webglcontextrestored', function (event) {
      console.log('webglcontextrestored');
    }, false);

    executeFilter()

    MediaImageRepositoryProcessing.webglFilterBackend = fabric.filterBackend;

    MediaImageRepositoryProcessing.canvas.clear();
    MediaImageRepositoryProcessing.canvas.add(fbImage);
    fbImage.applyFilters();
    MediaImageRepositoryProcessing.canvas.renderAll();

    let filteredBase64 = MediaImageRepositoryProcessing.canvas.toDataURL();

    fabric.filterBackend = MediaImageRepositoryProcessing.pixijsFilterBackend;
    return filteredBase64
  }
}