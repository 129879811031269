export enum MediaImageType {
  original,   // 0
  fullRes,    // 1
  fitted,     // 2 trimmed, resized & flipped image
  latest,     // 3
  thumbnail,  // 4
  mask,       // 5
  fittedMask, // 6 trimmed, resized & flipped mask
  depthMask,  // 7
  alphaMatteMask,  // 8
  originalMask, // 9
  removeBgMask, //10
  bzrtBgMask, //11
  appleBgMask, //12
  localModelBgMask, //13
  mattedForeground, //14
  maskWithoutCrop, //15
}

export const FilesOfMediaImageTypes = [
  "original",
  "full_res",
  "fitted",
  "latest",
  "thumbnail",
  "mask",
  "fitted_mask",
  "depth_mask",
  "alphaMatteMask",
  "originalMask",
  "removeBgMask",
  "bzrtBgMask",
  "appleBgMask",
  "localModelBgMask",
  "mattedForeground",
  "maskWithoutCrop"
];


export function convertFileNameToMediaImageType(fileName: string): MediaImageType | undefined {
  const index = FilesOfMediaImageTypes.indexOf(fileName);
  return index !== -1 ? index as MediaImageType : undefined;
}

/**
 * Converts a MediaImageType enum value to the corresponding file name in FilesOfMediaImageTypes.
 * @param type The MediaImageType to convert.
 * @returns The corresponding file name or undefined if no match is found.
 */
export function convertMediaImageTypeToFileName(type: MediaImageType): string | undefined {
  return FilesOfMediaImageTypes[type];
}


export const allMediaImageTypeValues = Object.keys(MediaImageType)
  .filter(key => isNaN(Number(key))) // Filter out the numeric keys
  .map(key => MediaImageType[key as keyof typeof MediaImageType]);
