import { useEffect, useState } from 'react'
import SliderCustom from '@/components/SliderCustom'
import { useEditorContext } from '@/scenes/engine'
import Icons from '../../../Icons'
import { customAmplitude } from '@/utils/customAmplitude'

import { useTranslation } from 'react-i18next'
import { StaticText } from 'fabric/fabric-impl'


const ITEMS = [30, 40, 48, 72, 80, 96, 120, 140, 180, 220]

function TextFontSize() {
  const activeObject = useEditorContext().activeObject as StaticText

  const [fontSize, setFontSize] = useState(6)
  const [preFontSize, setPreFontSize] = useState(6)
  const [activeObjectState, setActiveObjectState] = useState({
    selectionStart: 0,
    selectionEnd: 0,
    isTextEditing: false,
  })

  const editor = useEditorContext().editor

  useEffect(() => {
    if (activeObject) {
      setFontSize(Math.round(activeObject.fontSize))
      setPreFontSize(Math.round(activeObject.fontSize))
    }
  }, [activeObject])

  const updateFontSize = (value: number, finalValue = false): void => {
    if (activeObject.isEditing) {
      setActiveObjectState({
        selectionStart: activeObject.selectionStart,
        selectionEnd: activeObject.selectionEnd,
        isTextEditing: activeObject.isEditing,
      })
      activeObject.exitEditing()
    }
    const sliderOptions = finalValue
      ? { isSliderUpdate: finalValue, property: ['fontSize'], value: [preFontSize] }
      : { isSliderUpdate: finalValue }

    if (finalValue) {
      if(activeObjectState.isTextEditing) {
        activeObject.enterEditing()
        activeObject.setSelectionStart(activeObjectState.selectionStart)
        activeObject.setSelectionEnd(activeObjectState.selectionEnd)
      }
      setPreFontSize(value)
    }

    editor.handlers.objectsHandler.updateActive({ fontSize: value }, undefined, sliderOptions)
    editor.handlers.objectsHandler.handleTextSizeOnChanged(activeObject)
  }
  const { t } = useTranslation()

  return (
    <div style={{ width: '292px' }} className='mt-50'>
      <SliderCustom
        icon={<Icons.AA />}
        name={t("Size")}
        listValues={ITEMS}
        minValue={6}
        maxValue={Math.max(...ITEMS)}
        onChangeValue={value => {
          setFontSize(value)
          updateFontSize(value)
        }}
        onFinalChangeValue={value => {
          console.log('Final change', value)
          const eventProperties = {
            Tool: 'bazaart.style.fontSize',
            'Layer Type': activeObject.type,
          }
          customAmplitude('Selected tool', eventProperties)
          updateFontSize(value, true)
        }}
        value={Math.round(fontSize)}
      />
    </div>
  )
}

export default TextFontSize
