import Loading from './components/Loading'
import { colors } from '@/assets/colors'
import ColorButton from '@/components/ColorButton'
import { lightTheme } from './customTheme'
import { useTranslation } from 'react-i18next'
interface props {
  style?: React.CSSProperties
  onItemSelected: Function
  colorSelected: string
}

function ColorsPackContainer({ style, onItemSelected, colorSelected }: props) {
  const handleColorChange = (color: string) => {
    if (color !== colorSelected) {
      onItemSelected(color)
    }
  }
  const { t } = useTranslation()

  return (
    <div style={{ ...style }} className='mt-50'>
      <div
        style={{
          width: '100%',
          height: '26px',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '8px',
        }}
      >
        <span style={{ ...lightTheme.typography.Small14Semibold, color:lightTheme.colors.text.text_black_title }}>{t('Colors')}</span>
      </div>

      {colors && colors.length > 0 ? (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', width: '100%' }}>
          {colors.map((c, index) => {
            if (index < 11) {
              return (
                <div style={{ display: 'flex' }} key={index}>
                  <ColorButton
                    handleOnClick={handleColorChange}
                    color={c.color}
                    isActive={colorSelected === c.color}
                    isColorPicker={false}
                  />
                </div>
              )
            } else if (index === 11) {
              return (
                <div style={{ display: 'flex' }} key={index}>
                  <ColorButton
                    handleOnClick={handleColorChange}
                    color={c.color}
                    isActive={colorSelected === c.color}
                    isColorPicker={true}
                    colorSelected={colorSelected}
                  />
                </div>
              )
            }
            return null
          })}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default ColorsPackContainer
