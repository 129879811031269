import Background from './Background'
import Templates from './Templates'
import Text from './Text'
import Graphics from './Graphics'
import FontFamily from './FontFamily'
import Color from './Color'
import Images from './Images'
import Vectors from './Vectors'
import Pixabay from './Pixabay'
import Uploads from './Uploads'
import Overlay from './Overlay'
import Toolbox from '../../Toolbox'
import Stock from './Stock'

class PanelItems {
  static Background = Background
  // static Text = Text
  static Templates = Templates
  static Graphics = Graphics
  static FontFamily = FontFamily
  static Color = Color
  static Images = Images
  static Vectors = Vectors
  static Pixabay = Pixabay
  static Uploads = Uploads
  static Overlay = Overlay
  static Toolbox = Toolbox
  static Stock = Stock
}

export default PanelItems
