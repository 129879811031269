// @ts-nocheck
import { fabric } from 'fabric'
import { HandlerOptions } from '../common/interfaces'
import BaseHandler from './BaseHandler'
import {
  drawCircleIcon,
  drawVerticalLineIcon,
  drawHorizontalLineIcon,
  drawRotateIcon,
  mouseRotateIcon,
  drawCircleIconHover,
  drawRotateIconHover,
  drawVerticalLineIconHover,
  drawMoveTextIcon,
  drawMoveTextIconHover,
} from '../utils/drawer'
import { NAVBAR_HEIGHT, ObjectType } from '../common/constants'
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@/scenes/engine/objects/media-repository/media_image_type'
import store from '@/store/store'
import { setHasClipboardImage } from '@/store/slices/editor/action'
import { nanoid } from 'nanoid'
import { Size } from '../objects/media-repository/size'
import { CanvasLayerOutlineEffect } from '@/interfaces/CanvasLayerOutlineEffect'
import { CanvasLayerShadowEffect } from '@/interfaces/CanvasLayerShadowEffect'
import { Rectangle } from '../objects/media-repository/rectangle'
import { Inset } from '../objects/media-repository/inset'
import { Point } from '../objects/media-repository/point'


class PersonalizationHandler extends BaseHandler {
  private cornerPre
  private isChanging
  private isMoving
  private endAnimation = false
  private isRenderHover = false
  private isClickRotate = false
  private isRotating = false
  private isDraggingText = false
  private currentScalingCorner = ''
  originalCanvasSize = new Size(0,0);
  
  public readonly MTR_CONTROL_OFFSET = 37.5
  constructor(props: HandlerOptions) {
    super(props)
    this.init(this.root)
    this.cornerPre = null
    this.isChanging = false
    this.isMoving = false
  }

  init(root) {
    fabric.Object.prototype.transparentCorners = false
    fabric.Object.prototype.cornerColor = '#20bf6b'
    fabric.Object.prototype.cornerStyle = 'circle'
    fabric.Object.prototype.borderColor = '#FF0560'
    fabric.Object.prototype.cornerSize = 12
    fabric.Object.prototype.borderScaleFactor = 1.5
    fabric.Object.prototype.borderOpacityWhenMoving = 1
    fabric.Object.prototype.borderOpacity = 1
    fabric.Object.prototype.shadowBlur = 4
    fabric.Object.prototype.shadowColor = 'rgba(0, 0, 0, 0.25)'

    fabric.Object.prototype.controls.tr = new fabric.Control({
      x: 0.5,
      y: -0.5,
      actionHandler: fabric.controlsUtils.scalingEqually,
      // cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
      cursorStyle: 'nesw-resize',
      actionName: fabric.controlsUtils.scaleOrSkewActionName,
      render: drawCircleIcon,
      cornerSize: 28,
      withConnection: true,
    })

    fabric.Object.prototype.controls.tl = new fabric.Control({
      x: -0.5,
      y: -0.5,
      actionHandler: fabric.controlsUtils.scalingEqually,
      // cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
      cursorStyle: 'nwse-resize',
      actionName: fabric.controlsUtils.scaleOrSkewActionName,
      render: drawCircleIcon,
      cornerSize: 28,
      withConnection: true,
    })

    fabric.Object.prototype.controls.bl = new fabric.Control({
      x: -0.5,
      y: 0.5,
      actionHandler: fabric.controlsUtils.scalingEqually,
      // cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
      cursorStyle: 'nesw-resize',
      actionName: fabric.controlsUtils.scaleOrSkewActionName,
      render: drawCircleIcon,
      cornerSize: 28,
      withConnection: true,
    })

    fabric.Object.prototype.controls.br = new fabric.Control({
      x: 0.5,
      y: 0.5,
      actionHandler: fabric.controlsUtils.scalingEqually,
      cursorStyle: 'nwse-resize',
      actionName: fabric.controlsUtils.scaleOrSkewActionName,
      render: drawCircleIcon,
      cornerSize: 28,
      withConnection: true,
    })

    fabric.Object.prototype.controls.ml = new fabric.Control({
      x: -0.5,
      y: 0,
      offsetX: -1,
      // cursorStyle: `url("data:image/svg+xml;charset=utf-8,${iconResizeHorizontal}") 12 12, crosshair`,
      cursorStyle: 'ew-resize',
      actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
      actionName: fabric.controlsUtils.scaleOrSkewActionName,
      render: drawVerticalLineIcon,
      cornerSize: 18,
      withConnection: true,
    })

    fabric.Object.prototype.controls.mt = new fabric.Control({
      x: 0,
      y: -0.5,
      offsetY: -1,
      // cursorStyle: `url("data:image/svg+xml;charset=utf-8,${iconResizeVertical}") 12 12, crosshair`,
      cursorStyle: 'ns-resize',
      actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
      actionName: fabric.controlsUtils.scaleOrSkewActionName,
      render: drawHorizontalLineIcon,
      cornerSize: 18,
      withConnection: true,
    })

    fabric.Object.prototype.controls.mb = new fabric.Control({
      x: 0,
      y: 0.5,
      offsetY: 2,
      // cursorStyle: `url("data:image/svg+xml;charset=utf-8,${iconResizeVertical}") 12 12, crosshair`,
      cursorStyle: 'ns-resize',
      actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
      actionName: fabric.controlsUtils.scaleOrSkewActionName,
      render: drawHorizontalLineIcon,
      cornerSize: 18,
      withConnection: true,
    })

    fabric.Object.prototype.controls.mr = new fabric.Control({
      x: 0.5,
      y: 0,
      offsetX: 0.5,
      cursorStyle: 'ew-resize',
      actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
      actionName: fabric.controlsUtils.scaleOrSkewActionName,
      render: drawVerticalLineIcon,
      cornerSize: 18,
      withConnection: true,
    })

    fabric.Object.prototype.controls.mtr = new fabric.Control({
      x: 0,
      y: 0.5,
      offsetY: 37.5,
      cursorStyle: mouseRotateIcon(360),
      actionHandler: fabric.controlsUtils.rotationWithSnapping,
      actionName: 'rotate',
      render: drawRotateIcon,
      cornerSize: 48,
      withConnection: false,
    })

    // Texbox controls
    fabric.Textbox.prototype.controls.tr = fabric.Object.prototype.controls.tr
    fabric.Textbox.prototype.controls.tl = fabric.Object.prototype.controls.tl
    fabric.Textbox.prototype.controls.bl = fabric.Object.prototype.controls.bl
    fabric.Textbox.prototype.controls.br = fabric.Object.prototype.controls.br
    // fabric.Textbox.prototype.controls.deleteObject = fabric.Object.prototype.controls.deleteObject
    // // fabric.Textbox.prototype.controls.replaceObject = fabric.Object.prototype.controls.replaceObject
    // fabric.Textbox.prototype.controls.duplicateObject = fabric.Object.prototype.controls.duplicateObject
    // fabric.Textbox.prototype.controls.bringFrontPosition = fabric.Object.prototype.controls.bringFrontPosition
    // fabric.Textbox.prototype.controls.bringBackPosition = fabric.Object.prototype.controls.bringBackPosition

    fabric.Group.prototype.setControlsVisibility({
      mt: false,
      mb: false,
      mr: false,
      ml: false,
    })

    fabric.Textbox.prototype.setControlsVisibility({
      mt: false,
      mb: false,
    })

    fabric.Image.prototype.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
    })

    // changeWidth
    fabric.Textbox.prototype.controls.mr = new fabric.Control({
      x: 0.5,
      y: 0,
      offsetX: 0.5,
      actionHandler: fabric.controlsUtils.changeWidth,
      // cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
      // cursorStyle: resizeIcon(),
      cursorStyle: 'ew-resize',
      actionName: 'resizing',
      render: drawVerticalLineIcon,
      cornerSize: 18,
      withConnection: true,
    })

    fabric.Textbox.prototype.controls.ml = new fabric.Control({
      x: -0.5,
      y: 0,
      offsetX: -1,
      actionHandler: fabric.controlsUtils.changeWidth,
      // cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
      // cursorStyle: resizeIcon(),
      cursorStyle: 'ew-resize',
      actionName: 'resizing',
      render: drawVerticalLineIcon,
      cornerSize: 18,
      withConnection: true,
    })

    fabric.Textbox.prototype.controls.mtr = new fabric.Control({
      offsetX: -18,
      y: 0.5,
      offsetY: 37.5,
      actionHandler: fabric.controlsUtils.rotationWithSnapping,
      cursorStyle: mouseRotateIcon(360),
      actionName: 'rotate',
      render: drawRotateIcon,
      cornerSize: 48,
      withConnection: false,
    })
    fabric.Textbox.prototype.controls.moveText = new fabric.Control({
      offsetX: 18,
      y: 0.5,
      offsetY: 37.5,
      cursorStyle: 'move',
      actionHandler: () => {
        this.editorEventManager.emit('text:moving')
        this.canvas.setCursor('move')
        this.isDraggingText = true
        this.canvas.getActiveObject().set({
          hasRotatingPoint: false,
          selectable: false,
          hasControls: false,
        })
      },
      actionName: 'moveText',
      render: drawMoveTextIcon,
      cornerSize: 48,
      withConnection: false,
    })

    fabric.Textbox.prototype.editingBorderColor = 'rgba(255, 5, 96)'

    this.canvas.on('mouse:over', event => {
      const target = event.target
      const activeObjects = this.canvas.getActiveObject()
      if (
        target &&
        activeObjects !== target &&
        !target.isTemplateLayer &&
        target?.type !== ObjectType.BAZAART_BG &&
        target?.type !== ObjectType.FRAME
      ) {
        const ctx = this.canvas.getContext()
        this.updateDrawBorder(target)
        this.updateDrawBorder(activeObjects)
        this.drawObjectBoundary(ctx, target)
      }
    })

    function treatAngle(angle) {
      return angle - (angle % 15)
    }
    const state = {
      lastAngleRotation: null,
      selectionStart: 0,
      selectionEnd: 0,
      isTextEditing: false,
    }

    this.canvas.on('object:rotating', e => {
      this.isRotating = true
      const angle = treatAngle(e.target.angle)

      if (state.lastAngleRotation !== angle) {
        this.canvas.setCursor(mouseRotateIcon(angle))
        state.lastAngleRotation = angle
      }
      const target = e.target
      if (!target) {
        return
      }
      target.setControlsVisibility({
        ml: false,
        mr: false,
        tl: false,
        tr: false,
        bl: false,
        br: false,
        moveText: false,
        mtr: true,
      })
    })

    this.canvas.on('object:modified', e => {
      const target = e.target
      updateControlCursorStyle(target)
    })

    const updateControlCursorStyle = target => {
      ;['tl', 'tr', 'bl', 'br', 'mtr'].forEach(corner => {
        const rotatedCursorStyle = getRotatedCursorStyle(target.angle, corner)
        target.controls[corner].cursorStyle =
          corner === 'mtr' ? mouseRotateIcon(target.angle) : rotatedCursorStyle
      })
    }

    function getRotatedCursorStyle(angle, corner) {
      let cursorStyle = ''

      switch (corner) {
        case 'tr':
        case 'bl':
          cursorStyle = 'nesw-resize'
          if (
            (angle >= 0 && angle <= 22) ||
            (angle >= 338 && angle < 360) ||
            (angle >= 157 && angle <= 202)
          ) {
            return cursorStyle
          }
          if ((angle >= 23 && angle <= 67) || (angle >= 203 && angle <= 247)) {
            cursorStyle = 'ew-resize'
          }
          if ((angle >= 68 && angle <= 112) || (angle >= 248 && angle <= 292)) {
            cursorStyle = 'nwse-resize'
          }
          if ((angle >= 113 && angle <= 156) || (angle >= 293 && angle <= 337)) {
            cursorStyle = 'ns-resize'
          }
          break
        case 'br':
        case 'tl':
          cursorStyle = 'nwse-resize'
          if (
            (angle >= 0 && angle <= 22) ||
            (angle >= 338 && angle < 360) ||
            (angle >= 157 && angle <= 202)
          ) {
            return cursorStyle
          }
          if ((angle >= 23 && angle <= 67) || (angle >= 203 && angle <= 247)) {
            cursorStyle = 'ns-resize'
          }
          if ((angle >= 68 && angle <= 112) || (angle >= 248 && angle <= 292)) {
            cursorStyle = 'nesw-resize'
          }
          if ((angle >= 113 && angle <= 156) || (angle >= 293 && angle <= 337)) {
            cursorStyle = 'ew-resize'
          }
          break
        default:
          break
      }
      return cursorStyle
    }

    this.canvas.on('object:moving', event => {
      this.isMoving = true
    })

    this.canvas.on('mouse:move', event => {
      if (this.isClickRotate) {
        this.isChanging = true
        this.isClickRotate = false
      }
      const activeObject = this.canvas.getActiveObject()
      if (this.isDraggingText) {
        handleDraggingText(event, activeObject)
      }
      if (this.isMoving) {
        if (event.target) {
          activeObject.hasControls = false
          activeObject.hasRotatingPoint = false
        }
        return
      }
      if (this.isChanging) {
        return
      }
      if (!activeObject || !activeObject?.hasControls) {
        return
      }

      const pointer = this.canvas.getPointer(event.e, true)
      const corner = this.currentScalingCorner
        ? this.currentScalingCorner
        : activeObject._findTargetCorner(pointer, fabric.util.isTouchEvent(event.e))
      if (!corner && !this.cornerPre) {
        return
      }
      if (corner && corner === this.cornerPre) {
        return
      }
      if (corner) {
        revertPreviousCorner(this.cornerPre) //
        this.cornerPre = corner
        if (corner !== 'moveText') {
          fabric.Object.prototype.cornerSize = corner === 'mtr' ? 28 : 20
          fabric.Object.prototype.controls[corner] = new fabric.Control({
            ...fabric.Object.prototype.controls[corner],
            render: corner === 'mtr' ? onDrawRotateIcnHover : onDrawCircleHover,
          })
        }
        fabric.Textbox.prototype.cornerSize = corner === 'mtr' || corner === 'moveText' ? 28 : 20
        fabric.Textbox.prototype.controls[corner] = new fabric.Control({
          ...fabric.Textbox.prototype.controls[corner],
          render:
            corner === 'mtr'
              ? onDrawRotateIcnHover
              : corner === 'moveText'
              ? onDrawMoveTextIcnHover
              : corner === 'mr' || corner === 'ml'
              ? drawVerticalLineIconHover
              : onDrawCircleHover,
        })
      } else {
        if (!this.isChanging) {
          this.endAnimation = true
          this.isRenderHover = false
          revertPreviousCorner(this.cornerPre)
          this.cornerPre = null
          setTimeout(() => {
            this.endAnimation = false
          }, 100)
        }
      }
      this.canvas.requestRenderAll()
    })

    function revertPreviousCorner(cornerPre) {
      if (!cornerPre) {
        return
      }
      if (cornerPre !== 'moveText') {
        fabric.Object.prototype.cornerSize = cornerPre === 'mtr' ? 24 : 12
        fabric.Object.prototype.controls[cornerPre] = new fabric.Control({
          ...fabric.Object.prototype.controls[cornerPre],
          render: cornerPre === 'mtr' ? drawRotateIcon : drawCircleIcon,
        })
      }
      fabric.Textbox.prototype.cornerSize = cornerPre === 'mtr' || cornerPre === 'moveText' ? 24 : 12
      fabric.Textbox.prototype.controls[cornerPre] = new fabric.Control({
        ...fabric.Textbox.prototype.controls[cornerPre],
        render:
          cornerPre === 'mtr'
            ? drawRotateIcon
            : cornerPre === 'moveText'
            ? drawMoveTextIcon
            : cornerPre === 'mr' || cornerPre === 'ml'
            ? drawVerticalLineIcon
            : drawCircleIcon,
      })
    }

    this.canvas.on('before:transform', e => {
      // this.isChanging = true
    })

    this.canvas.on('mouse:up', e => {
      const target = e.target
      let rotateAngle = 0
      if (this.isClickRotate) {
        this.root.transactionHandler.save()
        rotateAngle = target.angle + 90 > 360 ? target.angle + 90 - 360 : target.angle + 90
        target.set('angle', rotateAngle)
        // adjustRotatePosition(rotateAngle)
        target.setCoords()
        this.editorEventManager.emit('after:render')
        if(target.type === ObjectType.BAZAART_TEXT) {
          target.isResized = true
          target.splitByGrapheme = true
        }
      } else if (this.isChanging) {
        rotateAngle = target.angle
        // adjustRotatePosition(rotateAngle)
      }
      const activeObject = this.canvas.getActiveObject()
      if (activeObject) {
        activeObject.setCoords()
        const pointer = this.canvas.getPointer(e.e, true)
        setTimeout(() => {
          const corner = activeObject._findTargetCorner(pointer, fabric.util.isTouchEvent(e.e))
          if ((this.isClickRotate || this.isChanging) && !corner) {
            this.endAnimation = true
            revertPreviousCorner(this.cornerPre)
            this.canvas.setCursor('default')
            this.cornerPre = null
            setTimeout(() => {
              this.endAnimation = false
            }, 100)
          }
        })
        activeObject.hasControls = true
        activeObject.hasRotatingPoint = true
      }
      if (this.isDraggingText) {
        this.editorEventManager.emit('text:moved')
        this.canvas.setCursor('default')
        this.canvas.getActiveObject().set({
          hasRotatingPoint: true,
          selectable: true,
          hasControls: true,
        })
        this.root.objectsHandler.setTextInitialPosition(activeObject)
      }
      if (this.isChanging || this.isDraggingText) {
        if (state.isTextEditing) {
          activeObject.enterEditing()
          activeObject.setSelectionStart(state.selectionStart)
          activeObject.setSelectionEnd(state.selectionEnd)
        }
      }
      if (this.isRotating) {
        activeObject.set('angle', Math.round(activeObject.angle))
        this.isRotating = false
      }
      this.isDraggingText = false
      this.isChanging = false
      this.isClickRotate = false
      this.isMoving = false
      this.currentScalingCorner = ''
      if (target) {
        target.setControlsVisibility({
          tl: true,
          tr: true,
          bl: true,
          br: true,
          mtr: !target._editingMode,
        })
        if (target.type === ObjectType.BAZAART_TEXT) {
          target.setControlsVisibility({
            moveText: true,
            ml: true,
            mr: true,
          })
        }
      }
      this.canvas.requestRenderAll()
    })

    this.canvas.on('mouse:down', async event => {
      const activeObject = this.canvas.getActiveObject()
      const target = event.target
      // right click
      if (event.button === 3) {
        const getDataFromClipboard = async () => {
          try {
            const clipboardItems = await navigator.clipboard.read()
            for (const clipboardItem of clipboardItems) {
              for (const type of clipboardItem.types) {
                if (type === 'image/png' || type === 'image/jpeg') {
                  const blob = await clipboardItem.getType(type)
                  const reader = new FileReader()
                  reader.onloadend = async () => {
                    const base64String = reader.result as string
                    let guid = nanoid()
                    let assetStateId = nanoid()

                    let maskInfo =
                      await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractMask(
                        base64String
                      )

                    await MediaImageRepository.getInstance().storeImageBlobString(
                      guid,
                      assetStateId,
                      MediaImageType.latest,
                      base64String
                    )
                    await MediaImageRepository.getInstance().storeImageBlobString(
                      guid,
                      assetStateId,
                      MediaImageType.original,
                      base64String
                    )
                    await MediaImageRepository.getInstance().storeImageBlobString(
                      guid,
                      assetStateId,
                      MediaImageType.mask,
                      maskInfo.blob
                    )

                    let frame = this.root.frameHandler.get()
                    let layerSize = maskInfo.size
                    let canvasAspectRatio = frame.width / frame.height
                    let layerAspectRatio = layerSize.width / layerSize.height
                    let width = 0.6

                    if (layerAspectRatio < canvasAspectRatio) {
                      width = (width * layerAspectRatio) / canvasAspectRatio
                    }
                    console.log('pointer', event.pointer)

                    const object = {
                      type: ObjectType.BAZAART_IMAGE,
                      centerPoint: {
                        x: 0.5,
                        y: 0.5,
                      },
                      sizeOnCanvas: {
                        width: width,
                      },
                      transformation: {
                        horizontalFlip: false,
                        verticalFlip: false,
                      },
                      boundingBox: { y: 0, width: 1, height: 1, x: 0 },
                      absoluteRotation: 0,
                      bazaartGuid: guid,
                      layerAssetStateId: assetStateId,
                      hasTransparency: maskInfo.hasTransparency,
                      top: event.pointer.y,
                      left: event.pointer.x,
                    }
                    this.root.objectsHandler.clipboard = object
                    store.dispatch(setHasClipboardImage('external'))
                  }
                  reader.readAsDataURL(blob)
                }
              }
            }
          } catch (error) {}
        }
        await getDataFromClipboard()

        event.e.preventDefault()
        event.e.stopPropagation()
        if (!target) {
          return
        }
        if (target.type === ObjectType.FRAME) {
          this.canvas.discardActiveObject()
        } else {
          this.canvas.setActiveObject(target)
        }
        const pointer = event.pointer
        const contextMenu = document.getElementById('context-menu')
        const isOverflowY = pointer.y + 64 + contextMenu.offsetHeight > window.innerHeight
        if (isOverflowY) {
          this.context.setContextMenuDetails({
            left: pointer.x,
            top: window.innerHeight - contextMenu.offsetHeight - 64,
          })
        } else {
          this.context.setContextMenuDetails({
            left: pointer.x,
            top: pointer.y,
          })
        }

        return
      }
      if (event.button === 1) {
        this.context.setContextMenuDetails(null)
      }
      const pointer = this.canvas.getPointer(event.e, true)
      if (!activeObject || !activeObject?.hasControls) {
        return
      }
      const corner = activeObject._findTargetCorner(pointer, fabric.util.isTouchEvent(event.e))
      this.isClickRotate = corner === 'mtr'
      if (corner === 'moveText') {
        this.root.transactionHandler.fireWithoutSave('object:changing')
        this.root.transactionHandler.save()
        // calculate offset
        const pointer = event.pointer
        this.offsetX = pointer.x / this.canvas.getZoom() - activeObject.left
        this.offsetY = pointer.y / this.canvas.getZoom() - activeObject.top
      }
      if (activeObject.type === ObjectType.BAZAART_TEXT && (corner === 'moveText' || corner === 'mtr')) {
        state.selectionStart = activeObject.selectionStart
        state.selectionEnd = activeObject.selectionEnd
        state.isTextEditing = activeObject.isEditing
        if (activeObject.isEditing) {
          activeObject.exitEditing()
        }
      } else {
        state.isTextEditing = false
      }
    })
    this.canvas.on('object:resizing', event => {
      const target = event.target
      if (!target) {
        return
      }
      this.currentScalingCorner = event.transform.corner
      if (target.type === ObjectType.STATIC_TEXT || target.type === ObjectType.BAZAART_TEXT) {
        target.setControlsVisibility({
          ml: this.currentScalingCorner === 'ml',
          mr: this.currentScalingCorner === 'mr',
          tl: false,
          tr: false,
          bl: false,
          br: false,
          moveText: false,
          mtr: false,
        })
      }
    })

    this.canvas.on('object:scaling', event => {
      this.currentScalingCorner = event.transform.corner
      const target = event.target
      if (!target) {
        return
      }
      target.setControlsVisibility({
        // Set all to false initially
        tl: this.currentScalingCorner === 'tl',
        tr: this.currentScalingCorner === 'tr',
        bl: this.currentScalingCorner === 'bl',
        br: this.currentScalingCorner === 'br',
        ml: false,
        mr: false,
        moveText: false,
        mtr: false,
      })
    })

    const onDrawCircleHover = (ctx, left, top, __styleOverride, fabricObject) => {
      if (this.isRenderHover) {
        drawCircleIconHover(ctx, left, top, __styleOverride, fabricObject, 10)
        return
      }
      let radius = 7
      let loop = () => {
        drawCircleIconHover(ctx, left, top, __styleOverride, fabricObject, radius)
        if (radius >= 10 || this.endAnimation) {
          this.isRenderHover = true
          return
        } else {
          radius = Math.min(radius + 0.25, 10)
        }
        requestAnimationFrame(loop)
      }
      requestAnimationFrame(loop)
    }

    const onDrawMoveTextIcnHover = (ctx, left, top, __styleOverride, fabricObject) => {
      if (this.isRenderHover) {
        drawMoveTextIconHover(ctx, left, top, __styleOverride, fabricObject, 56)
        return
      }
      let size = 48
      let loop = () => {
        drawMoveTextIconHover(ctx, left, top, __styleOverride, fabricObject, size)
        if (size >= 56 || this.endAnimation) {
          this.isRenderHover = true
          return
        } else {
          size = Math.min(size + 0.35, 56)
        }
        requestAnimationFrame(loop)
      }
      requestAnimationFrame(loop)
    }

    const onDrawRotateIcnHover = (ctx, left, top, __styleOverride, fabricObject) => {
      if (this.isRenderHover) {
        drawRotateIconHover(ctx, left, top, __styleOverride, fabricObject, 56)
        return
      }
      let size = 48
      let loop = () => {
        drawRotateIconHover(ctx, left, top, __styleOverride, fabricObject, size)
        if (size >= 56 || this.endAnimation) {
          this.isRenderHover = true
          return
        } else {
          size = Math.min(size + 0.35, 56)
        }
        requestAnimationFrame(loop)
      }
      requestAnimationFrame(loop)
    }

    const handleDraggingText = (event, activeObject) => {
      const pointer = event.pointer
      activeObject.set({
        left: pointer.x / this.canvas.getZoom() - this.offsetX,
        top: pointer.y / this.canvas.getZoom() - this.offsetY,
      })
      this.canvas.requestRenderAll()
      this.root.guidelinesHandler.onObjectMoving(event)
    }

    this.canvas.selectionColor = 'rgba(255, 5, 96, 0.1)'
    this.canvas.selectionBorderColor = 'rgba(255, 5, 96, 1)'
    this.canvas.selectionLineWidth = 1
    this.canvas.on('selection:created', ev => {
      const objects = this.canvas.getActiveObjects()
      const selection = this.canvas.getActiveObject()
      if (objects.length > 1) {
        selection.setControlsVisibility({
          mt: false,
          mb: false,
          mr: false,
          ml: false,
        })
        // selection.padding = 10
      }
    })
  }

  getCoords = (obj: fabric.Object) => {
    const [tl, tr, br, bl] = obj.getCoords(true)
    return { tl, tr, br, bl }
  }

  getRotateIconPosition = (object): string => {
    const corners = this.getCoords(object);
    const rotateCoords = {
      x: (corners.bl.x + corners.br.x) / 2,
      y: (corners.bl.y + corners.br.y) / 2 + 37.5,
    };
  
    if (rotateCoords.y >= corners.tr.y && rotateCoords.y >= corners.tl.y) return 'bottom';
    if (rotateCoords.y <= corners.tr.y && rotateCoords.y <= corners.tl.y) return 'top';
    if (rotateCoords.x <= corners.tl.x) return 'left';
    if (rotateCoords.x >= corners.tr.x) return 'right';
  
    return 'bottom';
  }

  /**
   * Adjust rotate icon coordinates base on tooltip position
   * @param tooltipPosition 
   * @returns 
   */
  reCalculateRotatePosition = (tooltipPosition: POSITION) => {
    const activeObject = this.canvas.getActiveObject()
    if (!activeObject) {
      return
    }

    const setControlPosition = (controlName, x, y, offsetX, offsetY) => {
      activeObject.controls[controlName] = new fabric.Control({
        ...activeObject.controls[controlName],
        x,
        y,
        offsetX,
        offsetY,
      });
    };

    // Set it back to the default position at the bottom
    setControlPosition('mtr', 0, 0.5, activeObject.type === ObjectType.BAZAART_TEXT ? -18 : 0, this.MTR_CONTROL_OFFSET)
    if (activeObject.type === ObjectType.BAZAART_TEXT) {
      setControlPosition('moveText', 0, 0.5, 18, this.MTR_CONTROL_OFFSET);
    }

    let rotateIcon = this.getRotateIconPosition(activeObject)
    const controlsPosition = this.getControlPositions(activeObject)
    const currentRotatePosition = this.getMtrCoordsAfterRotation(activeObject)
    const objectCorners = this.getCoords(activeObject)
    const objectCenterCoord = {
      x: (objectCorners.tl.x + objectCorners.tr.x + objectCorners.bl.x + objectCorners.br.x) / 4,
      y: (objectCorners.tl.y + objectCorners.tr.y + objectCorners.bl.y + objectCorners.br.y) / 4,
    } // Object's center
    const frameBB = this.root.frameHandler.get().getBoundingRect(true)

    if (rotateIcon === tooltipPosition) {
      let targetPosition = objectCenterCoord.x >= frameBB.left + frameBB.width / 2 ? 'left' : 'right'
      const sideControls = controlsPosition[`${targetPosition}Controls`].map(item => item.name)
      if(sideControls.includes('tl') && sideControls.includes('bl')) {
        setControlPosition('mtr', -0.5, 0, -this.MTR_CONTROL_OFFSET, activeObject.type === ObjectType.BAZAART_TEXT ? -18 : 0)
        if (activeObject.type === ObjectType.BAZAART_TEXT) {
          setControlPosition('moveText', -0.5, 0, -this.MTR_CONTROL_OFFSET, 18)
        }
      } else if (sideControls.includes('br') && sideControls.includes('tr')) {
        setControlPosition('mtr', 0.5, 0, this.MTR_CONTROL_OFFSET, activeObject.type === ObjectType.BAZAART_TEXT ? 18 : 0)
        if (activeObject.type === ObjectType.BAZAART_TEXT) {
          setControlPosition('moveText', 0.5, 0, this.MTR_CONTROL_OFFSET, -18)
        }
      }
    } else {
      let possiblePosition = ['left', 'right', 'top', 'bottom'].filter(position => position !== tooltipPosition)
      let outFramePosition = ''

      if (currentRotatePosition.y < frameBB.top) { outFramePosition = 'top' }
      if (currentRotatePosition.y > frameBB.top + frameBB.height) { outFramePosition = 'bottom' }
      if (currentRotatePosition.x < frameBB.left) { outFramePosition = 'left' }
      if (currentRotatePosition.x > frameBB.left + frameBB.width) { outFramePosition = 'right' }

      possiblePosition = possiblePosition.filter(position => position !== outFramePosition)

      // Current rotate position is out of frame and one is tooltip position, only 2 pos left
      if (possiblePosition.length === 2) {
        let newMtr,
          isValid = false
        possiblePosition.some((position, index) => {
          const sideControls = controlsPosition[`${position}Controls`].map(item => item.name)
          const mappedObject = controlsPosition[`${position}Controls`].reduce((acc, { name, point }) => {
            acc[name] = point
            return acc
          }, {})

          switch (position) {
            case 'left':
            case 'right':
              if (sideControls.includes('tl') && sideControls.includes('bl')) {
                newMtr = {
                  x: mappedObject.tl.x + (position === 'left' ? -this.MTR_CONTROL_OFFSET : this.MTR_CONTROL_OFFSET),
                  y: (mappedObject.tl.y + mappedObject.bl.y) / 2,
                }
                if((position === 'left' && newMtr.x > frameBB.left) || (position === 'right' && newMtr.x < frameBB.left + frameBB.width)) {
                  isValid = true
                  setControlPosition('mtr', -0.5, 0, -this.MTR_CONTROL_OFFSET, activeObject.type === ObjectType.BAZAART_TEXT ? -18 : 0)
                  if (activeObject.type === ObjectType.BAZAART_TEXT) {
                    setControlPosition('moveText', -0.5, 0, -this.MTR_CONTROL_OFFSET, 18)
                  }
                }
              } else if (sideControls.includes('br') && sideControls.includes('tr')) {
                newMtr = {
                  x: mappedObject.br.x + (position === 'left' ? -this.MTR_CONTROL_OFFSET : this.MTR_CONTROL_OFFSET),
                  y: (mappedObject.br.y + mappedObject.tr.y) / 2,
                }

                if((position === 'left' && newMtr.x > frameBB.left) || (position === 'right' && newMtr.x < frameBB.left + frameBB.width)) {
                  isValid = true
                  setControlPosition('mtr', 0.5, 0, this.MTR_CONTROL_OFFSET, activeObject.type === ObjectType.BAZAART_TEXT ? 18 : 0)
                  if (activeObject.type === ObjectType.BAZAART_TEXT) {
                    setControlPosition('moveText', 0.5, 0, this.MTR_CONTROL_OFFSET, -18)
                  }
                }
              }
              break
            default:
              break
          }
          return isValid
        })
      }
    }
  }
      
  updateDrawBorder(activeObject) {
    if (this.originalCanvasSize.isEmpty()) {
      this.originalCanvasSize = this.root.frameHandler.getSize()
    }

    if (!activeObject || activeObject.type === ObjectType.BAZAART_TEXT) {
      return
    }
    if(this.originalScaleX === 0 && this.originalScaleY === 0) {
      this.originalScaleX = activeObject.scaleX
      this.originalScaleY = activeObject.scaleY
    }
    // Shadow effect
    this.findControlsPosition(activeObject)
    activeObject.setCoords()
    activeObject.drawBorders = function (ctx) {
      if(!this.isLatest || (!this.effects?.shadow && !this.effects?.outline)) {
        this.callSuper('drawBorders', ctx);
      }
      // Do nothing here
    };
    const that = this
    activeObject.drawControls = function (ctx) {
      if(!this.isLatest || (!this.effects?.shadow && !this.effects?.outline)) {
        this.controls.tl = new fabric.Control({
          ...activeObject.controls.tl,
          x: -0.5,
          y: -0.5,
        })
        this.controls.tr = new fabric.Control({
          ...activeObject.controls.tr,
          x: 0.5,
          y: -0.5,
        })
        this.controls.bl = new fabric.Control({
          ...activeObject.controls.bl,
          x: -0.5,
          y: 0.5,
        })
        this.controls.br = new fabric.Control({
          ...activeObject.controls.br,
          x: 0.5,
          y: 0.5,
        })
        this.callSuper('drawControls', ctx);
        return
      }
      this.callSuper('drawControls', ctx);
      setTimeout(() => {
        ctx.save();
        that.drawObjectBoundary(ctx, activeObject)
        this.callSuper('drawControls', ctx);
        ctx.restore();
      }, 0);
    };
  }
  findControlsPosition = (activeObject) => {
    // Retrieve rotated control points
    if (!activeObject) {
      return;
    }

    let offsetX = 0;
    let offsetY = 0;
    let imageScaleX = 1;
    let imageScaleY = 1;
    if (activeObject.isLatest) {
      let w = activeObject.width;
      let h = activeObject.height;
  
      let sourceBounds = new Rectangle(0, 0, w, h);
      let shadowEffects = new CanvasLayerShadowEffect();
      let shadowRect = shadowEffects.revertEffectBounds(activeObject, this.originalCanvasSize, false, sourceBounds);
  
      let nonSymmetricOffset = new Point(
        shadowRect.x,
        shadowRect.y
      )

      if (activeObject.flipX) {
        nonSymmetricOffset.x = - nonSymmetricOffset.x;
      }
      if (activeObject.flipY) {
        nonSymmetricOffset.y =  - nonSymmetricOffset.y;
      }
  
      let outlineEffects = new CanvasLayerOutlineEffect();
      let outlineRect = outlineEffects.revertEffectBounds(activeObject, this.originalCanvasSize, false, shadowRect);
      
      imageScaleX = outlineRect.width / w
      imageScaleY = outlineRect.height / h

      offsetX = nonSymmetricOffset.x / w;
      offsetY = nonSymmetricOffset.y / h;
    }

    //  control location follow this formula : this.x * dim.x + this.offsetX
    activeObject.controls.tl = new fabric.Control({
      ...activeObject.controls.tl,
      x: -0.5 * imageScaleX - offsetX,
      y: -0.5 * imageScaleY - offsetY,
    })
    activeObject.controls.tr = new fabric.Control({
      ...activeObject.controls.tr,
      x: 0.5 * imageScaleX - offsetX,
      y: -0.5 * imageScaleY - offsetY,
    })
    activeObject.controls.br = new fabric.Control({
      ...activeObject.controls.br,
      x: 0.5 * imageScaleX - offsetX,
      y: 0.5 * imageScaleY - offsetY,
    })
    activeObject.controls.bl = new fabric.Control({
      ...activeObject.controls.bl,
      x: -0.5 * imageScaleX - offsetX,
      y: 0.5 * imageScaleY - offsetY,
    })
  }

  drawObjectBoundary(ctx, object) {
    ctx.strokeStyle = '#FF0560'
    ctx.lineWidth = 1.5
    ctx.beginPath()
    ctx.moveTo(object.oCoords.bl.x, object.oCoords.bl.y)
    ctx.lineTo(object.oCoords.tl.x, object.oCoords.tl.y)
    ctx.lineTo(object.oCoords.tr.x, object.oCoords.tr.y)
    ctx.lineTo(object.oCoords.br.x, object.oCoords.br.y)
    ctx.lineTo(object.oCoords.bl.x, object.oCoords.bl.y)
    ctx.stroke()
  }

  getControlPositions = object => {
    const objectCorners = this.getCoords(object) // Get actual corners after rotation

    // Extract corner coordinates
    const corners = [
      { name: 'tl', point: objectCorners.tl },
      { name: 'tr', point: objectCorners.tr },
      { name: 'bl', point: objectCorners.bl },
      { name: 'br', point: objectCorners.br },
    ]

    // Sort by Y coordinate to find top and bottom
    const sortedByY = [...corners].sort((a, b) => a.point.y - b.point.y)
    const topControls = [sortedByY[0], sortedByY[1]] // Two smallest Y values are the top
    const bottomControls = [sortedByY[2], sortedByY[3]] // Two largest Y values are the bottom

    // Sort by X coordinate to find left and right
    const sortedByX = [...corners].sort((a, b) => a.point.x - b.point.x)
    const leftControls = [sortedByX[0], sortedByX[1]] // Two smallest X values are the left
    const rightControls = [sortedByX[2], sortedByX[3]] // Two largest X values are the right

    return {
      topControls,
      bottomControls,
      leftControls,
      rightControls,
    }
  }

  /**
   * Will return coordinates of 'mtr' control after rotated
   * @param obj active object
   * @returns 
   */
  getMtrCoordsAfterRotation(obj) {
    const corners = this.getCoords(obj);
    const center = {
      x: (corners.tl.x + corners.tr.x + corners.bl.x + corners.br.x) / 4,
      y: (corners.tl.y + corners.tr.y + corners.bl.y + corners.br.y) / 4,
    };
    const angle = fabric.util.degreesToRadians(Math.abs(270 - obj.angle));
    const radius = obj.getScaledHeight() / 2 + 37.5;
  
    return {
      x: center.x + radius * Math.cos(angle),
      y: center.y - radius * Math.sin(angle),
    };
  }
}

export default PersonalizationHandler
