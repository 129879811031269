import { useEffect, useRef, useState } from 'react'
import useAppContext from '@/hooks/useAppContext'
import { useParams } from 'react-router'
import { getFonts } from '@store/slices/fonts/actions'
import { getEffect } from '@/store/slices/effect/actions'
import { getBackgroundPacks } from '@store/slices/backgroundPacks/actions'
import { useAppDispatch } from '@store/store'
import Navbar from './components/Navbar'
import Inspector from './components/Panels/Inspector'
import Footer from './components/Footer'
import api from '@services/api'
import Editor from '../engine/BZCanvas'
import { useEditorContext, useRemoveEditorContext } from '@/scenes/engine'
import { getGraphicPacks } from '@/store/slices/graphicsPacks/actions'
import { getTemplateDetails, getTemplatesSuperCategories } from '@/store/slices/templates/actions'
import Loading from '@/components/Loading'
import { PanelType, PopoverType } from '@/constants/app-options'
import PanelItems from './components/Panels/PanelItems'
import { SubMenuType } from '@/constants/editor'
import TooltipMenu from './components/TooltipMenu'
import { CSSTransition } from 'react-transition-group'
import './editor.css'
import { useSelector } from 'react-redux'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { ThemeProvider } from 'baseui'
import { lightTheme } from '@/customTheme'
import { setTemplateConfig } from '@/store/slices/templates/actions'
import ModalCustom from './components/Modal/Modal'
import { getImageElements } from '@/store/slices/imageElement/actions'
import RemoveToolCanvas from '../engine/RemoveToolCanvas'
import RemoveToolFooter from './components/Footer/RemoveToolFooter'
import { SignInManager } from './components/Navbar/components/SignInManager'
import { setIsSampleUser, setOpenModalTryPremium } from '@/store/slices/user/actions'
import {
  selectCallbackFromCreationPage,
  selectIsOpenErrorModalRemoveBg,
} from '@/store/slices/editor/selector'
import { setCallbackFromCreationPage, setIsOpenErrorModalRemoveBg } from '@/store/slices/editor/action'
import { customAmplitude } from '@/utils/customAmplitude'
import ContextMenu from './components/ContextMenu'
import * as amplitude from '@amplitude/analytics-browser'
import Sidebar from './components/Sidebar/Sidebar'
import { selectUploads } from '@/store/slices/uploads/selectors'
import { setUploads } from '@/store/slices/uploads/actions'
import { ActionType } from '@/store/slices/editor/reducer'
import { selectTemplatesSuperCategories } from '@/store/slices/templates/selectors'
import { selectOpenModalTryPremium, selectUser } from '@/store/slices/user/selectors'
import { Helmet } from 'react-helmet';
import { t } from 'i18next'
import { selectFBUser } from '@/store/slices/firebaseAuth/selectors'
import ImageRemoveTutorial from '@assets/images/image-remove-tutorial.jpg'
import { useTour } from '@reactour/tour'
import { useFrontChatBoot } from '../../libs/front-chat-sdk/hooks/use-front-chat-boot'
import { SyncManager, SyncState } from '@/firebase/services/sync/SyncManager'
import { MediaImageRepository } from '../engine/objects/media-repository/media_image_repository'
import { ShowModalType } from '../engine/common/constants'
import { useHistory } from 'react-router-dom'
import UnsavedProjectMessage from './components/UnsavedProjectMessage'
import { BtDraftsManager } from '@/firebase/services/sync/BtDraftsManager'
import { nanoid } from 'nanoid'
import { TemplateConfig } from '@/interfaces/editor'
import { URLLockManager } from './components/Navbar/URLLockManager'
// @ts-ignore
import FaviconError from '@assets/icons/favicon-eror.ico'


import PixelManipulation from './components/Toolbox/ToolboxItems/components/PixelManipulation'
import { PixelManipulationCommonObjects } from '@/common/interfaces'
import { InspectorDataProvider } from './components/Toolbox/InspectorDataProvider'
import { useInspector, InspectorPanelType } from './components/Toolbox/InspectorDataProvider'

function App({ location }) {
  //
  const {
    activePanel,
    activeSubMenu,
    isOpenInspector,
    setIsOpenInspector,
    popoverActive,
    setPopoverActive,
    isOpenPixelManipulationObject,
    setIsOpenPixelManipulationWithAnimation,
    setToolType,
    setShowModalType,
    setIsOpenTutorial,
    toolType
  } = useAppContext()
  const { canvas, contextMenuDetails, setContextMenuDetails } = useEditorContext()

  const [componentDefault, setComponentDefault] = useState(null)
  const [unloadedTemplate, setUnloadedTemplate] = useState(false)

  const { activeObject } = useEditorContext()
  const removeEditor = useRemoveEditorContext().editor
  const { setCurrentTemplate, setIsLoadedJsonSuccess, isLoadedJsonSuccess } = useAppContext()

  const openModalTryPremium = useSelector(selectOpenModalTryPremium)

  const editor = useEditorContext().editor
  const { id = null } = useParams<{ id: string }>()

  const dispatch = useAppDispatch()
  const urlParams = new URLSearchParams(window.location.search)

  const { disableEditor, setDisableEditor, removeBgAbortController, setRemoveBgAbortController, canCancelRemoveBg } = useAppContext()

  const searchParams = new URLSearchParams(location.search);
  const history = useHistory()
  const preview_path = urlParams.get('prevUrl')
  const po = PopoverType[urlParams.get('po')]
  const idFromDeeplink = urlParams.get('id')
  const rp = urlParams.get('rp')
  // const fonts = useSelector(selectFonts)
  const isOpenErrorModalRemoveBg = useSelector(selectIsOpenErrorModalRemoveBg)

  const [boundingRectFrame, setBoundingRectFrame] = useState(null)
  const [loadPixelTool, setLoadPixelTool] = useState(false)
  const uploads = useSelector(selectUploads)

  const callbackFromCreationPage = useSelector(selectCallbackFromCreationPage)
  const templatesSuperCategories = useSelector(selectTemplatesSuperCategories)
  const firebaseUser = useSelector(selectFBUser)

  const { setIsOpen } = useTour()

  const syncManager = SyncManager.shared;
  const user = useSelector(selectUser)

  const [isTabFocused, setIsTabFocused] = useState(true);
  const [lockManager] = useState(() => URLLockManager.getInstance());

  const preventDuplicateTabWindow = () => {
    let isDuplicated =  lockManager.isTabDuplicated(window.location.pathname)
    if (isDuplicated){
      // An active tab is already open that does not match our session guid.
      setShowModalType(ShowModalType.PROJECT_ALREADY_OPEN)  
      document.getElementById('favicon').setAttribute('href', FaviconError)
    }
  }

  const idRef = useRef(id);

  // Update the ref whenever 'id' changes
  useEffect(() => {
    idRef.current = id;
  }, [id]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!idRef.current) {return;}
      if (document.visibilityState === 'visible') {
        setIsTabFocused(true);
        // Notify or trigger any behavior when tab is focused
        preventDuplicateTabWindow()
      } else {
        setIsTabFocused(false);
        // You can also handle behavior when the tab is not focused
        console.log('Tab is not focused');
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // const {frontChat, initialize, isInitialized} = useFrontChatBoot(document.body);

  // Pixel Manipulation
  const pixelManipulationDoneClick = useRef<()=>void>(()=>{ console.log("pixelManipulationDoneClick empty") });
  const pixelManipulationCancelClick = useRef<()=>void>(()=>{ console.log("pixelManipulationCancelClick empty") });
  const [didPixelManipulationPerformAction, setDidPixelManipulationPerformAction] = useState<boolean>(false);
  const [isPixelManipulationInProcess, setIsPixelManipulationInProcess] = useState<boolean>(false);
  const pixelManipulationCommonObjects: PixelManipulationCommonObjects = {
    onDoneClick: pixelManipulationDoneClick,
    onCancelClick: pixelManipulationCancelClick,
    didPerformAction: didPixelManipulationPerformAction,
    setDidPerformAction: setDidPixelManipulationPerformAction,
    isInProcess: isPixelManipulationInProcess,
    setIsInProcess: setIsPixelManipulationInProcess,
  }
  const { updateInspector } = useInspector()

  useEffect(() => {
    // dispath(getElements())
    // MINIBIS: hides it becasue we don't want to show those for now
    dispatch(getFonts())
    dispatch(getGraphicPacks())
    dispatch(getBackgroundPacks())
    if (!templatesSuperCategories.length) {
      dispatch(getTemplatesSuperCategories())
    }
    dispatch(getEffect())
    dispatch(getImageElements())
    chooseSampleUser()
    setPopoverActive(po)
    if (rp === 'RESIZE') {
      dispatch(
        setCallbackFromCreationPage({
          action: ActionType.CUSTOM_SIZE,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const editorConfig = {
    clipToFrame: true,
    visible: true,
  }

  const chooseSampleUser = () => {
    const randomNumber = Math.floor(Math.random() * 10) + 1
    const isChooseen = randomNumber === 1
    dispatch(setIsSampleUser(isChooseen))
    const identifyEvent = new amplitude.Identify()
    identifyEvent.set('SampleUser', isChooseen)
  }

  const notifier = {
    syncManagerHasUpdates: async () => {
      await loadProject(id)
    },
    syncManagerHasStateChange: () => { },
    syncManagerHasProjectStateChange: () => { }
  }
  
  const onBeforeLeave = async () => {
    // For now we decice to not delete empty project
    // if(isLoading) { return}
    // // @ts-ignore
    // let objects = editor.handlers.templateHandler.canvas.getObjects().filter ((l) => !!l.bazaartGuid && !!l.type)
    // if (objects.length !== 0) {
    //   return;
    // }
    // let syncItem = syncManager.getSyncItem(idRef.current)
    // if (!syncItem){
    //   return;
    // }
    // await syncManager.deleteProjects([syncItem.mostUpdatedVersion.draftId])
  }

  const handleCallbackFromCreationPage = async () => {
    setIsLoadedJsonSuccess(false)
    
    editor.handlers.objectsHandler.clear(false, false)
    if (callbackFromCreationPage.action === ActionType.CUSTOM_SIZE) {
      if (callbackFromCreationPage.analytic) {
        const eventProperties= {}
        if(callbackFromCreationPage.analytic.source) {
          eventProperties['Source'] = callbackFromCreationPage.analytic.source
        }
        if(callbackFromCreationPage.analytic.projectSizeId) {
          eventProperties['Project Size Id'] = callbackFromCreationPage.analytic.projectSizeId
        }
        customAmplitude('Create', eventProperties)
        if (!callbackFromCreationPage.value) {
          updateInspector(InspectorPanelType.Canvas, { props: { openResize: true} })
        }
      }
    } else if (callbackFromCreationPage.action === ActionType.ADD_IMAGE) {
      await editor.handlers.objectsHandler.addImageToCanvasByUrl(uploads[0].url, true)
      customAmplitude('Create', {
        Source: 'Photo'
      })
    } else if (callbackFromCreationPage.action === ActionType.REMOVE_BG) {
      await editor.handlers.objectsHandler.addImageToCanvasByUrl(uploads[0].url, true, true)
      customAmplitude('Create', {
        Source: 'usecase.magic'
      })
    } else if (callbackFromCreationPage.action === ActionType.REMOVE_OBJ) {
      setIsOpenPixelManipulationWithAnimation(false)
      await editor.handlers.objectsHandler.addImageToCanvasByUrl(uploads[0].url, true)
      let containerOverlay = document.getElementById('container-overlay-remove')
      if (containerOverlay) {
        containerOverlay.style.display = 'block'
      }
      setTimeout(()=>{
        setToolType('Remove')
        setTimeout(()=>{
          updateInspector(InspectorPanelType.Image, { props: { openRemoveObjects: true} })
        })
      })

      customAmplitude('Create', {
        Source: 'usecase.remove'
      })
    } else if (callbackFromCreationPage.action === ActionType.ADD_TEMPLATE) {
      setPopoverActive(PopoverType.TEMPLATES)
      customAmplitude('Create', {
        Source: 'Template'
      })
      dispatch(setCallbackFromCreationPage(null))
    } else if (callbackFromCreationPage.action === ActionType.QUICK_TUTORIAL) {
      await editor.handlers.objectsHandler.addImageToCanvasByUrl(ImageRemoveTutorial, true)
    } else if (callbackFromCreationPage.action === ActionType.MAGIC_BACKGROUND) {
      setIsOpenPixelManipulationWithAnimation(false)
      await editor.handlers.objectsHandler.addImageToCanvasByUrl(uploads[0].url, true)
      let containerOverlay = document.getElementById('container-overlay-remove')
      if (containerOverlay) {
        containerOverlay.style.display = 'block'
      }
      setTimeout(()=>{
        setToolType('MagicBg')
        setTimeout(()=>{
          updateInspector(InspectorPanelType.Image, { props: { openMagicBackground: true} })
        })
      })
      customAmplitude('Create', {
        Source: 'usecase.magicbg'
      })
    } else if (callbackFromCreationPage.action === ActionType.ENHANCE) {
      setIsOpenPixelManipulationWithAnimation(false)
      await editor.handlers.objectsHandler.addImageToCanvasByUrl(uploads[0].url, true)
      let containerOverlay = document.getElementById('container-overlay-remove')
      if (containerOverlay) {
        containerOverlay.style.display = 'block'
      }
      setTimeout(()=>{
        setToolType('Enhance')
        setTimeout(()=>{
          updateInspector(InspectorPanelType.Image, { props: { openEnhance: true} })
        })
      })
      customAmplitude('Create', {
        Source: 'usecase.enhance'
      })
    }
    
    setIsLoadedJsonSuccess(true)
    dispatch(setUploads([]))
  }

  const isTemplate = (id: String): boolean => {
    return !isNaN(Number(id))
  }

  let firstAttempt = true;

  useEffect(() => {
    if (!editor) {
      return;
    }
    if (callbackFromCreationPage) {
      (async () => {
        dispatch(setTemplateConfig(null));
        await handleCallbackFromCreationPage()
        setLoadPixelTool(true)
      })()
    }
  }, [editor, callbackFromCreationPage])

  useEffect(() => {
    let userId = SignInManager.getInstance().userId
    if (!editor || (!user && userId)) {
      return;
    }
    
    const abortController = new AbortController()
    const signal = abortController.signal
    if (!callbackFromCreationPage) {
      setLoadPixelTool(true)
      setIsLoadedJsonSuccess(false)
      if (id && isTemplate(id)) {
        MediaImageRepository.getInstance()._filesystem.projectId = id;
        api.getCreationById(id).then(async data => {
          if (!data) {
            setUnloadedTemplate(true)
            setIsLoadedJsonSuccess(false)
            return
          }

          // @ts-ignore
          if (data && data.object !== 'error') {
            if (!signal.aborted) {
              // @ts-ignore
              dispatch(setTemplateConfig(data.config))
              dispatch(getTemplateDetails(id))
              setCurrentTemplate(data)
              loadTemplate(data)
            }
          }
        });
      } else if (id && !isTemplate(id)) {
        preventDuplicateTabWindow()

        if (!signal.aborted) {
          (async () => {
            MediaImageRepository.getInstance()._filesystem.projectId = id;

            if (firstAttempt) {
              syncManager.addDelegate(notifier)
              firstAttempt = false
            }
            
            if (syncManager.didCompleteSetup) {
              await loadProject(id)
            } else if (!user){              
              setIsLoadedJsonSuccess(true)
              setShowModalType(ShowModalType.PROJECT_DOESNT_EXIST)
            }
          })();
        }
      } else {
        // direct entry to editor, make up some new url and project -
        dispatch(setTemplateConfig(null));
        setIsLoadedJsonSuccess(true)
      }
    }

    editor.on('frame:changed', () => {
      setBoundingRectFrame(() => editor.handlers.frameHandler.get().getBoundingRect())
    })

    const handleLeaveEditor = function (e) {
      if (!editor)
        return
      if (!editor.handlers.transactionHandler.isFlushed) {
        e.preventDefault()
        e.stopPropagation()
        this.alert('You have unsaved changes. Are you sure you want to leave?')
      }
    }
    window.addEventListener('beforeunload', handleLeaveEditor)
    return () => {
      editor.off('history:changed', () => { })
      abortController.abort()
      setIsLoadedJsonSuccess(true)
      window.removeEventListener('beforeunload', handleLeaveEditor)
      syncManager.removeDelegate(notifier)
      dispatch(setTemplateConfig(null));
      onBeforeLeave()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, syncManager, user])

  let isLoading = false
  const loadProject = async id => {
    if (isLoading){
      return;
    }
    let syncItem = syncManager.getSyncItem(id);
    if (!syncItem){
      setShowModalType(ShowModalType.PROJECT_DOESNT_EXIST)
      setIsLoadedJsonSuccess(true)
      return
    }

    if (syncItem.isDownloading()) {
      return;
    }
    
    if (syncItem.isLocalOnly()){
      setIsLoadedJsonSuccess(true)
      return
    }

    isLoading = true
    syncManager.downloadProject(syncItem.mostUpdatedVersion, false, async (project, layers) => {
      if(!layers || !project) {
        setShowModalType(ShowModalType.PROJECT_OPEN_FAILED)
        setIsLoadedJsonSuccess(true)
        return
      }
      
      dispatch(setTemplateConfig(project))
      let templateLoadParams = { config: project, objects: layers, latestAssets: [] }
      setCurrentTemplate(templateLoadParams)
      
      await loadTemplateFonts(templateLoadParams)
      await editor.importFromJSON(templateLoadParams, null, false)

      setIsLoadedJsonSuccess(true)

      isLoading = false
    });
  }

  const loadTemplateFonts = async template => {
    const fonts = []
    if (template.config && template.config.fonts) {
      template.config.fonts.forEach(font => {
        fonts.push({
          name: font.keySystemName,
          url: font.keyFileURL,
          options: { weight: '400' },
        })
      })
    }
    const filteredFonts = fonts.filter(f => !!f.url)
    if (filteredFonts.length > 0) {
      try {
        await loadFonts(filteredFonts)
      }  catch (error) {
        console.error(`failed to load fonts: ${filteredFonts} with error: ${error}`)
      }
    }
  }

  const loadTemplate = async template => {
    await loadTemplateFonts(template)

    await editor.importFromJSON(template)
    setIsLoadedJsonSuccess(true)

    const eventProperties = {
      Source: 'Template',
      'Template id': id,
      'Category id': 0,
      'Category Name': 'none',
    }
    customAmplitude('Editor Open', eventProperties)
  }

  const loadFonts = fonts => {
    const promisesList = fonts.map(font => {
      // @ts-ignore
      // return new FontFace(font.name, `url(${font.url.replace('https://media.bazaart.me','/media')})`, font.options).load().catch(err => err)
      return new FontFace(font.name, `url(${font.url})`, font.options).load().catch(err => err)
    })

    return new Promise((resolve, reject) => {
      Promise.all(promisesList)
        .then(res => {
          res.forEach(uniqueFont => {
            // @ts-ignore
            if (uniqueFont && uniqueFont.family) {
              // @ts-ignore
              document.fonts.add(uniqueFont)
              resolve(true)
            }
          })
        })
        .catch(err => reject(err))
    })
  }

  useEffect(() => {
    setComponentDefault(() =>
      (activeObject && activeSubMenu) || (!activeObject && activeSubMenu === SubMenuType.COLOR)
        ? PanelItems[activeSubMenu]
        : activeObject
          ? PanelItems[PanelType.TOOLBOX]
          : PanelItems[activePanel]
    )
  }, [activePanel, activeSubMenu, activeObject])

  useEffect(() => {
    if (!editor) {
      return
    }
    const offset =
    editor.handlers.frameHandler.FRAME_PADDING_INSPECTOR / 2 -
    editor.handlers.frameHandler.FRAME_PADDING_ADD_MENU / 2
    if (editor.handlers.scrollbarHandler && editor.handlers.scrollbarHandler.updateScrollPosition) {
      editor.handlers.scrollbarHandler.updateScrollPosition()
      editor.handlers.frameHandler.updateLayersCoords()
    }
    if (isOpenPixelManipulationObject) {
      removeEditor.handlers.zoomRemoveHandler.isOpenInspector = !isOpenInspector
      const containerElement = toolType === 'Remove' ? document.querySelector(
        '#wrap-canvas-remove-tool .remove-container-class'
      ) as HTMLElement : document.querySelector(
        '#wrap-canvas-remove-tool #wrap-canvas-remove-zoom-tool'
      ) as HTMLElement
      const computedStyle = window.getComputedStyle(containerElement)
      let transformValue = computedStyle.transform
      console.log(transformValue);
      if(!transformValue.includes('matrix')) {
        transformValue = 'matrix(1, 0, 0, 1, 0, 0)'
      }

      const matrixValues = transformValue.match(/matrix\((.+)\)/)?.[1].split(', ')
      const translateXAsNumber = matrixValues ? parseFloat(matrixValues[4]) : 0
      const newTranslateX = !isOpenInspector ? translateXAsNumber + offset : translateXAsNumber - offset

      // Update the new value of transformValue
      const updatedMatrixValues = matrixValues?.map((value, index) =>
        index === 4 ? newTranslateX.toString() : value
      )
      const updatedTransformValue = `matrix(${updatedMatrixValues?.join(', ')})`

      // Update the containerElement's transform style
      containerElement.style.transform = updatedTransformValue
      const cursorPreview = document.querySelector('.custom-cursor') as HTMLElement
      cursorPreview.style.transform = updatedTransformValue
    }
  }, [isOpenInspector])

  const abortRemoveBackground = () => {
    removeBgAbortController.abort()
    setRemoveBgAbortController(null)
    setDisableEditor(false)
  }

  useEffect(() => {
    document.addEventListener(
      'keydown',
      e => {
        if (e.keyCode === 27) {
          if (disableEditor && removeBgAbortController) {
            abortRemoveBackground()
          }
        }
      },
      false
    )

    return () => {
      document.removeEventListener(
        'keydown',
        e => {
          // console.log('esc', e)
        },
        false
      )
    }
  }, [disableEditor, setDisableEditor, removeBgAbortController, setRemoveBgAbortController])

  useEffect(() => {
    if (canvas) {
      canvas.on('mouse:down', e => {
        if (!isOpenInspector) {
          // @ts-ignore
          if (e.target && e.target.isTemplateLayer) {
            return
          }
          setIsOpenInspector(true)
          editor.handlers.scrollbarHandler.updateScrollPosition()
        }
      })
    }
  }, [canvas, isOpenInspector])

  // useEffect(() => {
  //   console.log("initialized")
  //   if (!initialize || isInitialized) {
  //     return;
  //   }

  //   initialize({chatId});
  // }, [isInitialized, initialize]);


  
  return (
    <ThemeProvider theme={lightTheme}>
      <Helmet>
        <title>Bazaart Design Editor</title>
      </Helmet>
      <div
        style={{
          position: 'relative',
          width: '100vw',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          background: '#F2F2F2',
          overflow: 'hidden',
        }}
        onClick={(e: any) => {
          if (contextMenuDetails) {
            setContextMenuDetails(null)
          }
          if (e.target.closest('#wrap-modal')) {
            return
          }
          if (e.target.closest('.btn-close-popover')) {
            setIsOpenInspector(true)
          }
          if (popoverActive) {
            if (
              !e.target.closest('#popover-photo') &&
              !e.target.closest('#popover-graphic') &&
              !e.target.closest('#popover-template') &&
              !e.target.closest('#popover-stock') &&
              !e.target.closest('#popover-text')
            ) {
              setPopoverActive(null)
              if(idFromDeeplink) {
                searchParams.delete('po');
                searchParams.delete('id');
                history.push({ search: searchParams.toString() });
              }
            }
          }
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            transition: 'all .4s ease-in',
          }}
        >
          <Sidebar idFromDeeplink={idFromDeeplink} />
          <div
            id="wrap-canvas"
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
              transition: 'all .4s ease-in',
            }}
            onContextMenu={e => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <TooltipMenu/>
            {editor && loadPixelTool ? <RemoveToolCanvas /> : null}
            <PixelManipulation 
              pixelManipulationCommonObjects={pixelManipulationCommonObjects}
            />

            <Editor config={editorConfig} />
            {canvas && <ContextMenu />}
          </div>
          {isOpenPixelManipulationObject ? <RemoveToolFooter pixelManipulationIsInProcess={pixelManipulationCommonObjects.isInProcess}/> : <Footer />}
        </div>

        <Inspector 
          isDisabled={(disableEditor && toolType != 'MagicBg')}
          pixelManipulationCommonObjects={pixelManipulationCommonObjects}
        />

        <ModalCustom
          isOpen={unloadedTemplate || isOpenErrorModalRemoveBg}
          onClose={value => {
            if (isOpenErrorModalRemoveBg) {
              dispatch(setIsOpenErrorModalRemoveBg(value))
            } else {
              setUnloadedTemplate(value)
            }
          }}
          content={{
            header: isOpenErrorModalRemoveBg ? t('Oops!') : t('Uh-oh! Something went wrong!'),
            desc: isOpenErrorModalRemoveBg
              ? t('We couldn’t remove the background from your photos. Please try again.')
              : t('Try a different template.'),
          }}
          action={{
            primary: {
              name: 'Ok',
              handle: () => {
                if (isOpenErrorModalRemoveBg) {
                  dispatch(setIsOpenErrorModalRemoveBg(false))
                } else {
                  location.href = 'https://bazaart.me'
                }
              },
            },
          }}
        />
        {boundingRectFrame ? <div
          id="container-overlay-remove"
          style={{ position: 'absolute', inset: 0, background: 'transparent', zIndex: 2 }}
        >
          <div
            className="overlay-remove"
            style={{
              position: 'absolute',
              // only show when removing bg
              display: disableEditor ? 'flex' : 'none',
              top: `${boundingRectFrame.top < 0 ? 0 : boundingRectFrame.top}px`,
              left: boundingRectFrame.left > 0 ? `${boundingRectFrame.left}px` : 0,
              width: boundingRectFrame.left < 0 ? `100vw` : `${boundingRectFrame.width}px`,
              height:
                boundingRectFrame.top < 0
                  ? `calc(100vh - 64px)`
                  : `${boundingRectFrame.height}px`,
              borderRadius: boundingRectFrame.top < 0 ? 0 : '8px',
              background: 'rgba(0,0,0,.65)',
              zIndex: 99,
            }}
          >
            <CSSTransition in={disableEditor} classNames="my-node" timeout={400}>
              <div id="wrap-load-animation">
                <canvas
                  id="canvas-animation"
                  style={{
                    height: '124px',
                    marginTop: '-62px',
                  }}
                ></canvas>
                <p
                  style={{
                    color: '#fff',
                    transition: 'all 0.7s',
                    margin: '8px 0 24px',
                    ...lightTheme.typography.LabelLarge,
                  }}
                  id="text-remove-bg"
                >
                  {t('Just a sec...')}
                </p>
                <ButtonCustom
                  transparent
                  type={SizeButton.SMALL}
                  style={{
                    minWidth: '80px',
                    width: 'max-content',
                    borderRadius: '16px',
                    pointerEvents: 'auto',
                    display: canCancelRemoveBg ? 'flex' : 'none',
                  }}
                  onClick={() => {
                    abortRemoveBackground()
                  }}
                >
                  <p
                    style={{
                      ...lightTheme.typography.LabelLarge,
                      color: lightTheme.colors.contentPrimary,
                    }}
                  >
                    {t('Cancel')}
                  </p>
                </ButtonCustom>
              </div>
            </CSSTransition>
          </div>
        </div> : null}
      </div>
      {/* intro video */}
      {/* <IntroVideo /> */}
      <UnsavedProjectMessage />
    </ThemeProvider>
  )
}

const EditorWithInspectorContext = (props) => (
  <InspectorDataProvider>
    <App {...props} />
  </InspectorDataProvider>
);

export default EditorWithInspectorContext;
