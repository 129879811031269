import { fabric } from 'fabric'
import {
  ALPHA_MODES,
  BaseTexture,
  Container,
  IRenderer,
  Renderer,
  RenderTexture,
  Sprite,
  Texture,
  Transform
} from 'pixi.js'
import { Filter, Rectangle as PixiRectangle } from '@pixi/core'
import { BasePixiFilter } from '@scenes/engine/utils/PixijsFilters/BasePixiFilter'
import { Rectangle } from '@scenes/engine/objects/media-repository/rectangle'
import { Inset } from '@scenes/engine/objects/media-repository/inset'
import { Size } from '@scenes/engine/objects/media-repository/size'
import { FilterSimpleResize } from '@scenes/engine/utils/PixijsFilters/filter-simple-resize'
import { PremultiplyFilter } from '@scenes/engine/utils/PixijsFilters/Premultiply/PremultiplyFilter'
import { DropShadowFilter } from '@pixi/filter-drop-shadow'
import ApiService from '@services/api'

class PixiFilterBackend extends fabric.WebglFilterBackend {
    spriteDictionary: { [key: string]: Sprite } = {};
    photoContainerDictionary: { [key: string]: Container } = {};
    maxEdge: number;
    renderer: IRenderer

    constructor(props) {
      super(props)
      this.maxEdge = props.tileSize ?? 2048;

      this.renderer = new Renderer({
          backgroundAlpha: 0,
          antialias: true,
          premultipliedAlpha: true,
          clearBeforeRender: true,
          // provide area for filters that 'spill-over' like very blurry shadows
          width: this.maxEdge * 2,
          height: this.maxEdge * 2
      });
    }

    private capSize(inputSize: Size, inset: Inset): Size {
      // let size = new Size(inputSize.width, inputSize.height);
      // if (size.width <= this.maxEdge && size.height <= this.maxEdge) {
      //   return size;
      // }
      // if (size.width > size.height) {
      //   let maxWidth = this.maxEdge;// - inset.left - inset.right;
      //   return new Size( maxWidth, (size.height / size.width) * maxWidth);
      // } else {
      //   let maxHeight = this.maxEdge;// - inset.left - inset.bottom;
      //   return new Size((size.width / size.height) * maxHeight, maxHeight);
      // }
      return inputSize
    }

    private resizeTextureIfNeeded(renderer: IRenderer, sprite: Sprite, inputTargetSize: Size, targetSize: Size): Texture {
      if (inputTargetSize.width - targetSize.width < 1 && inputTargetSize.height - targetSize.height < 1) {
        return sprite.texture;
      }

      const renderTexture = RenderTexture.create({
          width: targetSize.width,
          height: targetSize.height
      });

      let transform = Transform.IDENTITY;
      transform.scale.set(targetSize.width / inputTargetSize.width, targetSize.height / inputTargetSize.height);
      transform.updateLocalTransform();

      renderer.render(sprite, {
        transform: transform.localTransform,
        renderTexture: renderTexture,
        clear: true
      });
      return renderTexture;
    }

    private handleShadowIfNeeded(targetSize: Size, inset: Inset, allFilters: Filter[], srcSprite: Sprite, photoContainer: Container): Boolean {
      let shadowFilterIdx = allFilters.findIndex((filter) => filter instanceof DropShadowFilter);
      let spriteWithoutShadow: Sprite;
      // draw image with all other filters on-top of shadow
      if (shadowFilterIdx === -1) {
        return;
      }

      let requiredHeight = (inset.top + inset.bottom) + targetSize.height;
      let requiredWidth = (inset.left + inset.right) + targetSize.width;
      let maxEdge = Math.max(requiredHeight, requiredWidth)
      if (maxEdge > this.maxEdge) {
        let ratio = this.maxEdge / maxEdge;
        // let scaleDownFilter = new FilterSimpleResize(ratio, ratio);
        srcSprite.scale.set(Math.sqrt(ratio), Math.sqrt(ratio))// =
        // srcSprite.filters = [...allFilters, scaleDownFilter.pixijsFilter];
      }


      spriteWithoutShadow = new Sprite(srcSprite.texture)
      spriteWithoutShadow.filters = [...allFilters];
      spriteWithoutShadow.filters.splice(shadowFilterIdx, 1);

      this.positionSprite(spriteWithoutShadow, inset)
      // @ts-ignore
      photoContainer.addChild(spriteWithoutShadow);
    }

    private positionSprite(sprite: Sprite, inset: Inset) {
        sprite.anchor.x = 0.0;
        sprite.anchor.y = 0.0;

        // this seems to be a bug with pixijs - image is not allowed to touch borders otherwise
        // we get some strange clamping artifacts.
        sprite.x = 1 + Math.abs(inset.left);
        sprite.y = 1 + Math.abs(inset.top);
    }
  /**
   * Attempts to apply the requested filters to the source provided, drawing the filtered output
   * to the provided target canvas.
   *
   * @param {Array} filters The filters to apply.
   * @param {HTMLImageElement|HTMLCanvasElement} source The source to be filtered.
   * @param {Rectangle} boundingBox The bounding box of target output
   * @param {HTMLCanvasElement} targetCanvas The destination for filtered output to be drawn.
   * @param {String|undefined} cacheKey A key used to cache resources related to the source. If
   * omitted, caching will be skipped.
   */
  applyFiltersWithBoundingBox(filters, source, roiTargetSize, inset, scaleX, scaleY, cacheKey, boundingBox: Rectangle) {
    let pixijsFilters = filters.filter((f) => f as BasePixiFilter).map((bpf) => bpf.pixijsFilter);
    if (pixijsFilters?.length == 0){
      return source;
    }

    let targetSize = this.capSize(roiTargetSize, inset);
    let sourceSize = new Size(roiTargetSize.width / boundingBox.width, roiTargetSize.height / boundingBox.height);
    let roi = boundingBox.multiply(sourceSize);

    // thank you - https://github.com/pixijs/pixijs/issues/4457
    if (!this.spriteDictionary[cacheKey]){
      const inputSource = source;
      const baseTexture = new BaseTexture(inputSource, { alphaMode: ALPHA_MODES.PMA});
      const inputTexture = new Texture(baseTexture);
      const inputSprite = new Sprite(inputTexture);

      const texture = this.resizeTextureIfNeeded(this.renderer, inputSprite, targetSize, targetSize);
      let sprite = new Sprite(texture);
      const container = new Container();
      this.spriteDictionary[cacheKey] = sprite;
      this.photoContainerDictionary[cacheKey] = container;
    }

    let photoContainer = this.photoContainerDictionary[cacheKey];
    let sprite = this.spriteDictionary[cacheKey];
    
    // @ts-ignore
    photoContainer.addChild(sprite);

    sprite.texture.frame = new PixiRectangle(
      Math.max(0, roi.x), 
      Math.max(0, roi.y), 
      Math.min(sourceSize.width, roi.width),
      Math.min(sourceSize.height, roi.height)
    );

    let scaleDownFilter = new FilterSimpleResize(scaleX, scaleY);
    let premultiplyFilter = new PremultiplyFilter();
    let allFilters = [
      ...pixijsFilters, 
      premultiplyFilter.pixijsFilter, 
      scaleDownFilter.pixijsFilter
    ];

    sprite.filters = allFilters;

    this.positionSprite(sprite, inset)
    this.handleShadowIfNeeded(targetSize, inset, allFilters, sprite, photoContainer);

    // canvas.toBlob(function(blob){
    //   let link = URL.createObjectURL(blob);
    //   console.log('dror : ' + link); // this line should be here
    // },'image/png');

 
    const extract = this.renderer.plugins.extract;
    let pixiRect = new PixiRectangle(
      1,
      this.renderer.height - targetSize.height - 1,
      roiTargetSize.width,
      roiTargetSize.height
    );

    this.renderer.render(photoContainer, {clear: true});

    
    const canvas = extract.canvas(null, pixiRect);
    
    const outputBase64 = extract.base64(null, null, null, pixiRect);
    // @ts-ignore
    canvas.lazyLoadingSrc = outputBase64

    photoContainer.removeChildren()
    
    return canvas;
  }

  randomNumber(min, max) {
    return Math.ceil(Math.random() * (max - min) + min);
  }

  evictCachesForKey(key: string){
    delete this.spriteDictionary[key];
    delete this.photoContainerDictionary[key];
  }
};

// @ts-ignore
fabric.PixiFilterBackend = PixiFilterBackend;