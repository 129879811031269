import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import Providers from './Providers'
import Routes from './Routes'
import Container from './Container'
import { createBrowserHistory } from 'history'
import './index.css'
import * as amplitude from '@amplitude/analytics-browser'
import * as Sentry from '@sentry/react'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import { TourProvider, components } from '@reactour/tour'
import { lightTheme } from './customTheme'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

 
function Content(props) {
  console.log('props', props);
  
  return (
    <div style={{ color: '#000', fontSize: '16px', fontWeight: '600', lineHeight: '24px' }}>
      <components.Content
        content={() => <div style={{
          position: 'relative'
        }}>
          <p style={{...lightTheme.typography.Small11medium}}>{props.currentStep + 1} / 3</p>
          {props.content}
          {/* <p style={{...lightTheme.typography.Small12medium, color: '#999', marginTop: '8px'}}>Skip</p> */}
          </div>}
        setCurrentStep={props.setCurrentStep}
        currentStep={props.currentStep}
      >
      </components.Content>
    </div>
  )
}

const history = createBrowserHistory()
// Set up the browser history with the updated location (minus the # sign)
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1]

if (path) {
  history.replace(path)
}


amplitude.init('3ef674a74b1c64de3b599b9d74000523', undefined, {
  appVersion: process.env.REACT_APP_VERSION,
})

const sessionReplayTracking = sessionReplayPlugin({
  sampleRate: 0.1
});

amplitude.add(sessionReplayTracking);

const identifyEvent = new amplitude.Identify()
identifyEvent.set('isSignup', false)
identifyEvent.set('isPremium', false)
amplitude.identify(identifyEvent)
Sentry.init({
  dsn: "https://a6598218172f465bb4a973937465d5e6@o4505475578134528.ingest.sentry.io/4505696928137216",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["https://staging.design.bazaart.me", "https://design.bazaart.me", "https://dev.design.bazaart.me"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <TourProvider steps={[]} 
    padding={{ 
      mask: 0, 
      wrapper: 0 
    }}
    disableDotsNavigation = {true}
    disableKeyboardNavigation={true}
    showCloseButton={false}
    showDots={false}
    showBadge={false}
    showNavigation={false}
    onClickMask={() => {}}
    className='tour-provider'
    components={{ Content }}
    styles={{
      maskWrapper: (base) => ({
        ...base,
        zIndex: 100000000
      }),
      popover: (base) => ({
        ...base,
        transition: 'none'
      })
    }}
  >
    
    <I18nextProvider  i18n={i18n}>
      <Providers>
        <Container>
          <Routes />
        </Container>
      </Providers>
    </I18nextProvider>
  </TourProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()