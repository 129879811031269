import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { customAmplitude } from '@/utils/customAmplitude'
import { useStyletron } from 'baseui'
import { KIND, SHAPE } from 'baseui/button'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Icons from '../../../../Icons'
import { lightTheme } from '@/customTheme'
import { ShowModalType } from '@/scenes/engine/common/constants'
import useAppContext from '@/hooks/useAppContext'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import { useSelector } from 'react-redux'
import { selectOpenModalTryPremium } from '@/store/slices/user/selectors'
import { useAppDispatch } from '@/store/store'

function ChooseAPlanV3({ onConfirmPlan, onNavigate, planDetail, planActive, setPlanActive }) {
  const [css] = useStyletron()
  const { t } = useTranslation()
  const { setShowModalType } = useAppContext()
  const openModalTryPremium = useSelector(selectOpenModalTryPremium)
  const dispatch = useAppDispatch()

  useEffect(() => {
    customAmplitude('Premium Choose Plan')
  }, [])
  return (
    <div
      style={{
        position: 'relative',
        padding: '48px 48px 16px 48px',
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        textAlign: 'left',
      }}
    >
      <h2 style={{ ...lightTheme.typography.Header26bold, marginBottom: '8px' }}>
        {t('Try Bazaart Premium')}
      </h2>
      <p style={{ ...lightTheme.typography.Header16bold, marginBottom: '32px' }}>
        {t('Give yourself extra design superpowers!')}
      </p>
      {/* monthly */}
      <div
        className={css({
          height: '81px',
          background: lightTheme.colors.grayScale50,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          borderRadius: '12px',
          padding: '24px',
          border: planActive === 1 ? '2px rgba(255, 5, 96, 1) solid' : '2px transparent solid',
          cursor: 'pointer',
          ':hover': {
            background: lightTheme.colors.grayScale100,
          },
          ':active': {
            background: lightTheme.colors.grayScale150,
          },
        })}
        onClick={() => setPlanActive(1)}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ ...lightTheme.typography.Small16Bold, lineHeight: '16px', margin: 0 }}>
            {t('Monthly')}
          </p>
          <p style={{ ...lightTheme.typography.Small12medium, margin: 0 }}>
            {t('%@ billed monthly', { arg: planDetail?.month?.price ?? '$11.99' })}
          </p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ ...lightTheme.typography.Small16Bold, margin: 0 }}>
            {planDetail?.month?.price ?? '$32.99'}/
          </p>
          <span style={{ ...lightTheme.typography.Small12regular, margin: 0 }}>{t('month')}</span>
        </div>
      </div>
      {/* yearly */}
      <div
        className={css({
          height: '81px',
          background: lightTheme.colors.grayScale50,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          borderRadius: '12px',
          padding: '24px',
          border: planActive === 2 ? '2px rgba(255, 5, 96, 1) solid' : '2px transparent solid',
          cursor: 'pointer',
          marginTop: '16px',
          ':hover': {
            background: lightTheme.colors.grayScale100,
          },
          ':active': {
            background: lightTheme.colors.grayScale150,
          },
        })}
        onClick={() => setPlanActive(2)}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              ...lightTheme.typography.Small16Bold,
              lineHeight: '16px',
              margin: 0,
              position: 'relative',
              width: 'fit-content',
            }}
          >
            {t('Yearly')}
            <div
              style={{
                position: 'absolute',
                left: 'calc(100% + 8px)',
                top: 0,
                ...lightTheme.typography.Small11medium,
                color: '#fff',
                padding: '0 10px',
                background: 'rgba(255, 5, 96, 1)',
                borderRadius: '10px',
                minWidth: '74px',
                width: 'max-content',
              }}
            >
              {t('Best value')}
            </div>
          </div>

          <p style={{ ...lightTheme.typography.Small12medium, margin: 0 }}>
            {t('%@ billed yearly', { arg: planDetail?.year?.price ?? '$49.99' })}
          </p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ ...lightTheme.typography.Small16Bold, margin: 0 }}>
            {planDetail?.year?.price
              ? planDetail?.year?.price.match(/[^0-9.-]+/g, '') +
                (Number(planDetail?.year?.price.replace(/[^0-9.-]+/g, '')) / 12).toFixed(2)
              : '$71.99'}
            /
          </p>
          <span style={{ ...lightTheme.typography.Small12regular, margin: 0 }}>{t('month')}</span>
        </div>
      </div>
      <div style={{ marginTop: 'auto', textAlign: 'center' }}>
        <h4
          style={{
            marginBottom: 0,
            marginTop: '8px',
            ...lightTheme.typography.Small12medium,
            color: lightTheme.colors.blackGray,
          }}
        >
          {t('Cancel anytime.')}
        </h4>
        <ButtonCustom
          type={SizeButton.LARGE}
          kind={KIND.primary}
          style={{
            position: 'relative',
            marginBottom: 0,
            marginTop: '8px',
          }}
          onClick={e => onConfirmPlan(planActive)}
        >
          <p
            className={css({
              ...lightTheme.typography.Small14Semibold,
              color: '#fff',
            })}
          >
            {planActive === 1
              ? planDetail?.month?.canFreeTrial
                ? t('Start free trial')
                : t('Continue')
              : planDetail?.year?.canFreeTrial
              ? t('Start free trial')
              : t('Continue')}
          </p>
        </ButtonCustom>
      </div>
      <h4
        style={{
          marginBottom: 0,
          marginTop: '8px',
          ...lightTheme.typography.Small12medium,
          padding: '0 16px',
          color: 'rgba(153, 153, 153, 1)',
          cursor: 'pointer',
          textAlign: 'center'
        }}
        onClick={() => {
          setShowModalType(ShowModalType.SIGN_IN)
          dispatch(
            setOpenModalTryPremium({
              ...openModalTryPremium,
              isOpen: false,
            })
          )
        }}
      >
        <span style={{ fontWeight: '400' }}>{t('Already Premium')}?</span> {t('Sign in')}
      </h4>
    </div>
  )
}

export default ChooseAPlanV3
