import React, { useState } from 'react'
import { KIND, SHAPE } from 'baseui/button'
import { PLACEMENT, StatefulPopover } from 'baseui/popover'
import { ThemeProvider } from 'baseui'
// import { isMobile } from 'react-device-detect'
import {
  browserName,
  fullBrowserVersion,
  osName,
  osVersion,
  deviceType,
  isDesktop,
} from 'react-device-detect'
import { useSelector } from 'react-redux'
import { selectUser } from '@/store/slices/user/selectors'
import { useTranslation } from 'react-i18next'
import { PremiumOnPlatformType } from '@/scenes/Editor/components/Navbar/components/SignInManager'
import ButtonCustom from '../ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { lightTheme } from '@/customTheme'
import Icons from '@/scenes/Editor/components/Icons'

function ContactUs() {
  const { t } = useTranslation()
  const user = useSelector(selectUser)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenContactMenu, setIsOpenContactMenu] = useState(false)
  const handleClickContact = (content = '') => {
    let device = isDesktop ? 'Desktop' : deviceType
    let os = `${osName} ${osVersion} `
    let browser = `${browserName} ${fullBrowserVersion} `
    let bazaartVersion = process.env.REACT_APP_VERSION
    let plan = 'Free'
    let userId = 'Free User'
    let email = ''
    if (user) {
      if (user.userIsPremium) {
        if (user.userPremiumOnPlatform === PremiumOnPlatformType.ios) {
          plan = 'Premium (App Store)'
        } else if (user.userPremiumOnPlatform === PremiumOnPlatformType.web) {
          plan = 'Premium (Purchased via Web)'
        }
      }
      userId = user.userId
      email = user.email
    }
    var formattedBody = `${content}\n\n\n\n\nDevice: ${device} \nOS Version: ${os} \nBrowser: ${browser} \nBazaart Version: ${bazaartVersion} \nPlan: ${plan} ${
      userId && userId !== 'Free User' ? `\nUser ID: ${userId}` : ''
    } ${email ? `\nEmail: ${email}` : ''}`
    window.open('mailto:support@bazaart.com?subject=Help&body=' + encodeURIComponent(formattedBody))
  }
  return (
    <StatefulPopover
      placement={PLACEMENT.top}
      popoverMargin={0}
      overrides={{
        Body: {
          style: ({ $theme }) => ({
            zIndex: '10000',
            transform: `translate(78px, calc(100vh - 122px))`,
          }),
        },
      }}
      content={({ close }) => (
        <ThemeProvider theme={lightTheme}>
          <div
            style={{
              width: '296px',
              height: '112px',
              borderRadius: '16px',
              background: lightTheme.colors.white,
              padding: '16px',
            }}
          >
            <ButtonCustom
              type={SizeButton.STANDARD}
              kind="tertiary"
              style={{
                padding: '8px',
                ...lightTheme.typography.Small14regular,
                border: 'none',
                justifyContent: 'left',
                ':hover': {
                  border: 'none',
                },
              }}
              onClick={() => {
                window.open('https://support.bazaart.com/en/category/4799-bazaart-for-web')
                close()
              }}
            >
              {t('Help center')}
            </ButtonCustom>
            <StatefulPopover
              placement={PLACEMENT.right}
              popoverMargin={0}
              overrides={{
                Body: {
                  style: ({ $theme }) => ({
                    zIndex: '10000',
                    transform: `translate(378px, calc(100vh - 242px))`,
                  }),
                },
              }}
              content={({ close }) => (
                <ThemeProvider theme={lightTheme}>
                  <div
                    style={{
                      width: '296px',
                      height: '232px',
                      borderRadius: '16px',
                      background: lightTheme.colors.white,
                      padding: '16px',
                    }}
                  >
                    <ButtonCustom
                      type={SizeButton.STANDARD}
                      kind="tertiary"
                      style={{
                        padding: '8px',
                        ...lightTheme.typography.Small14regular,
                        border: 'none',
                        justifyContent: 'left',
                        ':hover': {
                          border: 'none',
                        },
                      }}
                      onClick={() => {
                        handleClickContact('I have a question')
                        close()
                      }}
                    >
                      {t('I have a question')}
                    </ButtonCustom>
                    <ButtonCustom
                      type={SizeButton.STANDARD}
                      kind="tertiary"
                      style={{
                        padding: '8px',
                        ...lightTheme.typography.Small14regular,
                        border: 'none',
                        justifyContent: 'left',
                        ':hover': {
                          border: 'none',
                        },
                      }}
                      onClick={() => {
                        handleClickContact('I have a suggestion')
                        close()
                      }}
                    >
                      {t('I have a suggestion')}
                    </ButtonCustom>
                    <ButtonCustom
                      type={SizeButton.STANDARD}
                      kind="tertiary"
                      style={{
                        padding: '8px',
                        ...lightTheme.typography.Small14regular,
                        border: 'none',
                        justifyContent: 'left',
                        ':hover': {
                          border: 'none',
                        },
                      }}
                      onClick={() => {
                        handleClickContact('I need help with my subscription')
                        close()
                      }}
                    >
                      {t('I need help with my subscription')}
                    </ButtonCustom>
                    <ButtonCustom
                      type={SizeButton.STANDARD}
                      kind="tertiary"
                      style={{
                        padding: '8px',
                        ...lightTheme.typography.Small14regular,
                        border: 'none',
                        justifyContent: 'left',
                        ':hover': {
                          border: 'none',
                        },
                      }}
                      onClick={() => {
                        handleClickContact('I have an issue with my projects')
                        close()
                      }}
                    >
                      {t('I have an issue with my projects')}
                    </ButtonCustom>
                    <ButtonCustom
                      type={SizeButton.STANDARD}
                      kind="tertiary"
                      style={{
                        padding: '8px',
                        ...lightTheme.typography.Small14regular,
                        border: 'none',
                        justifyContent: 'left',
                        ':hover': {
                          border: 'none',
                        },
                      }}
                      onClick={() => {
                        handleClickContact('I need help with something else')
                        close()
                      }}
                    >
                      {t('I need help with something else')}
                    </ButtonCustom>
                  </div>
                </ThemeProvider>
              )}
              triggerType="hover"
              stateReducer={(a, b, c) => {
                setIsOpenContactMenu(b.isOpen)
                return b
              }}
            >
              <div>
                <ButtonCustom
                  type={SizeButton.STANDARD}
                  kind="tertiary"
                  style={{
                    padding: '8px',
                    ...lightTheme.typography.Small14regular,
                    border: 'none',
                    justifyContent: 'left',
                    ':hover': {
                      border: 'none',
                    },
                    background: isOpenContactMenu ? lightTheme.colors.grayScale50 : 'auto'
                  }}
                  endEnhancer={<Icons.ArrowRight fill={lightTheme.colors.grayScale600} />}
                >
                  {t('Contact us')}
                </ButtonCustom>
              </div>
            </StatefulPopover>
          </div>
        </ThemeProvider>
      )}
      triggerType="click"
      returnFocus
      autoFocus
      stateReducer={(a, b, c) => {
        setIsOpen(b.isOpen)
        return b
      }}
      dismissOnClickOutside={isOpenContactMenu ? false : true}
    >
      <div style={{ pointerEvents: isOpen ? 'none' : 'auto' }}>
        <ButtonCustom
          shape={SHAPE.circle}
          kind={KIND.minimal}
          type={SizeButton.SMALL}
          style={{
            position: 'fixed',
            bottom: '20px',
            left: '20px',
            width: '54px',
            height: '54px',
            background: lightTheme.colors.blackGray,
            zIndex: 10,
            boxShadow: '0 0 20px 0 rgba(0,0,0,0.3)',
            ':hover': {
              background: lightTheme.colors.grayScale800,
            },
            ':active': {
              background: lightTheme.colors.grayScale800,
            },
          }}
          tooltip={{
            content: 'Help',
            placement: 'top',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Icons.HelpIcon fill={lightTheme.colors.white}></Icons.HelpIcon>
          </div>
        </ButtonCustom>
      </div>
    </StatefulPopover>
  )
}
export default ContactUs
