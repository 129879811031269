import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={170}
    height={120}
    fill="none"
    {...props}
  >
    <g clipPath="url(#drop_a)">
      <g clipPath="url(#drop_b)">
        <rect
          width={51.65}
          height={51.652}
          y={34.903}
          fill="#FCDBE0"
          rx={7.102}
          transform="rotate(-15 0 34.903)"
        />
        <path
          fill="#F7A4B2"
          d="m39.202 58.67-5.141 9.422 2.79 10.41 30.08-8.06-20.076-13.624a5.317 5.317 0 0 0-7.653 1.853Z"
        />
        <path
          fill="#FF0560"
          d="m18.527 58.387-8.74 15.964 2.851 10.639 42.922-11.5-29.71-17.154a5.317 5.317 0 0 0-7.323 2.051Z"
        />
        <circle
          cx={40.047}
          cy={37.047}
          r={4.937}
          fill="#FF0560"
          transform="rotate(-15 40.047 37.047)"
        />
      </g>
      <rect
        width={53.65}
        height={53.652}
        x={-1.225}
        y={34.196}
        stroke="#fff"
        strokeWidth={2}
        rx={8.102}
        transform="rotate(-15 -1.225 34.196)"
      />
      <g clipPath="url(#drop_c)">
        <rect
          width={51.65}
          height={51.652}
          fill="#FCDBE0"
          rx={7.102}
          transform="scale(-1 1) rotate(-15 47.556 663.09)"
        />
        <path
          fill="#F7A4B2"
          d="m130.798 58.67 5.141 9.422-2.789 10.41-30.082-8.06 20.077-13.624a5.317 5.317 0 0 1 7.653 1.853Z"
        />
        <path
          fill="#FF0560"
          d="m151.473 58.387 8.739 15.964-2.851 10.639-42.921-11.5 29.71-17.154a5.318 5.318 0 0 1 7.323 2.051Z"
        />
        <circle
          cx={4.937}
          cy={4.937}
          r={4.937}
          fill="#FF0560"
          transform="scale(-1 1) rotate(-15 59.44 533.29)"
        />
      </g>
      <rect
        width={53.65}
        height={53.652}
        x={1.225}
        y={-0.707}
        stroke="#fff"
        strokeWidth={2}
        rx={8.102}
        transform="scale(-1 1) rotate(-15 47.556 671.687)"
      />
    </g>
    <g filter="url(#drop_d)">
      <g clipPath="url(#drop_e)">
        <rect
          width={71.63}
          height={71.628}
          x={49}
          y={5}
          fill="#FCDBE0"
          rx={4.213}
        />
        <path
          fill="#F7A4B2"
          d="M95.93 48.698 79 61.865V77h59l-32.744-28.08a7.374 7.374 0 0 0-9.327-.222Z"
        />
        <path
          fill="#FF0560"
          d="M64.46 42.131 42 61.716V77h74L74.025 42.024a7.374 7.374 0 0 0-9.566.107Z"
        />
        <circle cx={102.5} cy={21.5} r={6.5} fill="#FF0862" />
      </g>
      <rect
        width={73.63}
        height={73.628}
        x={48}
        y={4}
        stroke="#fff"
        strokeWidth={2}
        rx={5.213}
      />
    </g>
    <defs>
      <clipPath id="drop_a">
        <path fill="#fff" d="M0 4.5h170v115H0z" />
      </clipPath>
      <clipPath id="drop_b">
        <rect
          width={51.65}
          height={51.652}
          y={34.903}
          fill="#fff"
          rx={7.102}
          transform="rotate(-15 0 34.903)"
        />
      </clipPath>
      <clipPath id="drop_c">
        <rect
          width={51.65}
          height={51.652}
          fill="#fff"
          rx={7.102}
          transform="scale(-1 1) rotate(-15 47.556 663.09)"
        />
      </clipPath>
      <clipPath id="drop_e">
        <rect
          width={71.63}
          height={71.628}
          x={49}
          y={5}
          fill="#fff"
          rx={4.213}
        />
      </clipPath>
      <filter
        id="drop_d"
        width={99.63}
        height={99.628}
        x={35}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={9} />
        <feGaussianBlur stdDeviation={6} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.920833 0 0 0 0 0.713646 0 0 0 0 0.739544 0 0 0 1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_15088_9250"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_15088_9250"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default SvgComponent
