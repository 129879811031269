import { GridSize } from "./InspectorGrid";
import ButtonCustom from "../ButtonCustom";
import { KIND } from 'baseui/button'
import { SizeButton } from '@/constants/sizeButton'
import { lightTheme } from '@/customTheme'

interface InspectorGridButtonProps {
    gridSize: GridSize;
    title?: string;
    onClick?: () => void; // Optional onClick handler
    children?: JSX.Element;
}

function InspectorGridButton({ gridSize, title, onClick, children }: InspectorGridButtonProps) {
    return (
        <ButtonCustom
          type={SizeButton.LARGE}
          kind={KIND.tertiary}
          onClick={onClick}
          style={{ width: '67px', height: '67px', position: 'relative' }}
        >
          { children }
          { title && <p
            style={{
              position: 'absolute',
              margin: 0,
              top: `calc(100% + 8px)`,
              left: 0,
              right: 0,
              textAlign: 'center',
              fontSize: lightTheme.typography.Small11medium.fontSize,
              fontWeight: lightTheme.typography.Small11medium.fontWeight,
              lineHeight: lightTheme.typography.Small11medium.lineHeight,
              color: lightTheme.colors.grayScale600,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            { title }
          </p>}
        </ButtonCustom>
    );
}

export default InspectorGridButton