import { useEffect, useRef } from 'react'
import ButtonCustom from '@/components/ButtonCustom'
import Icons from '@/components/icons'
import { SizeButton } from '@/constants/sizeButton'
import { KIND, SHAPE } from 'baseui/button'
import Close from '../../../Icons/Close'
import { useStyletron } from 'baseui'
import { lightTheme } from '@/customTheme'
import { useTranslation } from 'react-i18next'
function Search({
  handleValueChange,
  size,
  valueExternal,
  setValueExternal,
  handleReset,
  focusSearchBox,
}: {
  handleValueChange: Function
  size: 'standard' | 'large'
  valueExternal: string | null
  setValueExternal: Function
  handleReset: Function
  focusSearchBox?: boolean
}) {
  const inputSearch = useRef(null)
  const [css] = useStyletron()

  const { t } = useTranslation()

  useEffect(() => {
    if(focusSearchBox) {
      setTimeout(()=>{
        inputSearch.current.focus()
      }, 700)
    }
  }, [focusSearchBox])

  return (
    <div
      className={css({
        boxSizing: 'border-box',
        background: '#fff',
        position: 'relative',
      })}
    >
      <input
        className={css({
          boxSizing: 'border-box',
          width: 'calc(100%)',
          paddingRight: '68px',
          height: size === 'standard' ? '40px' : '48px',
          background: '#F2F2F2',
          borderRadius: '360px',
          border: 0,
          outline: 'none',
          paddingLeft: '44px',
          ...lightTheme.typography.Small14regular,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          ':hover': {
            cursor: 'pointer',
            backgroundColor: lightTheme.colors.grayScale100,
          },
          ':focus': {
            background: '#F2F2F2',
            color: lightTheme.colors.blackGray,
          },
          '::placeholder': {
            color: lightTheme.colors.contentPrimary,
            fontWeight: '500',
            fontSize: '14px',
          },
        })}
        placeholder={t("Search")}
        value={valueExternal ?? ''}
        onChange={e => {
          setValueExternal(e.target.value)
          handleValueChange(e.target.value)
        }}
        onKeyDown={e => {
          e.stopPropagation()
        }}
        ref={inputSearch}
        // onKeyUp={e => {
        //   if (e.key === 'Enter') {
        //     handleValueChange()
        //   }
        // }}
      />
      <div
        style={{
          position: 'absolute',
          left: '12px',
          top: size === 'standard' ? '4px' : '8px',
          width: '32px',
          height: '32px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Icons.Search />
      </div>
      {valueExternal && (
        <div style={{ position: 'absolute', right: '4px', top: '50%', transform: 'translateY(-50%)', height: '32px' }}>
          <ButtonCustom
            kind={KIND.minimal}
            shape={SHAPE.square}
            type={SizeButton.SMALL}
            onClick={() => {
              inputSearch.current.value = ''
              inputSearch.current.focus()
              handleReset()
            }}
            style={{
              borderRadius: '50%',
              ':hover': {
                background: lightTheme.colors.grayScale100
              },
              ':active': {
                background: lightTheme.colors.grayScale150
              },
            }}
          >
            <Close size={24} />
          </ButtonCustom>
        </div>
      )}
    </div>
  )
}

export default Search
