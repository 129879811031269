import { styled, ThemeProvider } from 'baseui'
import Icons from '../Icons'
import { lightTheme } from '../../../../customTheme'
import { useCallback, useEffect, useState } from 'react'
import useAppContext from '@/hooks/useAppContext'
import { useAppDispatch } from '@/store/store'
import History from './components/History'
import ExportImage from './ExportImage'
import ButtonCustom from '@/components/ButtonCustom'
import { useStyletron } from 'baseui'
import { SizeButton } from '@/constants/sizeButton'
import RemoveHistory from './components/RemoveHistory'
import { KIND } from 'baseui/button'
import { LabelLarge } from 'baseui/typography'
import { StatefulPopover } from 'baseui/popover'
import { PremiumOnPlatformType, SignInManager } from './components/SignInManager'
import { selectOpenModalTryPremium, selectUser, selectUserIsPremium, selectUserStore } from '@/store/slices/user/selectors'
import { useSelector } from 'react-redux'
import Cookies from 'universal-cookie'
import { setOpenModalTryPremium, setUser, setUserIsPremium } from '@/store/slices/user/actions'
import * as amplitude from '@amplitude/analytics-browser'
import ModalCustom from '../Modal/Modal'
import { useTranslation } from 'react-i18next'
import {
  browserName,
  fullBrowserVersion,
  osName,
  osVersion,
  deviceType,
  isDesktop,
} from 'react-device-detect'
import * as Sentry from '@sentry/react'
import MiniMenu from './components/MiniMenu'
import { customAmplitude } from '@/utils/customAmplitude'
import { useHistory, useLocation } from 'react-router-dom'
import Logo from './Logo'
import { SAVE_PROJECT_STATE, ShowModalType } from '@/scenes/engine/common/constants'
import { useEditorContext } from '@/scenes/engine'
import { SyncManager } from '@/firebase/services/sync/SyncManager'
import SaveProject from './SaveProject'

import api from '@/services/api'
import { selectTemplateConfig } from '@/store/slices/templates/selectors'
import { MediaImageFallbackHandler } from '@/scenes/engine/objects/media-repository/media_image_fallback_handler'
import { selectImageElements } from '@/store/slices/imageElement/selectors'
import { initializeProfitWell } from '@/libs/profitwell'
import { isOlderThan24Hours } from '@/utils/isOlderThan24Hours'

const Container = styled('div', props => ({
  minHeight: '64px',
  background: lightTheme.colors.white,
  display: 'flex',
  padding: '0 16px',
  justifyContent: 'space-between',
  alignItems: 'center',
  // filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.08))',
  zIndex: 9,
}))

function NavbarEditor() {
  const [css, theme] = useStyletron()
  const { disableEditor, isOpenPixelManipulationObject, setShowModalType, setSaveProjectState } = useAppContext()
  // const [name, setName] = useState('Untitled design')
  const openModalTryPremium = useSelector(selectOpenModalTryPremium)
  const [isLoggined, setIsLoggined] = useState(false)
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const [isOpenModalManage, setIsOpenModalManage] = useState(false)
  const [isOpenSelectLanguage, setIsOpenSelectLanguage] = useState(false)
  const [isOpenManageSubscription, setIsOpenManageSubscription] = useState(false)
  const location = useLocation()
  const history = useHistory()

  const editor = useEditorContext().editor
  const filterPacks = useSelector(selectImageElements)
  const templateConfig = useSelector(selectTemplateConfig)

  useEffect(() => {
    if(!editor) return
    const unblock = history.block((location, action) => {
      if(!editor.handlers.transactionHandler.isFlushed) {
        if (action === 'POP') {
          const confirmNavigation = window.confirm(t('You have unsaved changes. Are you sure you want to leave?'));
          if (!confirmNavigation) {
            // Prevent the URL change
            history.go(1)
            return false
          } else {
            setSaveProjectState(SAVE_PROJECT_STATE.END)
            editor.handlers.transactionHandler.clear()
          }
        }
      }
    });

    return () => {
      unblock(); // Cleanup the block on component unmount
    };
  }, [history, editor]);

  useEffect(() => {
    if(!editor) return
    const sizeProvider = () => {
      return editor.handlers.frameHandler.getSize();
    };
    const filtersProvider = () => {
      return filterPacks
    };

    MediaImageFallbackHandler.getInstance().setup(editor.handlers.canvasHandler.canvas, sizeProvider, filtersProvider)
  }, [editor]);

  // const handleSave = async () => {
  //   if (editor) {
  //     setSaving(true)
  //     // TODO : implement save Template to json
  //     if (id) {
  //       // const exportedTemplate = editor.exportToJSON()
  //       // const savedTemplate = await api.updateCreation(id, { ...exportedTemplate, name })
  //       // dispatch(updateCreationsList(savedTemplate))
  //     } else {
  //       // const exportedTemplate = editor.exportToJSON()
  //       // const savedTemplate = await api.createCreation({ ...exportedTemplate, name })
  //       // dispatch(setCreations([savedTemplate]))
  //       // history.push(`/edit/${savedTemplate.id}`)
  //     }
  //     setSaving(false)
  //   }
  // }

  // const handleSaveAsTemplate = async () => {
  //   console.log('TODO: handleSaveAsTemplate')
  //   // const exportedTemplate = editor.exportToJSON()
  //   // const savedTemplate = await api.createTemplate(exportedTemplate)
  //   // console.log({ savedTemplate })
  // }
  const hasPremium = useSelector(selectUserIsPremium)

  const user = useSelector(selectUser)
  const userStore = useSelector(selectUserStore)
  

  useEffect(() => {
    if (user) {
      setIsLoggined(true)
      dispatch(setUserIsPremium(user.userIsPremium))
      // setTimeout(() => {
        if (openModalTryPremium.source) {
          if (user.userIsPremium) {
            // dispatch(
            //   setOpenModalTryPremium({
            //     ...openModalTryPremium,
            //     isOpen: false,
            //   })
            // )
          } else if (!openModalTryPremium.opened){
            dispatch(
              setOpenModalTryPremium({
                ...openModalTryPremium,
                isOpen: true,
              })
            )
          }
        }
      //   setIsLoadedJsonSuccess(true)
      // }, 500)
    } else {
      setIsLoggined(false)
    }
  }, [user])

  const dispatch = useAppDispatch()

  const handleShowModal = async () => {    
    let showedOnboarding = localStorage.getItem('showedOnboarding') ? JSON.parse(localStorage.getItem('showedOnboarding')) : false
    let showedOnboardingDate = localStorage.getItem('showedOnboardingDate') ? localStorage.getItem('showedOnboardingDate') : null
   
    // show onboarding
    if(!showedOnboarding) {
      setShowModalType(ShowModalType.ONBOARDING)
    }
    else{
      if(showedOnboardingDate && !isOlderThan24Hours(showedOnboardingDate)) {
        showModalTryPremiumIfNeed()
        return
      }
      
      let dataWhatnew = await api.getWhatsNew().then(data => {
        return data
      }).catch(err => err)
      let whatNewShowedIds = localStorage.getItem('whatNewIds') ? JSON.parse(localStorage.getItem('whatNewIds')) : []
      // let filterData = []
      let firstWhatNewUnshowedId = null
      if(dataWhatnew) {
        firstWhatNewUnshowedId = dataWhatnew.objects?.find(item => !(whatNewShowedIds || []).includes(item.id))?.id || null;
      }
      if(firstWhatNewUnshowedId) {
        // show what's new 
        localStorage.setItem('whatNewIds', JSON.stringify([firstWhatNewUnshowedId, ...whatNewShowedIds]))
        setShowModalType(ShowModalType.WHAT_NEW)
        customAmplitude('Whats New Prompt', {
          Source: 'Updated',
          ID: firstWhatNewUnshowedId
        })
      }
      else {
        showModalTryPremiumIfNeed()
      }
    }
  }

  function showModalTryPremiumIfNeed() {
    if (!SignInManager.getInstance().userIsPremium && !openModalTryPremium.opened) {
      let source = location.pathname.includes('create') ? 'Create' : 'Editor Open'
      dispatch(
        setOpenModalTryPremium({
          isOpen: true,
          source: source,
          opened: true,
        })
      )

      const eventProperties = {
        Source: source,
        Type: 'Standard',
      }
      customAmplitude('Premium Prompt', eventProperties)
      // @ts-ignore
      window.dataLayer.push({ event: 'premium_prompt', ...eventProperties })
    }
  }

  useEffect(() => {
    function getUser(): boolean {
      let cookies = new Cookies()
      let userInfo = cookies.get('userInfo')
      if (userInfo) {
        SignInManager.getInstance().setUserProperties(userInfo)
        SignInManager.getInstance().refreshTransactionStatus()

        setIsLoggined(true)

        const identifyEvent = new amplitude.Identify()
        identifyEvent.set('isSignup', true)
        amplitude.identify(identifyEvent)
        amplitude.setUserId(userInfo.userId)
        
        Sentry.setUser({ id: userInfo.userId })
        initializeProfitWell(userInfo.userId)
        return true
      } else {
        const identifyEvent = new amplitude.Identify()
        identifyEvent.set('isSignup', false)
        amplitude.identify(identifyEvent)
        Sentry.setUser(null)
        return false
      }
    }
    getUser()
    
    SyncManager.shared.setup();
    const urlParams = new URLSearchParams(window.location.search)
    const ob = urlParams.get('ob')
    if(ob === '0') {
      return
    }
    
    handleShowModal()
  }, [])

  const handleSignOut = useCallback(() => {
    setIsOpenPopover(false)
    let isProject = templateConfig?.draftGuid && !templateConfig?.templateId
    if(isProject) {
      setShowModalType(ShowModalType.CONFIRM_SIGN_OUT)
    } else {
      SignInManager.getInstance().logout()
      dispatch(setUser(null))
      dispatch(setUserIsPremium(false))

      const identifyEvent = new amplitude.Identify()
      identifyEvent.set('isSignup', false)
      identifyEvent.set('isPremium', false)
      amplitude.identify(identifyEvent)
      Sentry.setUser(null)
    }
  }, [location,templateConfig])

  const { t } = useTranslation()
  const handleClickContact = () => {
    let device = isDesktop ? 'Desktop' : deviceType
    let os = `${osName} ${osVersion} `
    let browser = `${browserName} ${fullBrowserVersion} `
    let bazaartVersion = process.env.REACT_APP_VERSION
    let plan = 'Free'
    let userId = 'Free User'
    let email = ''
    if(user) {
      if (user.userIsPremium) {
        if (user.userPremiumOnPlatform === PremiumOnPlatformType.ios) {
          plan = 'Premium (App Store)'
        } else if (user.userPremiumOnPlatform === PremiumOnPlatformType.web) {
          plan = 'Premium (Purchased via Web)'
        }
      }
      userId = user.userId
      email = user.email
    }
    var formattedBody = `\n\n\n\n\nDevice: ${device} \nOS Version: ${os} \nBrowser: ${browser} \nBazaart Version: ${bazaartVersion} \nPlan: ${plan} ${userId && userId !== 'Free User'? `\nUser ID: ${userId}` : ''} ${email ? `\nEmail: ${email}` : ''}`
    window.open('mailto:support@bazaart.com?subject=Help&body=' + encodeURIComponent(formattedBody))
  }

  const onNewProjectButton = () => {
    window.open('/create', '_blank')
    customAmplitude('Open', { Source: 'Editor' })
  }
  const onBackButton = () => {
    if(!editor.handlers.transactionHandler.isFlushed) {
      setShowModalType(ShowModalType.DISCARD_PROJECT)
    } else {
      history.push('/create/projects')
    }
  }

  
  return (
    <ThemeProvider theme={lightTheme}>
      <Container
        style={{
          opacity: disableEditor ? 0.5 : 1,
          pointerEvents: disableEditor ? 'none' : 'auto',
          borderBottom: location.pathname.includes('create') ? '1px solid #e5e5e5' : 'none',
          boxShadow: location.pathname.includes('create') ? 'none' : '0px 0px 6px 0px rgba(0, 0, 0, 0.08)',
        }}
      >
        <div style={{ display: 'flex', flex: '1 1 0px', alignItems: 'center' }}>
          <Logo size={32} />
          <ButtonCustom
            type={SizeButton.STANDARD}
            kind="minimal"
            onClick={() => onBackButton()}
            style={{
              minWidth: 'fit-content',
              width: 'max-content',
              marginLeft: '12px',
              display: location.pathname.includes('create') ? 'none' : 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              borderRadius: '360px',
              gap: '8px',
              padding: '8px 16px',
            }}
            disabled={isOpenPixelManipulationObject || disableEditor}
          >
            <Icons.ArrowLeft
              fill={isOpenPixelManipulationObject || disableEditor ? '#ccc' : lightTheme.colors.blackGray}
            />
            <p
              style={{
                ...lightTheme.typography.Small14Semibold,
                color: isOpenPixelManipulationObject || disableEditor ? '#ccc' : lightTheme.colors.blackGray,
                marginTop: '1px'
              }}
            >
              {t('Projects')}
            </p>
          </ButtonCustom>
          {!location.pathname.includes('create') ? <SaveProject/> : null}
        </div>

        {/* <AddGraphics /> */}
        {/* <Photos /> */}
        <div style={{ display: 'flex', gap: '8px', flex: '1 1 0px', justifyContent: 'flex-end' }}>
          {isOpenPixelManipulationObject ? <RemoveHistory /> : <History />}
          {/* <Resize /> */}
          {!user?.userIsPremium ? (
            <ButtonCustom
              type={SizeButton.STANDARD}
              kind={KIND.secondary}
              style={{
                minWidth: 'fit-content',
                width: '140px',
                gap: '6px',
                background: isOpenPixelManipulationObject ? lightTheme.colors.grayScale50 : 'auto',
              }}
              onClick={() => {
                dispatch(
                  setOpenModalTryPremium({
                    isOpen: true,
                    source: location.pathname.includes('create') ? 'Create Header_CTA' : 'Header_CTA',
                    opened: true,
                  })
                )
                const eventProperties = {
                  Source: location.pathname.includes('create') ? 'Create Header_CTA' : 'Header_CTA',
                  Type: 'Standard',
                }
                customAmplitude('Premium Prompt', eventProperties)

                // @ts-ignore
                window.dataLayer.push({ event: 'premium_prompt', ...eventProperties })
              }}
              disabled={isOpenPixelManipulationObject}
            >
              <Icons.PremiumIcon disabled={isOpenPixelManipulationObject} />
              <p
                style={{
                  ...lightTheme.typography.Small14Semibold,
                  color: isOpenPixelManipulationObject
                    ? lightTheme.colors.grayScale200
                    : lightTheme.colors.blackGray,
                }}
              >
                {t('Try Premium')}
              </p>
            </ButtonCustom>
          ) : null}
          {!isLoggined && (
            <ButtonCustom
              type={SizeButton.STANDARD}
              kind={KIND.secondary}
              style={{
                width: '108px',
                minWidth: 'fit-content',
                gap: '6px',
                background: isOpenPixelManipulationObject ? lightTheme.colors.grayScale50 : 'auto',
                pointerEvents: isOpenPixelManipulationObject ? 'none' : 'auto',
              }}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                setShowModalType(ShowModalType.SIGN_IN)
                dispatch(
                  setOpenModalTryPremium({
                    isOpen: false,
                    source: null,
                    callback: null,
                    opened: false,
                  })
                )
              }}
              disabled={isOpenPixelManipulationObject}
            >
              <Icons.DefaultAvatar
                fill={
                  isOpenPixelManipulationObject ? lightTheme.colors.grayScale200 : lightTheme.colors.blackGray
                }
              />
              <LabelLarge
                $style={{
                  ...lightTheme.typography.Small14Semibold,
                  color: isOpenPixelManipulationObject
                    ? lightTheme.colors.grayScale200
                    : lightTheme.colors.blackGray,
                }}
              >
                {t('Sign in')}
              </LabelLarge>
            </ButtonCustom>
          )}
          <ExportImage />
          {!isLoggined && (
            <StatefulPopover
              popoverMargin={4}
              placement="bottomRight"
              returnFocus={false}
              autoFocus={false}
              content={({ close }) => (
                <MiniMenu
                  handleClickContact={handleClickContact}
                  isOpenSelectLanguage={isOpenSelectLanguage}
                  setIsOpenSelectLanguage={setIsOpenSelectLanguage}
                  closeMenu={close}
                />
              )}
              overrides={{
                Body: {
                  style: ({ $theme }) => ({
                    zIndex: 1000,
                    transitionDuration: 'none',
                  }),
                },
                Inner: {
                  style: ({ $theme }) => ({
                    transitionDuration: 'none',
                  }),
                },
              }}
              onClose={() => {
                setIsOpenSelectLanguage(false)
              }}
              stateReducer={(a, b, c) => {
                if (b.isOpen) {
                  customAmplitude('Open Settings')
                }
                setIsOpenPopover(b.isOpen)
                return b
              }}
            >
              <div>
                <ButtonCustom
                  type={SizeButton.STANDARD}
                  kind={KIND.secondary}
                  style={{
                    width: '40px',
                    padding: '0 8px',
                    background: isOpenPixelManipulationObject
                      ? lightTheme.colors.grayScale50
                      : isOpenPopover
                      ? `${lightTheme.colors.grayScale150} !important`
                      : 'auto',
                  }}
                  onClick={() => {}}
                  disabled={isOpenPixelManipulationObject}
                >
                  <Icons.Dots
                    fill={
                      isOpenPixelManipulationObject
                        ? lightTheme.colors.grayScale200
                        : lightTheme.colors.blackGray
                    }
                  />
                </ButtonCustom>
              </div>
            </StatefulPopover>
          )}
          {isLoggined ? (
            <StatefulPopover
              popoverMargin={4}
              placement="bottomRight"
              returnFocus={false}
              autoFocus={false}
              content={({ close }) => (
                <div
                  style={{
                    width: '296px',
                    borderRadius: '16px',
                    backgroundColor: lightTheme.colors.white,
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
                    padding: '16px',
                    paddingTop:
                      isOpenSelectLanguage || isOpenManageSubscription ? 0 : '16px',
                  }}
                >
                  { isOpenSelectLanguage || isOpenManageSubscription ? null : (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        gap: '8px',
                        padding: '24px 0px 24px 8px',
                        borderBottom: '1px solid rgba(229,229,229,1)',
                      }}
                    >
                      <div
                        style={{
                          width: '40px',
                          height: '40px',
                          borderRadius: '50%',
                          background: lightTheme.colors.blackGray,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: lightTheme.colors.white,
                          textTransform: 'uppercase',
                        }}
                      >
                        {SignInManager.getInstance().email ? SignInManager.getInstance().email[0] ?? '?' : ''}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '2px',
                          maxWidth: '208px',
                        }}
                      >
                        <p
                          style={{
                            ...lightTheme.typography.Small14Semibold,
                            color: lightTheme.colors.blackGray,
                            lineHeight: '16px',
                            margin: 0,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {SignInManager.getInstance().email.split('@')[0] ?? 'Default'}
                        </p>
                        <p
                          style={{
                            ...lightTheme.typography.Small14regular,
                            color: 'rgba(153, 153, 153, 1)',
                            margin: 0,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {SignInManager.getInstance().email ?? 'Default'}
                        </p>
                        <p
                          style={{
                            ...lightTheme.typography.LabelSmall,
                            lineHeight: '14px',
                            color: 'rgba(153, 153, 153, 1)',
                            margin: 0,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {t(
                            `Connected with ${
                              SignInManager.getInstance().platformType.charAt(0).toUpperCase() +
                              SignInManager.getInstance().platformType.slice(1)
                            }`
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 0,
                      marginTop:
                        isOpenSelectLanguage || isOpenManageSubscription ? 0 : '16px',
                    }}
                  >
                    { isOpenSelectLanguage || isOpenManageSubscription
                      ? null
                      : !hasPremium && (
                          <ButtonCustom
                            type={SizeButton.STANDARD}
                            kind="tertiary"
                            style={{
                              display: 'inline-flex',
                              padding: '8px',
                              ...lightTheme.typography.Small14regular,
                              border: 'none',
                              justifyContent: 'left',
                              ':hover': {
                                border: 'none',
                              },
                            }}
                            onClick={e => {
                              customAmplitude('Premium Prompt', {
                                Source: 'BtSubscriptionSettings',
                                Type: 'Standard',
                              })
                              // @ts-ignore
                              window.dataLayer.push({
                                event: 'premium_prompt',
                                ...{
                                  Source: 'BtSubscriptionSettings',
                                  Type: 'Standard',
                                },
                              })
                              dispatch(
                                setOpenModalTryPremium({
                                  isOpen: true,
                                  source: 'BtSubscriptionSettings',
                                })
                              )
                              close()
                            }}
                          >
                            {t('Upgrade to Premium')}
                          </ButtonCustom>
                        )}
                    <MiniMenu
                      handleClickContact={handleClickContact}
                      isOpenSelectLanguage={isOpenSelectLanguage}
                      setIsOpenSelectLanguage={setIsOpenSelectLanguage}
                      isOpenManageSubscription={isOpenManageSubscription}
                      setIsOpenManageSubscription={setIsOpenManageSubscription}
                      setIsOpenModalManage={setIsOpenModalManage}
                      hasPremium={hasPremium}
                      closeMenu={close}
                    />

                    { isOpenSelectLanguage || isOpenManageSubscription ? null : (
                      <>
                        <ButtonCustom
                          type={SizeButton.STANDARD}
                          kind="tertiary"
                          style={{
                            padding: '8px',
                            ...lightTheme.typography.Small14regular,
                            border: 'none',
                            justifyContent: 'left',
                            ':hover': {
                              border: 'none',
                            },
                          }}
                          onClick={() => {
                            handleSignOut()
                            close()
                          }}
                        >
                          {t('Sign out')}
                        </ButtonCustom>
                      </>
                    )}
                  </div>
                </div>
              )}
              overrides={{
                Body: {
                  style: ({ $theme }) => ({
                    zIndex: 1000,
                  }),
                },
              }}
              stateReducer={(a, b, c) => {
                setIsOpenPopover(b.isOpen)
                return b
              }}
              onClose={() => {
                setIsOpenSelectLanguage(false)
                setIsOpenManageSubscription(false)
              }}
            >
              <div
                className={css({
                  display: 'flex',
                  gap: '2px',
                  padding: '4px 8px 4px 4px',
                  alignItems: 'center',
                  borderRadius: '100px',
                  cursor: 'pointer',
                  background: isOpenPopover ? lightTheme.colors.grayScale100 : 'transparent',
                  ':hover': {
                    background: isOpenPopover
                      ? lightTheme.colors.grayScale100
                      : lightTheme.colors.grayScale50,
                  },
                  pointerEvents: isOpenPixelManipulationObject ? 'none' : 'auto',
                })}
              >
                <div
                  style={{
                    width: '32px',
                    height: '32px',
                    borderRadius: '50%',
                    background: isOpenPixelManipulationObject
                      ? lightTheme.colors.grayScale50
                      : lightTheme.colors.blackGray,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: isOpenPixelManipulationObject
                      ? lightTheme.colors.grayScale200
                      : lightTheme.colors.white,
                    pointerEvents: isOpenPopover || isOpenPixelManipulationObject ? 'none' : 'auto',
                    textTransform: 'uppercase',
                  }}
                >
                  {SignInManager.getInstance().email ? SignInManager.getInstance()?.email[0] ?? 'B' : ''}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    transform: 'rotate(-90deg)',
                    pointerEvents: isOpenPopover || isOpenPixelManipulationObject ? 'none' : 'auto',
                  }}
                >
                  <Icons.Back
                    size={16}
                    fill={isOpenPixelManipulationObject ? lightTheme.colors.grayScale200 : null}
                  />
                </div>
              </div>
            </StatefulPopover>
          ) : null}
        </div>
      </Container>
      {/* modal manage subscription */}
      <ModalCustom
        isOpen={isOpenModalManage}
        onClose={setIsOpenModalManage}
        content={{
          header: t('Manage subscription'),
          desc: t(
            'To manage your subscription, open the Bazaart app on your mobile device and go to settings.'
          ),
        }}
        action={{
          primary: {
            name: 'Ok',
            handle: () => {
              setIsOpenModalManage(false)
            },
          },
        }}
      />
    </ThemeProvider>
  )
}

export default NavbarEditor