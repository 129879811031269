import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={21}
    fill="none"
    {...props}
  >
    <path
      stroke="#666"
      strokeWidth={1.846}
      d="M.923 6.868c0-2.103 0-3.154.416-3.955.35-.674.9-1.224 1.574-1.574.8-.416 1.852-.416 3.955-.416h10.264c2.103 0 3.154 0 3.955.416.674.35 1.224.9 1.574 1.574.416.8.416 1.852.416 3.955v6.572c0 2.103 0 3.154-.416 3.954-.35.675-.9 1.225-1.574 1.575-.8.416-1.852.416-3.955.416H6.868c-2.103 0-3.154 0-3.955-.416a3.693 3.693 0 0 1-1.574-1.575c-.416-.8-.416-1.851-.416-3.954V6.868Z"
    />
    <path
      fill="#666"
      stroke="#666"
      strokeWidth={1.846}
      d="M21.914 13.582c-1.56-.803-3.31-2.333-5.576-2.333-1.291 0-2.55.396-3.44.75-4.852 1.924-6.405 2.691-10.959 4.68-.313.137-.208.447-.209.789 0 .951 1.523 1.723 2.475 1.723h14.88c.177 0 .266 0 .34-.003a3.692 3.692 0 0 0 3.54-3.539c.003-.074.003-.163.003-.34v-.11a1.846 1.846 0 0 0-.956-1.566l-.098-.051Z"
    />
    <path
      fill="#666"
      d="M18.86 7.347c-.207-1.507-.196-1.48-1.787-1.78a.2.2 0 0 1-.127-.062.191.191 0 0 1-.052-.13.184.184 0 0 1 .05-.131.193.193 0 0 1 .13-.06c1.592-.22 1.585-.271 1.787-1.804a.185.185 0 0 1 .06-.124.194.194 0 0 1 .133-.05c.049 0 .096.02.132.053.035.034.056.08.057.128.192 1.509.234 1.503 1.809 1.796.049.004.094.026.128.062a.189.189 0 0 1 .051.13c0 .112-.074.17-.202.192-1.538.244-1.593.26-1.776 1.694-.006.049-.004.1-.022.146a.19.19 0 0 1-.101.105.195.195 0 0 1-.255-.094.186.186 0 0 1-.017-.07h.001ZM11.44 9.945v-.001c-.31-2.257-.231-2.257-2.701-2.703a.302.302 0 0 1-.19-.095.289.289 0 0 1-.003-.388.29.29 0 0 1 .193-.091c2.47-.33 2.398-.405 2.702-2.697a.278.278 0 0 1 .087-.198.293.293 0 0 1 .47.098.278.278 0 0 1 .02.108c.286 2.258.326 2.25 2.683 2.689.073.006.141.039.191.092a.28.28 0 0 1 .075.195c0 .17-.113.26-.303.287-2.339.371-2.362.43-2.648 2.687a.28.28 0 0 1-.088.186.294.294 0 0 1-.49-.168h.002ZM4.735 6.91C4.529 5.402 4.54 5.43 2.95 5.13a.2.2 0 0 1-.128-.062.191.191 0 0 1-.052-.13.184.184 0 0 1 .05-.131.193.193 0 0 1 .13-.06c1.592-.22 1.585-.271 1.787-1.804a.184.184 0 0 1 .061-.124.193.193 0 0 1 .132-.05c.05 0 .097.019.133.053.035.033.055.08.057.128.19 1.508.233 1.503 1.808 1.796.049.004.095.026.128.062a.189.189 0 0 1 .051.13c0 .112-.074.17-.201.191-1.539.245-1.594.262-1.777 1.695-.006.048-.004.1-.022.146a.189.189 0 0 1-.1.105.196.196 0 0 1-.256-.094.184.184 0 0 1-.017-.07h.002Z"
    />
  </svg>
)
export default SvgComponent
