import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={170}
    height={116}
    fill="none"
    {...props}
  >
    <rect
      width={73.737}
      height={73.735}
      x={-1.29}
      y={-0.745}
      fill="#E5E5E5"
      rx={5.267}
      transform="rotate(-15 144.54 -139.957)"
    />
    <rect
      width={73.737}
      height={73.735}
      x={-1.29}
      y={-0.745}
      stroke="#fff"
      strokeWidth={2.107}
      rx={5.267}
      transform="rotate(-15 144.54 -139.957)"
    />
    <circle
      cx={6.847}
      cy={6.847}
      r={6.847}
      fill="#B2B2B2"
      transform="rotate(-15 176.977 -273.534)"
    />
    <g clipPath="url(#upload_a)">
      <rect
        width={71.63}
        height={71.628}
        x={49}
        y={22}
        fill="#E5E5E5"
        rx={4.213}
      />
      <circle cx={102.5} cy={38.5} r={6.5} fill="#B2B2B2" />
      <path
        fill="#CCC"
        d="M95.93 65.698 79 78.865V94h59l-32.744-28.08a7.374 7.374 0 0 0-9.327-.222Z"
      />
      <path
        fill="#B2B2B2"
        d="M64.46 59.131 42 78.716V94h74L74.025 59.024a7.374 7.374 0 0 0-9.566.107Z"
      />
    </g>
    <rect
      width={73.737}
      height={73.735}
      x={47.947}
      y={20.947}
      stroke="#fff"
      strokeWidth={2.107}
      rx={5.267}
    />
    <defs>
      <clipPath id="upload_a">
        <rect
          width={71.63}
          height={71.628}
          x={49}
          y={22}
          fill="#fff"
          rx={4.213}
        />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
